import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase, { firestore, auth, User } from "firebase/app";
import { Spin, Button, Select, DatePicker, Checkbox, Icon } from "antd";
const { Option } = Select;
import UsersInformation from "../models/usersInformation";
import {
  officeRef,
  projectRef,
  allocationRef,
  budgetLinesRef,
  vacationRef,
  deductionRef,
  missionRef
} from "../../db/collectionsRef";
import { currentUserData } from "../../db/userGlobal";
import { sortBy, sumBy, uniq, uniqBy } from "lodash";
import NewUser from "../models/newUser";
import moment from "moment";
const { MonthPicker } = DatePicker;
import DevTable from "../../helpers/devex-table";
import {
  allocation,
  deduction,
  users,
  access,
  project,
  mission
} from "../../db/interfaces";
import { currentSalary } from "../../db/currentSalary";
import { currentP } from "../../db/currentPosition";
import PayrollToPrint from "./payrollToPrint";
import { globalOfficesData } from "../../global/officeList";
import { globalProjectsData } from "../../global/projectList";
import { globalBudgetLinesData } from "../../global/budgetLineList";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function PayrollByOfficeCluster() {
  const [filteredPosition, setFilteredPosition] = useState([] as string[]);
  const [month, setMonth] = useState(moment());
  const [fingerPrintActive, setFingerPrintActive] = useState(true);
  const uid = auth().currentUser!.uid;
  const [officeId, setOfficeId] = useState(currentUserData.office);
  const [modalState, setModalState] = useState(false);
  const [modalStateNewUser, setModalStateNewUser] = useState(false);
  const [defaultColumnWidths] = useState([
    { columnName: "id", width: 150 },
    { columnName: "index", width: 150 },
    { columnName: "resourceNO", width: 150 },
    { columnName: "firstName", width: 150 },
    { columnName: "lastName", width: 150 },
    { columnName: "position", width: 150 },
    { columnName: "workingDayInMonth", width: 150 },
    { columnName: "deduction", width: 150 },
    { columnName: "leaveWithoutPay", width: 250 },
    { columnName: "absentDays", width: 150 },
    { columnName: "daysWorked", width: 150 },
    { columnName: "monthlySalary", width: 250 },
    { columnName: "numberOfHoursLate", width: 250 },
    { columnName: "hoursLateDeduction", width: 250 },
    { columnName: "totalSalaryForCurrentMonth", width: 250 },
    { columnName: "difference", width: 150 },
    { columnName: "donor", width: 150 },
    { columnName: "project", width: 150 },
    { columnName: "budgetLineId", width: 150 },
    { columnName: "percentage", width: 150 },
    { columnName: "allocatedSalary", width: 200 },
    { columnName: "idaAllocationPercentage", width: 200 },
    { columnName: "contractSalary", width: 200 }
  ]);

  const [allocationsSN] = useCollection(allocationRef);
  const [AllUsers] = useCollection(usersRef.where("office", "==", officeId));
  const [officeSN] = useCollection(officeRef);
  const [projectSN] = useCollection(projectRef);
  const [missionSn] = useCollection(missionRef);
  const [budgetLineSN] = useCollection(budgetLinesRef.orderBy("createdAt"));
  const [vacationSN] = useCollection(
    vacationRef
      .where("vacationStartOn", "<=", month.endOf("month").toDate())
      .where("vacationStartOn", ">=", month.startOf("month").toDate())
  );
  const [deductionSN] = useCollection(
    deductionRef.where("month", "==", month.format("MM-YYYY").toString())
  );

  const [accessSN] = useCollection(
    db
      .collectionGroup("access")
      .where("date", "<=", month.endOf("month").toDate())
      .where("date", ">=", month.startOf("month").toDate())
  );
  const [delaySN] = useCollection(
    db
      .collectionGroup("delay")
      .where("month", "==", month.format("MMM.YYYY").toString())
  );
  const print1CbRef = useRef<() => void>();
  const printPayroll = useCallback(() => {
    setTimeout(() => {
      print1CbRef.current();
    }, 1500);
  }, []);

  if (
    !missionSn ||
    !delaySN ||
    !accessSN ||
    !deductionSN ||
    !vacationSN ||
    !budgetLineSN ||
    !allocationsSN ||
    !projectSN ||
    !AllUsers ||
    !officeSN ||
    !currentUserData.fullControlOffices
  )
    return <Spin />;
  const UserOffices = [
    ...(currentUserData.fullControlOffices ?? []),
    currentUserData.office
  ];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map(id => {
    const officeName = officeSN?.docs?.find(d => d.id == id)?.data()
      ?.officeName;
    return { officeName, id };
  });
  const missions = missionSn.docs.map(m => {
    return { ...m.data(), id: m.id } as mission;
  });
  const delays = delaySN.docs
    .map(d => {
      return {
        ...d.data(),
        id: d.id,
        uid: d.data().userUID,
        min: d.data().min,
        status: d.data().status
      };
    })
    .filter(d => d.status != "disabled");

  const VacationDates = vacationSN.docs
    .filter(
      vacation =>
        vacation.data().status != "Rejected" &&
        vacation.data().status != "Canceled"
    )
    .map(d => {
      return {
        ...d.data(),
        employeeUID: d.data().employeeUID,
        start: d.data().vacationStartOn,
        end: d.data().vacationEndOn,
        duration: d.data().vocationPeriodDays,
        type: d.data().vacationType,
        halfDay: d.data().halfDay
      };
    });
  function daysInMonth(m: any) {
    var count = moment()
      .year(m.year())
      .month(m.month())
      .daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(
        moment()
          .year(m.year())
          .month(m.month())
          .date(i)
      );
    }
    return days;
  }
  var days = daysInMonth(month);
  const deductionData = deductionSN.docs.map(d => {
    return { ...d.data(), id: d.id } as deduction;
  });
  const projectList = projectSN?.docs.map(p => {
    return {
      id: p.id,
      projectCode: p?.data()?.projectCode,
      donor: p?.data().donor
    } as project;
  });

  const allocationsUids = allocationsSN?.docs
    .filter(d => {
      const startDate = d.data().startDate.toDate();
      return startDate < month.endOf("month");
    })
    .filter(d => {
      const endDate = d.data().endDate.toDate();
      return month.startOf("month") < endDate;
    })
    .map(al => {
      return { ...al.data(), id: al.id } as allocation;
    });

  const users = AllUsers?.docs
    .filter(d => currentUserData.fullControlOffices.includes(d.data()?.office))
    .filter(d => uniq(allocationsUids).filter(i => i.uid == d.id).length > 0)
    .map(d => {
      return {
        ...d.data(),
        id: d.id
      };
    });

  const userAllocation = allocationsUids
    .filter(a => {
      const user = users.find((u: any) => u.id == a.uid);
      return user ? true : false;
    })
    .map((a: any) => {
      const user = users.find((u: any) => u.id == a.uid);
      return { ...a, ...user, id: a.id };
    });
  const access = accessSN.docs?.map(a => {
    return { ...a.data(), id: a.id } as access;
  });

  const absentDaysArr = userAllocation.map((u: any) => {
    const absentDays = days
      .map(day => {
        let absent = false;

        const accessInDay = access
          .filter(a => a.uid == u.id)
          .filter(a => {
            return (
              moment(a.date.toDate())
                .format("DD-MM-YYYY")
                .toString() ==
              moment(day)
                .format("DD-MM-YYYY")
                .toString()
            );
          }).length;

        const vacationsFiltered = VacationDates.filter(
          v => v.employeeUID == u.id
        ).filter((vacation: any) => {
          const vacationStartOn = moment(
            vacation?.vacationStartOn?.toDate()
          ).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn?.toDate()).endOf(
            "day"
          );

          return moment(day).isBetween(
            vacationStartOn,
            vacationEndOn,
            undefined,
            "[]"
          );
        }).length;

        if (vacationsFiltered == 0 && accessInDay == 0) {
          absent = true;
        }

        return { absent, day };
      })
      .filter(ab => ab.absent == true).length;

    return { uid: u.uid, absentDays };
  });

  const data = sortBy(
    userAllocation.map((row: any) => {
      const id = row.id;
      const missionId = globalOfficesData[row.office].missionId;
      const position =
        currentP(row.uid, month) == ""
          ? row.position
          : currentP(row.uid, month);

      const workingDayInMonth = days.length;

      const deduction =
        deductionData.find(d => d.uid == row.uid)?.percentage ?? 0;

      const leaveWithoutPay = VacationDates.filter(
        v => v.employeeUID == row.uid && v.type == "Unpaid Leave"
      ).length;

      const absentDays = (fingerPrintActive
        ? absentDaysArr.find(ab => ab.uid == row.uid)?.absentDays
        : 0) as number;
      const daysWorked =
        days.length -
        absentDays * 2 -
        VacationDates.filter(
          v => v.employeeUID == row.uid && v.type == "Unpaid Leave"
        ).length;

      const monthlySalary =
        currentSalary(row.uid, month) == ""
          ? row.salary
          : currentSalary(row.uid, month);

      const contractSalary =
        (monthlySalary * row.idaAllocationPercentage) / 100;

      const delaysMin = sumBy(
        delays.filter(d => d.uid == row.uid),
        d => d.min
      );

      const delaysHours = delaysMin >= 120 ? delaysMin / 60 : 0;
      const numberOfHoursLate = delaysHours.toFixed(2);

      const salaryInHour = contractSalary / days.length / 8;
      const deductionByHour = salaryInHour * (delaysHours * 2);
      const hoursLateDeduction = deductionByHour.toFixed(3);
      ///
      const salaryInDay = contractSalary / days.length;
      const deductionPercentage =
        deductionData.find(d => d.uid == row.uid)?.percentage ?? 0;
      const salaryAfterDeduction =
        salaryInDay * daysWorked -
        deductionByHour -
        (deductionPercentage * contractSalary * row.percentage) / 10000;
      const totalSalaryForCurrentMonth = parseInt(
        salaryAfterDeduction.toFixed(3)
      );
      ///
      const differenceNumber = contractSalary - salaryAfterDeduction;
      const difference = parseInt(differenceNumber.toFixed(3));
      ///
      const donor = projectList.find(p => p.id == row.projectId)?.donor;

      const percentage = allocationsUids
        .filter(a => a.uid == row.uid)
        .map(a => a.percentage + "%")
        .join(" - ");
      const allocatedSalaryNumber = salaryAfterDeduction;
      const allocatedSalary = parseInt(allocatedSalaryNumber.toFixed(3));
      const project = allocationsUids
        .filter(a => a.uid == row.uid)
        .map(a => globalProjectsData[a.projectId].projectCode)
        .join(" - ");
      const budgetLineId = allocationsUids
        .filter(a => a.uid == row.uid)
        .map(a => globalBudgetLinesData[a.budgetLineId].budgetLine)
        .join(" - ");

      return {
        ...row,
        position,
        workingDayInMonth,
        deduction,
        leaveWithoutPay,
        absentDays,
        daysWorked,
        monthlySalary,
        numberOfHoursLate,
        hoursLateDeduction,
        totalSalaryForCurrentMonth,
        salaryAfterDeduction,
        difference,
        differenceNumber,
        donor,
        project,
        budgetLineId,
        percentage,
        missionId,
        allocatedSalary,
        allocatedSalaryNumber,
        id,
        contractSalary
      };
    }),
    ["firstName", "lastName"]
  ).map((d, index) => {
    return { ...d, index: index + 1 };
  });
  const positions = uniq(data.map(d => d.position));
  const tableData = data.filter((d: any) => {
    if (filteredPosition.length == 0) {
      return true;
    } else {
      if (filteredPosition.includes(d.position)) {
        return true;
      } else {
        return false;
      }
    }
  });
  const uniqTable = uniqBy(tableData, e => e.uid);

  const columns = [
    { name: "id", title: "id" },
    { name: "index", title: "NO" },
    { name: "resourceNO", title: "Resource NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    {
      name: "position",
      title: "Position"
    },
    {
      name: "workingDayInMonth",
      title: "Work days in a month"
    },
    {
      name: "deduction",
      title: "Deduction"
    },
    {
      name: "leaveWithoutPay",
      title: "Leave Without Pay"
    },
    {
      name: "absentDays",
      title: "Absent Days"
    },
    {
      name: "daysWorked",
      title: "Days worked"
    },
    {
      name: "monthlySalary",
      title: "Monthly salary ( TL/ USD )"
    },
    {
      name: "idaAllocationPercentage",
      title: "Contract Percentage"
    },
    {
      name: "contractSalary",
      title: "Contract Salary"
    },
    {
      name: "numberOfHoursLate",
      title: "Number of Hours Late"
    },
    {
      name: "hoursLateDeduction",
      title: "Hours Late Deduction"
    },
    {
      name: "totalSalaryForCurrentMonth",
      title: "Total Salary for current month"
    },
    {
      name: "difference",
      title: "Difference"
    },
    {
      name: "donor",
      title: "Donor"
    },
    {
      name: "project",
      title: "Project"
    },
    {
      name: "budgetLineId",
      title: "Budget Line"
    },
    {
      name: "percentage",
      title: "Percentage"
    },
    {
      name: "allocatedSalary",
      title: "Allocated Salary"
    }
  ];

  return (
    <div>
      {modalState ? (
        <UsersInformation
          modalState={modalState}
          setModalState={setModalState}
          UID={UID}
        />
      ) : null}
      {modalStateNewUser ? (
        <NewUser
          modalState={modalStateNewUser}
          setModalState={setModalStateNewUser}
        />
      ) : null}
      <MonthPicker
        size="large"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      <Select
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={
          [...UserListOffices, { officeName: "All", id: "notYet" }].find(
            office => office.id == officeId
          )?.officeName
        }
        onChange={e => {
          const id = [
            ...UserListOffices,
            { officeName: "All", id: "notYet" }
          ].find(office => office.officeName == e)?.id as string;
          setOfficeId(id);
        }}
      >
        {[...UserListOffices, { officeName: "All", id: "notYet" }].map(d => {
          return <Option value={d.officeName}>{d.officeName}</Option>;
        })}
      </Select>
      <Select
        placeholder="Filter for Positions!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        mode="multiple"
        showSearch
        value={filteredPosition}
        onChange={(e: any) => {
          setFilteredPosition(e);
        }}
      >
        {positions?.map(d => {
          return (
            <Option key={d} value={d}>
              {d}
            </Option>
          );
        })}
      </Select>

      <div>
        <Checkbox
          checked={fingerPrintActive}
          onChange={e => {
            setFingerPrintActive(e.target.checked);
          }}
        >
          Finger-print Activation
        </Checkbox>

        <Button
          size="large"
          icon="print"
          type="primary"
          onClick={() => printPayroll()}
          style={{ marginBottom: "1%", marginRight: "1%" }}
        >
          <Icon type="print" />
          Payroll
        </Button>
      </div>
      <Paper>
        <DevTable
          data={uniqTable}
          columns={columns}
          defaultHiddenColumnNames={["id"]}
          defaultColumnWidths={defaultColumnWidths}
          tableName={`Payroll-List`}
          selected={false}
          typicalTable={false}
          columnsExport={columns}
          summaryColumn={true}
          totalItems={[
            { columnName: "allocatedSalary", type: "sum" },
            { columnName: "difference", type: "sum" },
            { columnName: "totalSalaryForCurrentMonth", type: "sum" }
          ]}
        />
      </Paper>
      <div style={{ display: "none" }}>
        <PayrollToPrint
          data={uniqTable}
          printFnRef={print1CbRef as any}
          location={
            UserListOffices.find(office => office.id == officeId)
              ?.officeName as string
          }
          month={month}
          days={days}
        />
      </div>
    </div>
  );
}
