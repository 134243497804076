import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  notification,
} from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUploader from "react-firebase-file-uploader";

import { notificationsRef, userRef, orderRef, vacationRef } from "../../../db/collectionsRef";
import { useTranslation } from "react-i18next";
// import { openNotification } from "../layout/openNotification";
import { generateAndUploadWord } from "../../orders/generateAndUploadWord";
import { vacations, users } from "../../../db/interfaces";
import { currentUserData } from "../../../db/userGlobal";
import { values } from "lodash";
// import contract from "../orders/contract.docx";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  orderId: string;
  employeeUID: string;
}
const projectSchema = Yup.object().shape({
  vacationStartOn: Yup.date().required("*"),
});

function EditVacation(props: Props) {
  const { t } = useTranslation();
  const [vacationType, setVacationType] = useState("");
  const [vacationPeriod, setVacationPeriod] = useState(0);
  const { modalState, setModalState, employeeUID } = props;
  const [isCreate, setIsCreate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [halfDay, setHalfDay] = useState("");
  const history = useHistory();
  const [id, setId] = useState();
  const db = firebase.firestore();
  const newId = orderRef.doc().id;
  const uid = auth().currentUser!.uid;

  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const [usersSNDoc] = useDocument(userRef.doc(uid));
  const [userSNEmployee] = useDocument(userRef.doc(employeeUID));
  const employeeData = userSNEmployee?.data() as vacations;
  const [vacationDocSN] = useDocument(vacationRef.doc(props.orderId));
  const vacationData = vacationDocSN?.data() as vacations;
  const changeOnScore = async (vocationPeriodDays: number) => {
    if (vacationType == "Annual Leave") {
      userRef.doc(uid).update({
        vacationAnnualScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Sick Leave") {
      userRef.doc(uid).update({
        vacationSickScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Compensation Leave") {
      userRef.doc(uid).update({
        vacationCompensationScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Death Leave") {
      userRef.doc(uid).update({
        vacationDeathScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Marriage Leave") {
      userRef.doc(uid).update({
        vacationMarriageScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Paternity Leave") {
      userRef.doc(uid).update({
        vacationPaternityScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Maternity Leave") {
      userRef.doc(uid).update({
        vacationMaternityScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    }
  };
  const data = usersSNDoc?.data() as users;

  const formik = useFormik({
    initialValues: {
      vacationType: "",
      notes: "",
      vacationStartOn: moment(),
      vacationEndOn: moment(),
      startWorkOn: moment(),
      vocationPeriodDays: 0,
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      const typeScore =
        vacationType == "Annual Leave"
          ? employeeData.vacationAnnualScore
          : vacationType == "Sick Leave"
          ? employeeData.vacationSickScore
          : 120;
      if (values.vocationPeriodDays > typeScore + vacationPeriod || values.vocationPeriodDays == 0) {
        alert(`Your ${vacationType} Score is not enough as requested!`);
        setLoading(false);
      } else {
        const promise = vacationRef.doc(props.orderId).update({
          vacationStartOn: values.vacationStartOn.toDate() as any,
          vacationEndOn: values.vacationEndOn.toDate() as any,
          startWorkOn: values.startWorkOn.toDate() as any,
          employeeSignatureDate: firebase.firestore.Timestamp.fromDate(new Date()),
          managerSignatureDate: "",
          adminSignatureDate: "",
          HRSignatureDate: "",
          // HRUserUID: "",
          vacationType: vacationType,
          vocationPeriodDays: values.vocationPeriodDays,
          status: "Requested",
          requestedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          notes: values.notes,
          halfDay: halfDay,
          version: 2,
        });
        promise
          .then(() => {
            changeOnScore(values.vocationPeriodDays - vacationData.vocationPeriodDays);
          })
          .then(() => {
            message.success("Your request has been submitted successfully");
            console.log("Document successfully written!");
            setModalState(false);
            setLoading(false);
            history.push(`/vacation/${props.orderId}`);
            setLoading(false);
          })

          .then(() => form.resetForm());
        promise.catch((error) => {
          console.error("Error writing document: ", error);
          message.error("Failed");
        });
      }
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (vacationDocSN && loaded) {
      formik.setValues({
        vacationType: vacationData.vacationType,
        vacationStartOn: moment(vacationData.vacationStartOn.toDate()),
        vacationEndOn: moment(vacationData.vacationEndOn.toDate()),
        startWorkOn: moment(vacationData.startWorkOn.toDate()),
        vocationPeriodDays: vacationData.vocationPeriodDays,
        notes: vacationData.notes,
      } as any);
      setVacationPeriod(vacationData.vocationPeriodDays);
      setLoaded(false);
      setVacationType(vacationData.vacationType);
      setHalfDay(vacationData.halfDay);
    }
  }, [vacationDocSN, formik, loaded]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const orderStorageRef = firebase.storage().ref(uid);
  return (
    <Drawer title="Vacation Request" width={600} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.vacationType")} span={6}>
                <Select value={vacationType} onChange={(e) => setVacationType(e)}>
                  <Option value={"Annual Leave"}>Annual Leave</Option>
                  <Option value={"Unpaid Leave"}>Unpaid Leave</Option>
                  <Option value={"Sick Leave"}>Sick Leave</Option>
                  <Option value={"Death Leave"}>Death Leave</Option>
                  <Option value={"Marriage Leave"}>Marriage Leave</Option>
                  <Option value={"Paternity Leave"}>Paternity Leave</Option>
                  <Option value={"Maternity Leave"}>Maternity Leave</Option>
                  <Option value={"Compensation Leave"}>Compensation Leave</Option>
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationStartOn")} span={6}>
                <DatePicker
                  value={formik.values.vacationStartOn}
                  format={dateFormat}
                  onChange={(e) => {
                    if (data.admin || data.adminHR) {
                      formik.setFieldValue("vacationStartOn", e);
                    } else {
                      if (vacationType == "Sick Leave") {
                        formik.setFieldValue("vacationStartOn", e);
                      } else {
                        if (moment(e) >= moment()) {
                          formik.setFieldValue("vacationStartOn", e);
                        } else {
                          formik.setFieldValue("vacationStartOn", moment());
                        }
                      }
                    }
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationEndOn")} span={6}>
                <DatePicker
                  value={formik.values.vacationEndOn}
                  format={dateFormat}
                  onChange={(e) => {
                    if (data.admin || data.adminHR) {
                      formik.setFieldValue("vacationEndOn", e);
                    } else {
                      if (vacationType == "Sick Leave") {
                        formik.setFieldValue("vacationEndOn", e);
                      } else {
                        if (moment(e) >= moment()) {
                          formik.setFieldValue("vacationEndOn", e);
                        } else {
                          formik.setFieldValue("vacationEndOn", moment());
                        }
                      }
                    }
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.startWorkOn")} span={6}>
                <DatePicker
                  value={formik.values.startWorkOn}
                  format={dateFormat}
                  onChange={(e) => formik.setFieldValue("startWorkOn", e)}
                />{" "}
              </Descriptions.Item>

              <Descriptions.Item label={t("general.vocationPeriodDays")} span={6}>
                <InputNumber
                  max={
                    vacationType == "Annual Leave"
                      ? (employeeData.vacationAnnualScore + vacationPeriod).toFixed(1)
                      : vacationType == "Sick Leave"
                      ? employeeData.vacationSickScore.toFixed(1)
                      : 120
                  }
                  min={0}
                  value={formik.values.vocationPeriodDays}
                  onChange={(e: any) => {
                    formik.setFieldValue("vocationPeriodDays", e);
                    // setVacationPeriod(e);
                  }}
                />
              </Descriptions.Item>
              {formik.values.vocationPeriodDays == 0.5 ? (
                <Descriptions.Item label={t("general.halfDay")} span={6}>
                  <Select value={halfDay} onChange={(e) => setHalfDay(e)}>
                    <Option value={"morning"}>Morning</Option>
                    <Option value={"evening"}>Evening</Option>
                  </Select>
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label={t("general.notes")} span={6}>
                <TextArea
                  value={formik.values.notes}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={(e) => formik.setFieldValue("notes", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>

        {/* { this.state.loading ? <Spin/>: null} */}
      </Form>
    </Drawer>
  );
}

export default EditVacation;
