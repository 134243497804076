import { globalUser, position, salary, workOffice } from "../db/interfaces";
import { userRef, officeRef, positionRef, salariesRef, workOfficeRef } from "./collectionsRef";
import { auth } from "firebase/app";
import { uniq } from "lodash";
import moment from "moment";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";

export let currentUserData = {} as globalUser;
export let currentPosition = "";
export let positions = [] as any;
export let currentSalary = "";
export let salaries = [] as any;

export let currentOffice = "";
export let offices = [] as any;

auth().onAuthStateChanged(async (user) => {
  if (!user) {
    return;
  }
  positionRef.onSnapshot((data) => {
    // data.docs.map(doc => doc.data() as position)
    positions.splice(0, positions.length, ...data.docs.map((doc) => doc.data() as position));
  });
  positionRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentPosition = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment(p.data()?.endDate.toDate()).endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.position)[0])
    );

  salariesRef.onSnapshot((data) => {
    salaries.splice(0, salaries.length, ...data.docs.map((doc) => doc.data() as salary));
  });
  salariesRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentSalary = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment(p.data()?.endDate.toDate()).endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.salary)[0])
    );

  workOfficeRef.onSnapshot((data) => {
    offices.splice(0, offices.length, ...data.docs.map((doc) => doc.data() as workOffice));
  });
  workOfficeRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentOffice = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment(p.data()?.endDate.toDate()).endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.office)[0])
    );
  userRef.doc(user.uid).onSnapshot((userData) => {
    currentUserData = {
      ...userData.data(),
      uid: user.uid,
      adminOffices: userData.data()?.adminOffices ?? [],
      HROffices: userData.data()?.HROffices ?? [],
      fullControlOffices: uniq([...(userData.data()?.adminOffices ?? []), ...(userData.data()?.HROffices ?? [])]),
      position: currentPosition === "" ? userData.data()?.position : currentPosition,
      salary: currentSalary === "" ? userData.data()?.salary : currentSalary,
      fullName: userData.data()?.firstName + " " + userData.data()?.lastName
    } as globalUser;
  });
});

// console.log(positions)
