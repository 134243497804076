import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col
} from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;

import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  notificationsRef,
  userRef,
  orderRef,
  meetingRoomList,
  projectsRef,
  budgetLinesRef,
  allocationRef
} from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
import { allocation, users } from "../../db/interfaces";

interface Props {
  allocationId: string;
  projectId: string;
  modalState: boolean;
  uid: string;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  projectId: Yup.string().required("*"),
  uid: Yup.string().required("*"),
  percentage: Yup.number().required("*"),
  budgetLineId: Yup.string().required("*")
});

function EditAllocation(props: Props) {
  const { t } = useTranslation();
  const [projectCodeId, setProjectCodeId] = useState(props.projectId);
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const newId = allocationRef.doc().id;
  const uid = auth().currentUser!.uid;
  const [projectsSN] = useCollection(projectsRef);
  const [usersSN] = useCollection(userRef);
  const [budgetLinesSN] = useCollection(budgetLinesRef);
  const [allocationSN] = useDocument(allocationRef.doc(props.allocationId));
  const [userSN] = useDocument(userRef.doc(props.uid));

  const allocationData = allocationSN?.data() as allocation;
  const userData = userSN?.data() as users;

  const projectsMap = projectsSN?.docs.map(d => {
    return {
      id: d.id,
      code: d.data()?.projectCode
    };
  });
  const USERS_ID_FULL_NAME = usersSN?.docs.map(u => {
    const fullName = u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName;
    const uid = u.id;
    return { uid, fullName };
  });

  const budgetLineMap = budgetLinesSN?.docs
    .filter(d => {
      return d.data().projectId == projectCodeId;
    })
    .map(d => {
      return {
        id: d.id,
        budgetLine: d.data().budgetLine
      };
    });
  const formik = useFormik({
    initialValues: {
      uid: "",
      projectId: "",
      budgetLineId: "",
      percentage: 0,
      idaAllocationPercentage: 0,
      startDate: moment(),
      endDate: moment()
    },
    validationSchema: projectSchema,
    onSubmit: async values => {
      const promise = allocationRef.doc(props.allocationId).update({
        uid: values.uid,
        office: usersSN?.docs?.find(d => d.id == values.uid)?.data()?.office,
        projectId: values.projectId,
        budgetLineId: values.budgetLineId,
        percentage: values.percentage,
        idaAllocationPercentage: values.idaAllocationPercentage,
        startDate: values.startDate.toDate() as any,
        endDate: values.endDate.toDate() as any,
        createdAt: new Date()
      });
      promise.then(() => {
        userRef.doc(values.uid).update({
          idaAllocationPercentage: values.idaAllocationPercentage
        });
      });
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch(error => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    }
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (allocationSN && loaded && userSN) {
      formik.setValues({
        uid: allocationData.uid,
        projectId: allocationData.projectId,
        budgetLineId: allocationData.budgetLineId,
        percentage: allocationData.percentage,
        idaAllocationPercentage: allocationData.idaAllocationPercentage,
        startDate: moment(allocationData.startDate.toDate()),
        endDate: moment(allocationData.endDate.toDate())
      } as any);
      setLoaded(false);

      // setItemLineCode(procurementPlan.itemLineCode)
    }
  }, [allocationSN, formik, loaded, userSN]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.editNewAllocation")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            showSearch
            value={USERS_ID_FULL_NAME?.find(d => d.uid == formik.values.uid)?.fullName}
            onChange={e => {
              const id = USERS_ID_FULL_NAME?.find(d => d.fullName == e)?.uid;
              formik.setFieldValue("uid", id);
            }}
          >
            {USERS_ID_FULL_NAME?.map(d => {
              return <Option value={d.fullName}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.projectCode")}>
          <Select
            // showSearch
            value={formik.values.projectId}
            onChange={e => {
              formik.setFieldValue("projectId", e);
              setProjectCodeId(e);
              formik.setFieldValue("budgetLineId", "");
            }}
          >
            {projectsMap?.map(d => {
              return <Option value={d.id}>{d.code}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.budgetLine")}>
          <Select
            // showSearch
            value={formik.values.budgetLineId}
            onChange={e => formik.setFieldValue("budgetLineId", e)}
          >
            {budgetLineMap?.map(d => {
              return <Option value={d.id}>{d.budgetLine}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.allocation")}>
          <InputNumber
            min={0}
            value={formik.values.percentage}
            onChange={e => {
              formik.setFieldValue("percentage", e);
            }}
          />
        </Form.Item>
        <Form.Item label={t("general.percentageOfProjectsAllocation")}>
          <InputNumber
            min={0}
            value={formik.values.idaAllocationPercentage}
            onChange={e => {
              formik.setFieldValue("idaAllocationPercentage", e);
            }}
          />
        </Form.Item>
        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={e => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={e => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditAllocation;
