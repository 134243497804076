import * as firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyB3R5HK0Ldnud--qDEUWvW40oOO2_NChZk",
  authDomain: "rc-hrms.firebaseapp.com",
  projectId: "rc-hrms",
  storageBucket: "rc-hrms.appspot.com",
  messagingSenderId: "328514852255",
  appId: "1:328514852255:web:b75cd05bc75ebd146e99aa",
  measurementId: "G-VJ7F1HL9PE"
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore!();
firestore.settings({});
firestore.enablePersistence({ synchronizeTabs: true });
//firebase.firestore().settings({});
export default firebaseConfig;
