import React, { useState } from "react";
import OrderSummary from "./OrderSummary";
import { auth } from "firebase/app";
import { Spin, Button, Card, Descriptions, Icon, Avatar, message } from "antd";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { orderRef, userRef } from "../../db/collectionsRef";
import { usersRef } from "../User/usersTable";
import { sortBy } from "lodash";
import moment from "moment";
import { formatMMM } from "../../db/dateFormat";
import { useTranslation } from "react-i18next";
import { delay } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase";

export default function OrderList() {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const antIcon = <Icon type="loading" style={{ fontSize: 25 }} spin />;
  const [userSN] = useDocument(usersRef.doc(uid));
  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const [userSNDelay] = useCollection(
    usersRef.doc(uid).collection("delay").where("month", "==", moment().format("MMM.YYYY"))
  );
  const [userSNCollection] = useCollection(usersRef);
  const supervisor = `${userSNCollection?.docs.find((d) => d.id == userSN?.data()?.managerUID)?.data()?.firstName} ${
    userSNCollection?.docs.find((d) => d.id == userSN?.data()?.managerUID)?.data()?.lastName
  }`;
  const convertToHours = (num: number) => {
    const hours = Math.floor(num / 60);
    const min = num % 60;
    return ("00" + hours).slice(-2) + ":" + ("00" + min).slice(-2);
  };
  const delays = userSNDelay?.docs
    .map((d) => {
      return { ...d?.data(), id: d.id } as delay;
    })
    .filter((d) => d.status != "disabled");

  let total = 0;
  delays?.map((d: delay, index: any) => {
    return (total = total + d.min);
  });

  let totalDay = 0;
  delays
    ?.filter((d: delay) => {
      const docDate = moment(d.dayDate.toDate()).format("DD-MM-YYYY").toString();
      const today = moment().format("DD-MM-YYYY").toString();
      return docDate == today;
    })
    .map((d: delay, index: any) => {
      return (totalDay = totalDay + d.min);
    });
  const orderStorageRef = firebase.storage().ref(uid).child("images");

  if (userSN?.data()?.adminLaw == true) {
    const [value] = useCollection(orderRef);
    const valueSN = value == undefined ? null : value.docs;
    const sortedList = sortBy(valueSN, (item) => item.data()?.requestedAt.toDate()).reverse();

    return (
      <div>
        <div style={{ background: "#ffffffc7", padding: "3%", borderRadius: "2%" }}>
          <div style={{ textAlign: "center", paddingBottom: "1%" }}>
            <label style={{ cursor: "pointer" }}>
              <Avatar shape="circle" size={150} icon="user" src={currentUserData.personalPhoto} />
              <FileUploader
                accept="image/*"
                name="avatar"
                hidden
                storageRef={orderStorageRef}
                onUploadStart={() => setUploadingPhoto(true)}
                onUploadSuccess={async (fileName: string) => {
                  const downloadURL = (await orderStorageRef.child(fileName).getDownloadURL()) as string;
                  setUploadingPhoto(false);
                  userRef.doc(uid).update({
                    personalPhoto: downloadURL,
                  });
                  message.success(" Uploaded successfully");
                }}
                onUploadError={(e: any) => message.success(e)}
              />
            </label>
            {uploadingPhoto ? <Spin indicator={antIcon} /> : null}
          </div>

          <Spin spinning={!value || !userSN || !userSNDelay} indicator={antIcon}>
            <div style={{ textAlign: "center" }}>
              <h2>
                <b>
                  {userSN.data()?.firstName} {userSN.data()?.lastName}
                </b>
              </h2>

              <h5>{userSN.data()?.position}</h5>
              <h5>{supervisor}</h5>
              <h5>{userSN.data()?.department}</h5>
              <h5><b>{`Vacation Annual Score: ${userSN.data()?.vacationAnnualScore.toFixed(3)}`}</b></h5>
              <h5><b>{`Vacation Sick Score: ${userSN.data()?.vacationSickScore.toFixed(3)}`}</b></h5>
              <h5><b>{`Vacation Compensation Score: ${userSN.data()?.vacationCompensationScore.toFixed(3)}`}</b></h5>
              <h5 style={{ color: "green" }}>
                {userSN.data()?.status == undefined ? "Not Registered Yet" : userSN.data()?.status=="active"? "Active" :"Disabled"}
              </h5>
            </div>

            <Descriptions
              style={{
                width: "100%",
                background: "rgb(240 242 245)",
                borderRadius: "5px",
              }}
              size={"small"}
              layout="vertical"
              bordered={true}
              column={{ xs: 8, sm: 6, md: 4 }}
            >
              <Descriptions.Item label={t("general.dailyDelay")} span={2}>
                {convertToHours(totalDay)}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.monthlyDelay")} span={2}>
                {convertToHours(total)}
              </Descriptions.Item>
            </Descriptions>
          </Spin>
        </div>
        {sortedList.map((doc: any) => {
          const data = doc?.data();
          return (
            <OrderSummary
              key={doc.id}
              id={doc.id}
              firstName={doc.data()?.firstName}
              lastName={doc.data()?.lastName}
              permitExpiryDate={moment(data?.permitExpiryDate.toDate()).format(formatMMM)}
              jobStartDate={moment(data?.jobStartDate.toDate() as any).format(formatMMM)}
              status={doc.data()?.status}
              firstTime={doc.data()?.firstTime}
            />
          );
        })}
      </div>
    );
  } else {
    const [value] = useCollection(orderRef.where("manager", "==", uid));
    const valueSN = value == undefined ? null : value.docs;
    const sortedList = sortBy(valueSN, (item) => item.data()?.requestedAt.toDate()).reverse();

    return (
      <div>
        <div style={{ background: "#ffffffc7", padding: "3%", borderRadius: "2%" }}>
          <div style={{ textAlign: "center", paddingBottom: "1%" }}>
            <label style={{ cursor: "pointer" }}>
              <Avatar shape="circle" size={150} icon="user" src={currentUserData.personalPhoto} />
              <FileUploader
                accept="image/*"
                name="avatar"
                hidden
                storageRef={orderStorageRef}
                onUploadStart={() => setUploadingPhoto(true)}
                onUploadSuccess={async (fileName: string) => {
                  const downloadURL = (await orderStorageRef.child(fileName).getDownloadURL()) as string;
                  setUploadingPhoto(false);
                  userRef.doc(uid).update({
                    personalPhoto: downloadURL,
                  });
                  message.success(" Uploaded successfully");
                }}
                onUploadError={(e: any) => message.success(e)}
              />
            </label>
            {uploadingPhoto ? <Spin indicator={antIcon} /> : null}
          </div>
          <Spin spinning={!value || !userSN || !userSNDelay} indicator={antIcon}>
          <div style={{ textAlign: "center" }}>
              <h2>
                <b>
                  {userSN?.data()?.firstName} {userSN?.data()?.lastName}
                </b>
              </h2>

              <h5>{userSN?.data()?.position}</h5>
              <h5>{supervisor}</h5>
              <h5>{userSN?.data()?.department}</h5>
              <h5><b>{`Vacation Annual Score: ${userSN?.data()?.vacationAnnualScore.toFixed(3)}`}</b></h5>
              <h5><b>{`Vacation Sick Score: ${userSN?.data()?.vacationSickScore.toFixed(3)}`}</b></h5>
              <h5><b>{`Vacation Compensation Score: ${userSN?.data()?.vacationCompensationScore.toFixed(3)}`}</b></h5>
              <h5 style={{ color: "green" }}>
                {userSN?.data()?.status == undefined ? "Not Registered Yet" : userSN?.data()?.status}
              </h5>
            </div>


            <Descriptions
              style={{
                width: "100%",
                background: "rgb(240 242 245)",
                borderRadius: "5px",
                
              }}
              size={"small"}
              layout="vertical"
              bordered={true}
              column={{ xs: 8, sm: 6, md: 4 }}
            >
              <Descriptions.Item label={t("general.dailyDelay")} span={2}>
                {convertToHours(totalDay)}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.monthlyDelay")} span={2}>
                {convertToHours(total)}
              </Descriptions.Item>
            </Descriptions>
          </Spin>
        </div>

        {sortedList.map((doc: any) => {
          const data = doc.data();
          return (
            <OrderSummary
              key={doc.id}
              id={doc.id}
              firstName={doc.data()?.firstName}
              lastName={doc.data()?.lastName}
              permitExpiryDate={moment(data?.permitExpiryDate.toDate()).format(formatMMM)}
              jobStartDate={moment(data?.jobStartDate.toDate()).format(formatMMM)}
              status={doc.data()?.status}
              firstTime={doc.data()?.firstTime}
            />
          );
        })}
      </div>
    );
  }
}
