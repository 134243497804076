import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, Popconfirm, Select } from "antd";
const { Option } = Select;

import { userRef, projectsRef, budgetLinesRef, allocationRef, officeRef } from "../../db/collectionsRef";
import { Link } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AddNewAllocation from "./AddNewAllocationModel";
import EditAllocation from "./EditAllocation";
import { currentUserData } from "../../db/userGlobal";
import DevTable from "../../helpers/devex-table";

export default function AllocationsTable() {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [officeId, setOfficeId] = useState(currentUserData.office);
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [allocationId, setAllocationId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [userUID, setUserUID] = useState("");
  const [allocationSN] = useCollection(allocationRef.where("office", "==", officeId));
  const [budgetLineSN, loading2, error2] = useCollection(budgetLinesRef.orderBy("createdAt"));
  const [projectsSN] = useCollection(projectsRef);
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);

  if (!budgetLineSN || !projectsSN || !allocationSN || !usersSN || !officeSN) return <Spin />;

  const allOfficesId = officeSN.docs.map(office => office.id);
  const UserListOffices = allOfficesId.map(id => {
    const officeName = officeSN?.docs?.find(d => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });

  const data = allocationSN.docs.map(d => {
    return { ...d.data(), id: d.id };
  });

  const columns = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.uid)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.uid)?.data()?.lastName
        }`
    },
    {
      name: "projectId",
      title: "Project Code",
      getCellValue: (row: any) => {
        return projectsSN.docs.find(d => d.id == row.projectId)?.data().projectCode;
      }
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
      getCellValue: (row: any) => {
        return budgetLineSN.docs.find(d => d.id == row.budgetLineId)?.data().budgetLine;
      }
    },
    { name: "percentage", title: "Allocation %", getCellValue: (row: any) => row.percentage + "%" },
    {
      name: "idaAllocationPercentage",
      title: "% of All",
      getCellValue: (row: any) => row.idaAllocationPercentage + "%"
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY")
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              allocationRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      }
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setAllocationId(row.id);
              setProjectId(row.projectId);
              setUserUID(row.uid);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      }
    }
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.uid)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.uid)?.data()?.lastName
        }`
    },
    {
      name: "projectId",
      title: "Project Code",
      getCellValue: (row: any) => {
        return projectsSN.docs.find(d => d.id == row.projectId)?.data().projectCode;
      }
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
      getCellValue: (row: any) => {
        return budgetLineSN.docs.find(d => d.id == row.budgetLineId)?.data().budgetLine;
      }
    },
    { name: "percentage", title: "Allocation %", getCellValue: (row: any) => row.percentage + "%" },
    {
      name: "idaAllocationPercentage",
      title: "% of All",
      getCellValue: (row: any) => row.idaAllocationPercentage + "%"
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY")
    }
  ];

  return (
    <div>
      <AddNewAllocation modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditAllocation
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          allocationId={allocationId}
          projectId={projectId}
          uid={userUID}
        />
      ) : null}{" "}
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewAllocation")}
      </Button>
      <Select
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "0.5%", margin: "1%" }}
        showSearch
        value={officeId}
        onChange={e => {
          setOfficeId(e);
        }}
      >
        {UserListOffices.map(d => {
          return <Option value={d.id}>{d.officeName}</Option>;
        })}
      </Select>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Allocations"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
