import React, { useState } from "react";
import OrderList from "../orders/OrderList";
import { Route, RouteComponentProps } from "react-router";
import OrderDetails from "../orders/OrderDetails";
import { Row, Col, Button } from "antd";
import AddNewOrder from "../models/AddNewOrder";
import Services from "./Services";

//import ProjectDetails from "../projects/ProjectDetails";

export default function Dashboard() {
  const [modalState, setModalState] = useState(false);

  return (
    <div>
      <div style={{ paddingLeft:"5%", paddingRight:"5%" }}>
        <Row gutter={30} style={{ alignItems: "center" }}>
          <Col span={5}>
            <OrderList />
          </Col>
          <Col span={17} style={{background:"#ffffffc7", borderRadius:"6px"}}>
            <Route exact path="/" component={(props: RouteComponentProps<{ id: string }>) => <Services />} />
            <Route
              path="/order/:id"
              component={(props: RouteComponentProps<{ id: string }>) => (
                <OrderDetails key={props.match.params.id} {...props} />
              )}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
