import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Button, Popconfirm } from "antd";
import { projectsRef, budgetLinesRef } from "../../db/collectionsRef";
import { Link } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import { useTranslation } from "react-i18next";
import AddNewBudgetLine from "./AddNewBudgetLineModel";
import DevTable from "../../helpers/devex-table";

const columnsExport = [
  { name: "budgetLine", title: "Budget Line" },
  { name: "projectCode", title: "Code" }
];

export default function BudgetLinesTable() {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;

  const [modalState, setModalState] = useState(false);

  const storage = firebase.storage();

  const [budgetLineSN, loading2, error2] = useCollection(budgetLinesRef.orderBy("createdAt"));
  const [projectsSN] = useCollection(projectsRef);

  if (!budgetLineSN || !projectsSN) return <Spin />;

  const data = budgetLineSN.docs.map(d => {
    return { ...d.data(), id: d.id };
  });

  const columns = [
    { name: "budgetLine", title: "Budget Line" },
    {
      name: "projectId",
      title: "Project Code",
      getCellValue: row => {
        return projectsSN.docs.find(d => d.id == row.projectId)?.data().projectCode;
      }
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              budgetLinesRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <div>
      <AddNewBudgetLine modalState={modalState} setModalState={setModalState} />
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewBudgetLine")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="BudgetLines"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
