import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col,
  Switch,
  TimePicker,
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
const { Option } = Select;
import { carRef, officeRef, userRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { car, office } from "../../db/interfaces";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  car: car | undefined;
}

function AddCar(props: Props) {
  const { t } = useTranslation();

  const { modalState, setModalState, car } = props;
  const [loading, setLoading] = useState(false);
  const uid = auth().currentUser!.uid;
  const [usersSN, loadingUsers] = useCollection(userRef);
  const [officesSN, loadingOffices] = useCollection(officeRef);

  const USERS_ID_FULL_NAME = usersSN?.docs.map((u) => {
    const fullName = u.data().firstName + " " + u.data().lastName;
    const uid = u.id;
    return { uid, fullName };
  });

  const OFFICES = officesSN?.docs.map((u) => {
    const officeData = u.data() as office;
    const officeName = officeData.officeName;
    const id = u.id;
    return { id, officeName };
  });

  const formik = useFormik({
    initialValues: {
      offices: [],
      name: "",
      description: "",
      capacity: 0,
      drivers: [],
      manager: "",
      status: "enabled",
    },
    onSubmit: async (values) => {
      console.log({ values });
      if (car) {
        carRef
          .doc(car.id)
          .update({
            name: values.name,
            description: values.description,
            capacity: values.capacity,
            offices: values.offices as string[],
            drivers: values.drivers as string[],
            manager: values.manager,
            status: values.status,
          } as car)
          .then(() => {
            message.success("Edit Successfully!");
            setModalState(false);
          });
      } else {
        carRef
          .add({
            name: values.name,
            description: values.description,
            capacity: values.capacity,
            offices: values.offices as string[],
            drivers: values.drivers as string[],
            manager: values.manager,
            status: values.status,
          } as car)
          .then(() => {
            message.success("Added Successfully!");
            setModalState(false);
          });
      }
    },
  });

  const enterLoading = () => {
    setLoading(true);
  };

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (car && loaded) {
      formik.setValues({
        name: car.name as string,
        description: car.description as string,
        capacity: car.capacity as number,
        offices: car.offices as string[],
        drivers: car.drivers as string[],
        manager: car.manager as string,
        status: car.status as string,
      } as any);
      setLoaded(false);
    }
  }, [car, formik, loaded]);

  return loadingUsers || loadingOffices ? (
    <Spin />
  ) : (
    <Drawer
      title={t("general.carInformation")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit} layout="horizontal">
        <Form.Item label={t("general.carName")}>
          <Input value={formik.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.description")}>
          <Input
            value={formik.values.description}
            onChange={(e) => formik.setFieldValue("description", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.capacity")}>
          <InputNumber value={formik.values.capacity} onChange={(e) => formik.setFieldValue("capacity", e)} />
        </Form.Item>
        <Form.Item label={t("general.drivers")}>
          <Select
            mode="multiple"
            showSearch
            value={formik.values.drivers.map((id: string) => USERS_ID_FULL_NAME?.find((d) => d.uid == id)?.fullName)}
            onChange={(e: any) => {
              const uids = e.map((name: string) => {
                const id = USERS_ID_FULL_NAME?.find((d) => d.fullName == name)?.uid;
                return id;
              });
              formik.setFieldValue("drivers", uids);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.fullName}>
                  {d.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.offices")}>
          <Select
            mode="multiple"
            showSearch
            value={formik.values.offices.map((id: string) => OFFICES?.find((d) => d.id == id)?.officeName)}
            onChange={(e: any) => {
              const ids = e.map((name: string) => {
                const id = OFFICES?.find((d) => d.officeName == name)?.id;
                return id;
              });
              formik.setFieldValue("offices", ids);
            }}
          >
            {OFFICES?.map((d) => {
              return (
                <Option key={d.id} value={d.officeName}>
                  {d.officeName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item labelAlign="right" label={t("general.status")}>
          <Switch
            checked={formik.values.status == "enabled" ? true : false}
            onChange={(e) => {
              const status = e ? "enabled" : "disabled";
              formik.setFieldValue("status", status);
            }}
          />
        </Form.Item>
        <Form.Item label={t("general.manager")}>
          <Select
            showSearch
            value={USERS_ID_FULL_NAME?.find((d) => d.uid == formik.values.manager)?.fullName}
            onChange={(e: any) => {
              const id = USERS_ID_FULL_NAME?.find((d) => d.fullName == e)?.uid;

              formik.setFieldValue("manager", id);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.fullName}>
                  {d.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddCar;
