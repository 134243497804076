import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col,
  Modal
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { notificationsRef, userRef, orderRef, meetingRoomList, calendar } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import DraftText from "./TextEditor/Draft";
import { uniq } from "lodash";

interface Props {
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  modalStateSendMOM: boolean;
  setModalStateSendMOM: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  MOM: string;
  mentions: any;
  MOMWriter: string;
  files: any[]
  downloadURLs:any[]
}

function SendMOM(props: Props) {
  const { t } = useTranslation();
  const { modalStateSendMOM, setModalStateSendMOM, MOM, mentions, MOMWriter, setModalState, files , downloadURLs} = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const newId = meetingRoomList.doc().id;
  const uid = auth().currentUser!.uid;
  const momAttachStorage = firebase.storage().ref(uid);

  return (
    <Modal
      title={t("general.addMOM")}
      okText={t("general.yes")}
      cancelText={t("general.no")}
      visible={modalStateSendMOM}
      onCancel={async () => {
        console.log("is works?");
        try {
         
      await calendar.doc(props.docId).update({
        momAttach:downloadURLs,
        filesNames:files,
        MOM: MOM,
        MOMWriter: MOMWriter,
        sendMOM: true
      });
          await mentions.map((id: string) => {
            notificationsRef.add({
              notifyTo: id,
              notifyFrom: uid,
              orderId: props.docId,
              read: false,
              kind: "mention",
              content: "Someone mentioned you in Meetings!",
              postedAt: new Date() as any
            });
          });
          // setEditorState(createEditorStateWithText(""));
          message.success(t("general.success"));

          setModalStateSendMOM(false);
        } catch (e) {
          console.log(e);
          message.error(t("general.error"));
        } finally {
          setLoading(false);
        }
      }}
      onOk={async () => {
        try {
       

          await calendar.doc(props.docId).update({
            momAttach:downloadURLs,
            filesNames:files,
            MOM: MOM,
            MOMWriter: MOMWriter,
            sendMOM: true
          });

                
           
          await mentions.map((id: string) => {
            notificationsRef.add({
              notifyTo: id,
              notifyFrom: uid,
              orderId: props.docId,
              read: false,
              kind: "mention",
              content: "Someone mentioned you in Meetings!",
              postedAt: new Date() as any
            });
          });
          message.success(t("general.success"));

          setModalStateSendMOM(false);
        } catch (e) {
          console.log(e);
          message.error(t("general.error"));
        } finally {
          setLoading(false);
        }
      }}
      closable={false}
    >
      <div>Do you want to send MOM via Email?</div>
    </Modal>
  );
}

export default SendMOM;
