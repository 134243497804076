import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import {
  RouteComponentProps,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col
} from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;

import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  notificationsRef,
  userRef,
  orderRef,
  meetingRoomList,
  projectsRef,
  budgetLinesRef,
  allocationRef
} from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  projectId: Yup.string().required("*"),
  uid: Yup.string().required("*"),
  percentage: Yup.number().required("*"),
  budgetLineId: Yup.string().required("*")
});

function AddNewAllocation(props: Props) {
  const { t } = useTranslation();
  const [projectCodeId, setProjectCodeId] = useState("");
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const newId = allocationRef.doc().id;
  const uid = auth().currentUser!.uid;
  const [projectsSN] = useCollection(projectsRef);
  const [usersSN] = useCollection(userRef);
  const [budgetLinesSN] = useCollection(budgetLinesRef);

  const projectsMap = projectsSN?.docs.map(d => {
    return {
      id: d.id,
      code: d.data()?.projectCode
    };
  });
  const USERS_ID_FULL_NAME = usersSN?.docs.map(u => {
    const fullName =
      u.data().firstName +
      " " +
      u.data().lastName +
      " _ " +
      u.data().arabicFullName;
    const uid = u.id;
    return { uid, fullName };
  });

  const budgetLineMap = budgetLinesSN?.docs
    .filter(d => {
      return d.data().projectId == projectCodeId;
    })
    .map(d => {
      return {
        id: d.id,
        budgetLine: d.data().budgetLine
      };
    });
  const formik = useFormik({
    initialValues: {
      uid: "",
      projectId: "",
      budgetLineId: "",
      percentage: 0,
      idaAllocationPercentage: 0,
      startDate: moment(),
      endDate: moment()
    },
    validationSchema: projectSchema,
    onSubmit: async values => {
      const promise = allocationRef.doc(newId).set(
        {
          uid: values.uid,
          office: usersSN?.docs?.find(d => d.id == values.uid)?.data()?.office,
          projectId: values.projectId,
          budgetLineId: values.budgetLineId,
          percentage: values.percentage,
          idaAllocationPercentage: values.idaAllocationPercentage,
          startDate: values.startDate.toDate() as any,
          endDate: values.endDate.toDate() as any,
          createdAt: new Date()
        },
        { merge: true }
      );
      promise.then(() => {
        setModalState(false), setLoading(false);
      });
      promise.then(() => {
        userRef.doc(values.uid).update({
          idaAllocationPercentage: values.idaAllocationPercentage
        });
      });
      promise.catch(error => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    }
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.addNewAllocation")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            showSearch
            value={
              USERS_ID_FULL_NAME?.find(d => d.uid == formik.values.uid)
                ?.fullName
            }
            onChange={e => {
              const id = USERS_ID_FULL_NAME?.find(d => d.fullName == e)?.uid;
              formik.setFieldValue("uid", id);
            }}
          >
            {USERS_ID_FULL_NAME?.map(d => {
              return <Option value={d.fullName}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.projectCode")}>
          <Select
            showSearch
            value={
              projectsMap?.find(d => d.id == formik.values.projectId)?.code
            }
            onChange={e => {
              const id = projectsMap?.find(p => p.code == e)?.id;
              formik.setFieldValue("projectId", id);
              setProjectCodeId(id);
            }}
          >
            {projectsMap?.map(d => {
              return <Option value={d.code}>{d.code}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.budgetLine")}>
          <Select
            // showSearch
            value={formik.values.budgetLineId}
            onChange={e => formik.setFieldValue("budgetLineId", e)}
          >
            {budgetLineMap?.map(d => {
              return <Option value={d.id}>{d.budgetLine}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.allocation")}>
          <InputNumber
            min={0}
            value={formik.values.percentage}
            onChange={e => {
              formik.setFieldValue("percentage", e);
            }}
          />
        </Form.Item>
        <Form.Item label={"Contract Percentage"}>
          <InputNumber
            min={0}
            value={formik.values.idaAllocationPercentage}
            onChange={e => {
              formik.setFieldValue("idaAllocationPercentage", e);
            }}
          />
        </Form.Item>
        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={e => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={e => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewAllocation;
