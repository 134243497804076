import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col
} from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;

import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  userRef,
 
  positionRef
} from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
import { , position, users } from "../../db/interfaces";

interface Props {
  positionId: string;
  modalState: boolean;
  uid: string;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  uid: Yup.string().required("*"),
  position: Yup.string().required("*"),

});

function EditPosition(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const newId = positionRef.doc().id;
  const uid = auth().currentUser!.uid;
  const [usersSN] = useCollection(userRef);
  const [positionSN] = useDocument(positionRef.doc(props.positionId));
  const [userSN] = useDocument(userRef.doc(props.uid));

  const positionData = positionSN?.data() as position;
  const userData = userSN?.data() as users;

 
  const USERS_ID_FULL_NAME = usersSN?.docs.map(u => {
    const fullName = u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName;
    const uid = u.id;
    return { uid, fullName };
  });

  
  const formik = useFormik({
    initialValues: {
      uid: "",
      position:"",
        startDate: moment(),
        endDate: moment()
    },
    validationSchema: projectSchema,
    onSubmit: async values => {
      const promise = positionRef.doc(props.positionId).update({
        uid: values.uid,
        position:values.position,
         startDate: values.startDate.toDate() as any,
         endDate: values.endDate.toDate() as any,
         createdAt: new Date()

      }).then(()=>{
        userRef.doc(values.uid).update({
          position: values.position
        })
      })
    
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch(error => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    }
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (positionSN && loaded && userSN) {
      formik.setValues({
        uid: positionData.uid,
        position: positionData.position,
        startDate: moment(positionData.startDate.toDate()),
        endDate: moment(positionData.endDate.toDate())
      } as any);
      setLoaded(false);

      // setItemLineCode(procurementPlan.itemLineCode)
    }
  }, [positionSN, formik, loaded, userSN]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.editNewPosition")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            showSearch
            value={USERS_ID_FULL_NAME?.find(d => d.uid == formik.values.uid)?.fullName}
            onChange={e => {
              const id = USERS_ID_FULL_NAME?.find(d => d.fullName == e)?.uid;
              formik.setFieldValue("uid", id);
            }}
          >
            {USERS_ID_FULL_NAME?.map(d => {
              return <Option value={d.fullName}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.position")}>
          <Input
            value={formik.values.position}
            onChange={e => {
              formik.setFieldValue("position", e.target.value);
            }}
          />
        </Form.Item>
    

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={e => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={e => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditPosition;
