import React, { useState } from "react";
import { auth } from "firebase/app";
import { Spin, Button } from "antd";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { overtimeRef, officeRef } from "../../db/collectionsRef";
import { usersRef } from "../User/usersTable";
import { sortBy } from "lodash";
import AddNewOvertime from "./OverTimeModules/AddNewOvertime";
import OvertimeSummary from "./overtimeSummary";
import { currentUserData } from "../../db/userGlobal";

export default function OvertimeList() {
  const [modalState, setModalState] = useState(false);
  const uid = auth().currentUser!.uid;

  const [userSN] = useDocument(usersRef.doc(uid));
  const [userSNC] = useCollection(usersRef);
  const [officeSN] = useCollection(officeRef);

  const isAdmin = userSN?.data()?.admin;
  const isHR = userSN?.data()?.adminHR;
  const isCEO = userSN?.data()?.CEO;

  if (isAdmin || isHR || isCEO) {
    const [value] = useCollection(overtimeRef);
    const valueSN =
      value == undefined
        ? null
        : value.docs.filter(
            e =>
              currentUserData.fullControlOffices.includes(e.data()?.office) ||
              e.data()?.employeeUID == uid ||
              e.data()?.managerUID == uid ||
              isCEO
          );
    const sortedList = sortBy(valueSN, item => item.data().requestedAt.toDate()).reverse();

    if (!value || !userSN)
      return (
        <div>
          <Spin size="small" />
        </div>
      );
    return (
      <div>
        <AddNewOvertime modalState={modalState} setModalState={setModalState} />

        {sortedList.map(doc => {
          const data = doc.data();
          const fullName = `${userSNC?.docs.find(d => d.id == data.employeeUID)?.data().firstName} ${
            userSNC?.docs.find(d => d.id == data.employeeUID)?.data().lastName
          }`;
          return <OvertimeSummary key={doc.id} id={doc.id} employeeUID={fullName} status={doc.data().status} />;
        })}
        <Button
          type="primary"
          shape="circle"
          size="large"
          onClick={() => {
            setModalState(true);
          }}
        >
          +
        </Button>
      </div>
    );
  } else {
    const [value] = useCollection(overtimeRef.where("employeeUID", "==", uid));
    const valueSN = value == undefined ? null : value.docs;
    const sortedList = sortBy(valueSN, item => item.data().requestedAt.toDate()).reverse();
    if (!value || !userSN)
      return (
        <div>
          <Spin size="small" />
        </div>
      );
    return (
      <div>
        <AddNewOvertime modalState={modalState} setModalState={setModalState} />

        {sortedList.map(doc => {
          const data = doc.data();
          const fullName = `${userSNC?.docs.find(d => d.id == data.employeeUID)?.data().firstName} ${
            userSNC?.docs.find(d => d.id == data.employeeUID)?.data().lastName
          }`;
          return <OvertimeSummary key={doc.id} id={doc.id} employeeUID={fullName} status={doc.data().status} />;
        })}
        <Button
          type="primary"
          shape="circle"
          size="large"
          onClick={() => {
            setModalState(true);
          }}
        >
          +
        </Button>
      </div>
    );
  }
}
