import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import { Spin, Button, message, Descriptions, Badge, Input, Select, Popconfirm, Icon, DatePicker } from "antd";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import Discussion from "../../discussion/discussion";
import { userRef, discussionsRef, notificationsRef, vacationRef, officeRef } from "../../db/collectionsRef";
import FileUploader from "react-firebase-file-uploader";
import moment from "moment";
import RFLTurkeyMission from "./RFLTurkeyMission.docx";
import RFLIraqMission from "./RFLIraqMission.docx";
import { format } from "../../db/dateFormat";
import { useTranslation } from "react-i18next";
import { vacations, users } from "../../db/interfaces";
import EditVacation from "./VacationsModules/EditVacation";
import { generateAndUploadWord } from "./generateAndUploadWord";
import { currentUserData } from "../../db/userGlobal";
import { globalOfficesData } from "../../global/officeList";
import VacationView from "./vacationView";
import { HRManager } from "../../db/admin";
const storage = firebase.storage();
const db = firebase.firestore();

export default function VacationDetails() {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const { t } = useTranslation();
  const history = useHistory();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploadingContract, setUploadingContract] = useState(false);
  const [file, setFile] = useState("");

  const id = useRouteMatch<{ id: string }>().params.id;
  const [orderSnapshot, error] = useDocument(vacationRef.doc(id));
  const [VacationAll] = useCollection(vacationRef);
  const [usersSnapshot] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);

  const [discussionSN] = useCollection(discussionsRef.where("orderId", "==", id));
  const [notificationSN] = useCollection(notificationsRef.where("orderId", "==", id));

  const uid = auth().currentUser!.uid;
  const notificationSNObject = (notificationSN === undefined ? [] : notificationSN.docs).map((doc) => {
    return {
      orderId: doc.data().orderId,
      id: doc.id,
    };
  });
  const discussionSNObject = (discussionSN === undefined ? [] : discussionSN.docs).map((doc) => {
    return {
      orderId: doc.data().orderId,
      id: doc.id,
    };
  });

  const deleteOrder = async () => {
    history.push(`/vacation`);
    await discussionSNObject
      .filter((e) => e.orderId === id)
      .map((x) => x.id)
      .forEach((docId) => {
        discussionsRef.doc(docId).delete();
      });
    await notificationSNObject
      .filter((e) => e.orderId === id)
      .map((x) => x.id)
      .forEach((docId) => {
        notificationsRef.doc(docId).delete();
      });
    const promise = db.collection("vacations").doc(id).delete();

    promise.then(() => {
      message.success(t("general.successfulDelete"));
      console.log("Document successfully deleted!");
      userRef.doc(employeeUID).update({ vacationAbility: true });
    });
    promise.catch((error: any) => {
      console.error("Error removing document: ", error);
    });
  };

  const enterLoading = () => {
    setLoading(true);
  };
  const print1CbRef = useRef<() => void>();
  const print = useCallback(() => {
    setTimeout(() => {
      print1CbRef.current();
    }, 1500);
  }, []);

  if (!orderSnapshot || !usersSnapshot || !VacationAll || !officeSN) {
    return <Spin size="large" />;
  }

  const data = orderSnapshot!.data()!;
  const {
    vacationType,
    employeeUID,
    managerUID,
    adminUID,
    HRUserUID,
    employeeSignatureURL,
    managerSignatureURL,
    adminSignatureURL,
    HRSignatureURL,
    version,
  } = data as vacations;

  const userId = employeeUID;
  const vacationId = id;
  const office = officeSN.docs.find((d) => d.id == data.office)?.data().officeName;

  const empFullName = `${usersSnapshot?.docs.find((d) => d.id == employeeUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == employeeUID)?.data().lastName
  }`;
  const managerFullName = `${usersSnapshot?.docs.find((d) => d.id == managerUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == managerUID)?.data().lastName
  }`;
  const adminFullName = `${usersSnapshot?.docs.find((d) => d.id == adminUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == adminUID)?.data().lastName
  }`;
  const HRFullName = `${usersSnapshot?.docs.find((d) => d.id == HRUserUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == HRUserUID)?.data().lastName
  }`;

  const status = orderSnapshot?.data()?.status;
  const statusBadge =
    status == "Requested"
      ? "warning"
      : status == "Pending"
      ? "processing"
      : status == "Rejected" || status == "Canceled"
      ? "default"
      : status == "Accepted"
      ? "success"
      : "error";

  const vacationStorageRef = firebase.storage().ref(employeeUID);
  const dateFormat = "YYYY/MM/DD hh:mm:ss";
  const reChangeOnScore = () => {
    if (vacationType == "Annual Leave") {
      userRef.doc(employeeUID).update({
        vacationAnnualScore: firebase.firestore.FieldValue.increment(data.vocationPeriodDays),
      });
    } else if (vacationType == "Sick Leave") {
      userRef.doc(employeeUID).update({
        vacationSickScore: firebase.firestore.FieldValue.increment(data.vocationPeriodDays),
      });
    } else if (vacationType == "Compensation Leave") {
      userRef.doc(employeeUID).update({
        vacationCompensationScore: firebase.firestore.FieldValue.increment(data.vocationPeriodDays),
      });
    } else if (vacationType == "Death Leave") {
      userRef.doc(employeeUID).update({
        vacationDeathScore: firebase.firestore.FieldValue.increment(data.vocationPeriodDays),
      });
    } else if (vacationType == "Marriage Leave") {
      userRef.doc(employeeUID).update({
        vacationMarriageScore: firebase.firestore.FieldValue.increment(data.vocationPeriodDays),
      });
    } else if (vacationType == "Paternity Leave") {
      userRef.doc(employeeUID).update({
        vacationPaternityScore: firebase.firestore.FieldValue.increment(data.vocationPeriodDays),
      });
    } else if (vacationType == "Maternity Leave") {
      userRef.doc(employeeUID).update({
        vacationMaternityScore: firebase.firestore.FieldValue.increment(data.vocationPeriodDays),
      });
    }
  };
  const changeOnScore = async () => {
    if (vacationType == "Annual Leave") {
      userRef.doc(employeeUID).update({
        vacationAnnualScore: firebase.firestore.FieldValue.increment(-data.vocationPeriodDays),
      });
    } else if (vacationType == "Sick Leave") {
      userRef.doc(employeeUID).update({
        vacationSickScore: firebase.firestore.FieldValue.increment(-data.vocationPeriodDays),
      });
    } else if (vacationType == "Compensation Leave") {
      userRef.doc(employeeUID).update({
        vacationCompensationScore: firebase.firestore.FieldValue.increment(-data.vocationPeriodDays),
      });
    } else if (vacationType == "Death Leave") {
      userRef.doc(employeeUID).update({
        vacationDeathScore: firebase.firestore.FieldValue.increment(-data.vocationPeriodDays),
      });
    } else if (vacationType == "Marriage Leave") {
      userRef.doc(employeeUID).update({
        vacationMarriageScore: firebase.firestore.FieldValue.increment(-data.vocationPeriodDays),
      });
    } else if (vacationType == "Paternity Leave") {
      userRef.doc(employeeUID).update({
        vacationPaternityScore: firebase.firestore.FieldValue.increment(-data.vocationPeriodDays),
      });
    } else if (vacationType == "Maternity Leave") {
      userRef.doc(employeeUID).update({
        vacationMaternityScore: firebase.firestore.FieldValue.increment(-data.vocationPeriodDays),
      });
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      {modalState ? (
        <EditVacation modalState={modalState} setModalState={setModalState} orderId={id} employeeUID={employeeUID} />
      ) : null}

      {/* //------------------- */}
      <Descriptions
        size={"default"}
        title={`${vacationType}`}
        layout="horizontal"
        bordered={true}
        column={{ xs: 8, sm: 6, md: 4 }}
      >
        <Descriptions.Item label={t("general.requestedAt")}>
          {moment(data.requestedAt.toDate()).format(dateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.status")}>
          <Badge status={statusBadge} text={status} />
        </Descriptions.Item>
      </Descriptions>
      {/* //------------------- */}

      <Descriptions size={"default"} layout="horizontal" bordered={true} column={{ xs: 8, sm: 6, md: 4 }}>
        <Descriptions.Item label={t("general.fullName")} span={6}>
          {empFullName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.position")} span={2}>
          {data.position}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.manager")} span={2}>
          {managerFullName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.location")} span={2}>
          {data.office == "HQ" ? data.office : officeSN.docs.find((d) => d.id == data.office)?.data().officeName}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.vacationType")} span={2}>
          {data.vacationType}{" "}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.vocationPeriodDays")} span={2}>
          {data.vocationPeriodDays.toFixed(1)}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.vacationStartOn")} span={2}>
          {moment(data.vacationStartOn.toDate()).format(format)}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.vacationEndOn")} span={2}>
          {moment(data.vacationEndOn.toDate()).format(format)}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.startWorkOn")} span={2}>
          {moment(data.startWorkOn.toDate()).format(format)}{" "}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.vacationAnnualScore")} span={2}>
          {data.vacationAnnualScore.toFixed(3)}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.vacationSickScore")} span={2}>
          {data.vacationSickScore.toFixed(1)}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.vacationCompensationScore")} span={6}>
          {data.vacationCompensationScore.toFixed(3)}{" "}
        </Descriptions.Item>
        {data.halfDay == "" ? null : (
          <Descriptions.Item label={t("general.halfDay")} span={6}>
            {data.halfDay}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={t("general.employeeSignatureDate")} span={2}>
          {data.employeeSignatureDate == undefined || data.employeeSignatureDate == "" ? (
            <Button
              style={{ width: "150px" }}
              type="primary"
              onClick={() =>
                vacationRef.doc(vacationId).update({
                  employeeSignatureDate: new Date(),
                })
              }
            >
              Approve
            </Button>
          ) : (
            <div>
              {employeeSignatureURL == "" || employeeSignatureURL == undefined ? null : (
                <img height={100} src={employeeSignatureURL} />
              )}
              <p> {moment(data.employeeSignatureDate.toDate()).format(dateFormat)} </p>
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.managerSignatureDate")} span={2}>
          {data.managerSignatureDate == undefined || data.managerSignatureDate == "" ? (
            data.managerUID == uid ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={() =>
                  vacationRef.doc(vacationId).update({
                    managerSignatureDate: new Date(),
                    managerSignatureURL: currentUserData.signatureURL ?? "",
                    status: "Pending",
                  })
                }
              >
                Approve
              </Button>
            ) : null
          ) : (
            <div>
              {managerSignatureURL == "" || managerSignatureURL == undefined ? null : (
                <img height={100} src={managerSignatureURL} />
              )}
              <p> {moment(data.managerSignatureDate.toDate()).format(dateFormat)} </p>
            </div>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.adminUser")} span={2}>
          {data.HRSignatureDate == "" ? null : data.adminSignatureDate == undefined || data.adminSignatureDate == "" ? (
            currentUserData.admin && currentUserData.adminOffices.includes(data.office) ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={async () => {
                  vacationRef
                    .doc(vacationId)
                    .update({
                      adminSignatureDate: new Date(),
                      adminSignatureURL: currentUserData.signatureURL ?? "",
                      status: "Accepted",
                      adminUID: uid,
                    })
                    .then(() => {
                      if (version != 2) {
                        changeOnScore();
                      }
                    });
                }}
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {adminFullName} </p>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.HRUser")} span={2}>
          {data.managerSignatureDate == "" ? null : data.HRSignatureDate == undefined || data.HRSignatureDate == "" ? (
            currentUserData.adminHR && currentUserData.HROffices.includes(data.office) ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={() =>
                  vacationRef.doc(vacationId).update({
                    HRSignatureDate: new Date(),
                    HRSignatureURL: currentUserData.signatureURL ?? "",
                    HRUserUID: uid,
                  })
                }
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {HRFullName} </p>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.adminSignatureDate")} span={2}>
          {data.adminSignatureDate == "" ? null : (
            <div>
              {" "}
              {adminSignatureURL == "" || adminSignatureURL == undefined ? null : (
                <img height={100} src={adminSignatureURL} />
              )}
              <p> {moment(data.adminSignatureDate.toDate()).format(dateFormat)} </p>
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.HRSignatureDate")} span={2}>
          {data.HRSignatureDate == "" ? null : (
            <div>
              {" "}
              {HRSignatureURL == "" || HRSignatureURL == undefined ? null : <img height={100} src={HRSignatureURL} />}
              <p> {moment(data.HRSignatureDate.toDate()).format(dateFormat)} </p>
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.actions")} span={6}>
          <div>
            {data.status == "Pending" || data.status == "Requested" ? (
              <Button type="primary" style={{ margin: "1%" }} onClick={() => setModalState(true)}>
                Edit
              </Button>
            ) : null}
            {data.status == "Requested" || uid == "Jmlv0ajWF9RoGv4jWsvosBIBS1s1" ? (
              <Button
                type="danger"
                style={{ margin: "1%" }}
                onClick={() => {
                  deleteOrder();
                  reChangeOnScore();
                }}
              >
                Delete
              </Button>
            ) : null}
            {data.employeeUID == uid ? null : data.status == "Pending" || data.status == "Requested" ? (
              <Button
                style={{ margin: "1%" }}
                type="default"
                onClick={() => {
                  vacationRef.doc(vacationId).update({
                    status: "Rejected",
                    rejectedBy: uid,
                  });
                  // .then(() => {
                  //   userRef.doc(employeeUID).update({ vacationAbility: true });
                  // });
                }}
              >
                Reject
              </Button>
            ) : null}
            {data.status == "Accepted" ? (
              <div>
                {!currentUserData.adminHR ? null : (
                  <Button
                    style={{ margin: "1%" }}
                    type="danger"
                    onClick={() => {
                      vacationRef
                        .doc(vacationId)
                        .update({
                          status: "Canceled",
                          canceledBy: uid,
                        })
                        .then(() => {
                          reChangeOnScore();
                        });
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {employeeSignatureURL && managerSignatureURL && adminSignatureURL && HRSignatureURL ? (
                  <Button
                    style={{ margin: "1%" }}
                    onClick={() => {
                      print();
                    }}
                  >
                    Print
                  </Button>
                ) : null}
                {currentUserData.fullControlOffices.includes(data.office) ? (
                  <Button
                    style={{ margin: "1%" }}
                    loading={downloadLoading}
                    type="primary"
                    onClick={async () => {
                      setDownloadLoading(true);
                      generateAndUploadWord(
                        globalOfficesData[data.office].cacheMissionName == "IRAQ" ? RFLIraqMission : RFLTurkeyMission,
                        data,
                        vacationId,
                        uid,
                        empFullName,
                        managerFullName,
                        adminFullName,
                        HRFullName,
                        vacationType,
                        setDownloadLoading,
                        office,
                        globalOfficesData[data.office].cacheMissionName
                      );
                    }}
                  >
                    <Icon type="download" />
                    Print Word file
                  </Button>
                ) : null}

                <label
                  style={{
                    background: "rgb(24 144 255)",
                    padding: "1%",
                    marginRight: "3%",
                    borderColor: "rgb(24 144 255)",
                    borderRadius: "6px",
                    color: "white",
                  }}
                >
                  <Icon type="upload" />
                  <span style={{ padding: "2%" }}>{t("general.uploadContract")}</span>
                  {uploadingContract ? <Spin indicator={antIcon} style={{ color: "#fff" }} /> : null}
                  {data.archivedRFL == undefined || data.archivedRFL == "" ? null : (
                    <Icon type="check" style={{ color: "#fff", padding: "1%" }} />
                  )}

                  <FileUploader
                    hidden
                    storageRef={vacationStorageRef}
                    onUploadStart={() => setUploadingContract(true)}
                    onUploadSuccess={async (fileName: string) => {
                      setUploadingContract(false);
                      setFile(fileName);

                      const downloadURL = await vacationStorageRef.child(fileName).getDownloadURL();
                      await vacationRef
                        .doc(vacationId)
                        .update({
                          archivedRFL: downloadURL,
                        })
                        .then(async () => {
                          await message.success(t("general.successfulUpload"));
                        });
                    }}
                  />
                </label>
              </div>
            ) : null}
          </div>
        </Descriptions.Item>
      </Descriptions>

      {/* //------------------- */}

      <Discussion userId={userId} orderId={vacationId} type={"vacation"} officeId={data.office} />
      <br />
      <div style={{ display: "none" }}>
        <VacationView
          vacation={data as vacations}
          printFnRef={print1CbRef as any}
          statics={{
            office,
            empFullName,
            managerFullName,
            adminFullName,
            HRFullName,
          }}
        />
      </div>
    </div>
  );
}
