import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Icon,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useTranslation } from "react-i18next";

const { Option } = Select;

import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notificationsRef, userRef, orderRef } from "../../db/collectionsRef";
import FileUploader from "react-firebase-file-uploader";
import OrderDetails from "../orders/OrderDetails";
import { generateAndUploadWord } from "../orders/generateAndUploadWord";
import contract from "../orders/contract.docx";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  orderId: string;
}
const projectSchema = Yup.object().shape({
  TC: Yup.number().required("*"),
  // firstName: Yup.string().required("*"),
  // lastName: Yup.string().required("*"),
  // fatherName: Yup.string().required("*"),
  // motherName: Yup.string().required("*"),
  // gender: Yup.string().required("*"),
  // // birthPlace: Yup.string().required("*"),
  // birthDate: Yup.date()
  //   .min(new Date("01-01-1930"))
  //   .max(new Date())
  //   .required("*"), // marriedStatus: Yup.string().required("*"),
  // nationality: Yup.string().required("*"),
  femaleChildrenNumber: Yup.number()
    .min(0)
    .max(20)
    .required("*"),
  maleChildrenNumber: Yup.number()
    .min(0)
    .max(20)
    .required("*")
  // wifeNationality: Yup.string().required("*"),
  // wifeFirstName: Yup.string().required("*"),
  // wifeLastName: Yup.string().required("*"),
  // wifeTC: Yup.string().required("*"),
  // phone: Yup.string().required("*"),
  // email: Yup.string().required("*"),
  // residenceAddress: Yup.string().required("*"),
  // universityNameAddress: Yup.string().required("*"),
  // degree: Yup.string().required("*"),
  // degreeField: Yup.string().required("*"),
  // degreeFaculty: Yup.string().required("*"),
  // degreeSpecialization: Yup.string().required("*"),
  // passportNO: Yup.string().required("*"),
  // passportExpiryDate: Yup.date().required("*")
  // passportAuth: Yup.string().required("*"),
  // position: Yup.string().required("*"),
  // salary: Yup.string().required("*"),
  // cityInSyria: Yup.string().required("*"),
  // durationInTurkey: Yup.string().required("*"),
  // language: Yup.string().required("*")
});

function EditOrder(props: Props) {
  const { t } = useTranslation();
  //-----upload states
  const [uploadingPermit, setUploadingPermit] = useState(false);
  const [uploadingPassport, setUploadingPassport] = useState(false);
  const [uploadingDiploma, setUploadingDiploma] = useState(false);
  const [uploadingID, setUploadingID] = useState(false);
  const [uploadingPersonalPhoto, setUploadingPersonalPhoto] = useState(false);

  const [IDURL, setIDURL] = useState("");
  const [permitCardURL, setPermitCardURL] = useState("");
  const [passportPhoto, setPassportPhoto] = useState("");
  const [diplomaURL, setDiplomaURL] = useState("");
  const [personalPhoto, setPersonalPhoto] = useState("");

  //-----upload status ends
  const { modalState, setModalState } = props;
  const [isCreate, setIsCreate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [firstTime, setFirstTime] = useState("");
  const history = useHistory();

  const [orderDocSN] = useDocument(orderRef.doc(props.orderId));
  const [usersAdminSN] = useCollection(userRef.where("adminLaw", "==", true));
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const uid = auth().currentUser!.uid;

  const formik = useFormik({
    initialValues: {
      jobStartDate: moment(),
      permitExpiryDate: moment(),
      TC: "",
      firstName: "",
      lastName: "",
      fatherName: "",
      motherName: "",
      gender: "",
      birthPlace: "",
      birthDate: moment(),
      marriedStatus: "",
      nationality: "",
      femaleChildrenNumber: 0,
      maleChildrenNumber: 0,
      wifeNationality: "",
      wifeFirstName: "",
      wifeLastName: "",
      wifeTC: "",
      phone: "",
      email: "",
      residenceAddress: "",
      universityNameAddress: "",
      degree: "",
      degreeField: "",
      degreeFaculty: "",
      degreeSpecialization: "",
      passportNO: "",
      passportExpiryDate: moment(),
      passportAuth: "",
      position: "",
      salary: 0,
      cityInSyria: "",
      durationInTurkey: "",
      language: "",
      startWorkDate: moment(),
      endWorkDate: moment(),
      diplomaURL: "",
      IDURL: "",
      personalPhoto: "",
      passportPhotoURL: "",
      permitCardPhotoURL: "",
      trackingNumber: ""
    },
    validationSchema: projectSchema,
    onSubmit: async values => {
      try {
        await orderRef
          .doc(props.orderId)
          .update({
            IDURL: values.IDURL,
            personalPhoto: values.personalPhoto,
            passportPhotoURL: values.passportPhotoURL,
            permitCardPhotoURL: values.permitCardPhotoURL,
            diplomaURL: values.diplomaURL,
            requestedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            orderType: orderType,
            imgURL: "",
            TC: values.TC,
            firstTime: firstTime,
            permitExpiryDate: values.permitExpiryDate.toDate() as any,
            jobStartDate: values.jobStartDate.toDate() as any,
            firstName: values.firstName,
            lastName: values.lastName,
            fatherName: values.fatherName,
            motherName: values.motherName,
            gender: values.gender,
            birthPlace: values.birthPlace,
            birthDate: values.birthDate.toDate() as any,
            marriedStatus: values.marriedStatus,
            nationality: values.nationality,
            femaleChildrenNumber: values.femaleChildrenNumber,
            maleChildrenNumber: values.maleChildrenNumber,
            wifeNationality: values.wifeNationality,
            wifeFirstName: values.wifeFirstName,
            wifeLastName: values.wifeLastName,
            wifeTC: values.wifeTC,
            phone: values.phone,
            email: values.email,
            residenceAddress: values.residenceAddress,
            universityNameAddress: values.universityNameAddress,
            degree: values.degree,
            degreeField: values.degreeField,
            degreeFaculty: values.degreeFaculty,
            degreeSpecialization: values.degreeSpecialization,
            passportNO: values.passportNO,
            passportExpiryDate: values.passportExpiryDate.toDate() as any,
            passportAuth: values.passportAuth,
            position: values.position,
            salary: values.salary,
            cityInSyria: values.cityInSyria,
            durationInTurkey: values.durationInTurkey,
            language: values.language,
            trackingNumber: values.trackingNumber
          } as any)
          .then(async () => {
            const contractData = {
              firstName: values.firstName,
              lastName: values.lastName,
              fatherName: values.fatherName,
              nationality: values.nationality,
              cityInSyria: values.cityInSyria,
              birthPlace: values.birthPlace,
              birthDate: values.birthDate,
              TC: values.TC,
              passportNO: values.passportNO,
              residenceAddress: values.residenceAddress,
              salary: values.salary
            };
            await generateAndUploadWord(contract, contractData, props.orderId, uid);
          });
        await usersAdminSN?.docs.map((doc: any) => {
          const adminID = doc.id;
          notificationsRef.add({
            notifyTo: adminID,
            notifyFrom: auth().currentUser!.uid,
            orderId: props.orderId,
            read: false,
            kind: "newRequest",
            content: "New order has been modified!",
            postedAt: new Date()
          });
        });

        if (isCreate == true) {
          message.success("Your Changes has been submitted successfully");
          setModalState(false);
          setLoading(false);
          history.push(`/order/${props.orderId}`);
        } else {
          console.log("Document successfully written!");
          message.success("Your request has been changed successfully");
          history.push(`/`);
        }
      } catch (e) {
        console.log(e);
      }
    }
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (orderDocSN && loaded) {
      formik.setValues({
        IDURL: orderDocSN?.data()?.IDURL,
        personalPhoto: orderDocSN?.data()?.personalPhoto,
        passportPhotoURL: orderDocSN?.data()?.passportPhotoURL,
        permitCardPhotoURL: orderDocSN?.data()?.permitCardPhotoURL,
        diplomaURL: orderDocSN?.data()?.diplomaURL,
        TC: orderDocSN?.data()?.TC,
        permitExpiryDate: moment(orderDocSN?.data()?.permitExpiryDate.toDate()),
        jobStartDate: moment(orderDocSN?.data()?.jobStartDate.toDate()),
        firstName: orderDocSN?.data()?.firstName,
        lastName: orderDocSN?.data()?.lastName,
        fatherName: orderDocSN?.data()?.fatherName,
        motherName: orderDocSN?.data()?.motherName,
        gender: orderDocSN?.data()?.gender,
        birthPlace: orderDocSN?.data()?.birthPlace,
        birthDate: moment(orderDocSN?.data()?.birthDate.toDate()),
        marriedStatus: orderDocSN?.data()?.marriedStatus,
        nationality: orderDocSN?.data()?.nationality,
        femaleChildrenNumber: orderDocSN?.data()?.femaleChildrenNumber,
        maleChildrenNumber: orderDocSN?.data()?.maleChildrenNumber,
        wifeNationality: orderDocSN?.data()?.wifeNationality,
        wifeFirstName: orderDocSN?.data()?.wifeFirstName,
        wifeLastName: orderDocSN?.data()?.wifeLastName,
        wifeTC: orderDocSN?.data()?.wifeTC,
        phone: orderDocSN?.data()?.phone,
        email: orderDocSN?.data()?.email,
        residenceAddress: orderDocSN?.data()?.residenceAddress,
        universityNameAddress: orderDocSN?.data()?.universityNameAddress,
        degree: orderDocSN?.data()?.degree,
        degreeField: orderDocSN?.data()?.degreeField,
        degreeFaculty: orderDocSN?.data()?.degreeFaculty,
        degreeSpecialization: orderDocSN?.data()?.degreeSpecialization,
        passportNO: orderDocSN?.data()?.passportNO,
        passportExpiryDate: moment(orderDocSN?.data()?.passportExpiryDate.toDate()),
        passportAuth: orderDocSN?.data()?.passportAuth,
        position: orderDocSN?.data()?.position,
        salary: orderDocSN?.data()?.salary,
        cityInSyria: orderDocSN?.data()?.cityInSyria,
        durationInTurkey: orderDocSN?.data()?.durationInTurkey,
        language: orderDocSN?.data()?.language,
        trackingNumber: orderDocSN?.data()?.trackingNumber
      } as any);
      setLoaded(false);
      setFirstTime(orderDocSN?.data()?.firstTime);
      setOrderType(orderDocSN?.data()?.orderType);
    }
  }, [orderDocSN, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const orderStorageRef = firebase.storage().ref(uid);

  return (
    <Drawer title="Add New Request" width={1000} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.selectYourRC")} span={1}>
                <Select value={orderType} onChange={e => setOrderType(e)}>
                  <Option value={"AFAD Kart"}>AFAD Kart</Option>
                  <Option value={"Ikamet Kart"}>Ikamet Kart</Option>
                  <Option value={"Turk Vatandasi"}>Turk Vatandasi</Option>
                </Select>
              </Descriptions.Item>
            </Descriptions>

            {orderType != "" ? (
              <div>
                <Descriptions
                  size={"small"}
                  layout="horizontal"
                  bordered
                  column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label={t("general.firstTime")} span={2}>
                    <Select
                      value={firstTime}
                      defaultValue={firstTime}
                      onChange={e => {
                        setFirstTime(e);
                      }}
                    >
                      <Option value={"Yes"}>Yes</Option>
                      <Option value={"No"}>No</Option>
                    </Select>
                  </Descriptions.Item>
                  {orderDocSN?.data()?.status == "Approved" ? (
                    <Descriptions.Item label={t("general.trackingNumber")} span={2}>
                      <Input
                        value={formik.values.trackingNumber}
                        onChange={e => formik.setFieldValue("trackingNumber", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {firstTime == "No" ? (
                    <Descriptions.Item label={t("general.permitExpiryDate")} span={2}>
                      <DatePicker
                        value={formik.values.permitExpiryDate} // defaultValue={formik.values.firstTime}
                        format={dateFormat}
                        onChange={e => formik.setFieldValue("permitExpiryDate", e)}
                      />
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item label={t("general.workStartOn")} span={2}>
                      <DatePicker
                        value={formik.values.jobStartDate}
                        format={dateFormat}
                        onChange={e => formik.setFieldValue("jobStartDate", e)}
                      />
                    </Descriptions.Item>
                  )}
                  {/* //------------------- */}
                  <Descriptions.Item label={t("general.TC")} span={2}>
                    <Input value={formik.values.TC} onChange={e => formik.setFieldValue("TC", e.target.value)} />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.firstName")} span={2}>
                    <Input
                      value={formik.values.firstName}
                      onChange={e => formik.setFieldValue("firstName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.lastName")} span={2}>
                    <Input
                      value={formik.values.lastName}
                      onChange={e => formik.setFieldValue("lastName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.fatherName")} span={2}>
                    <Input
                      value={formik.values.fatherName}
                      onChange={e => formik.setFieldValue("fatherName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.motherName")} span={2}>
                    <Input
                      value={formik.values.motherName}
                      onChange={e => formik.setFieldValue("motherName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.gender")} span={2}>
                    <Select value={formik.values.gender} onChange={e => formik.setFieldValue("gender", e)}>
                      <Option value={"male"}>Male</Option>
                      <Option value={"female"}>Female</Option>
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.birthPlace")} span={2}>
                    <Input
                      value={formik.values.birthPlace}
                      onChange={e => formik.setFieldValue("birthPlace", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.birthDate")} span={2}>
                    <DatePicker
                      value={formik.values.birthDate}
                      format={dateFormat}
                      onChange={e => formik.setFieldValue("birthDate", e)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.marriedStatus")} span={2}>
                    <Select
                      value={formik.values.marriedStatus}
                      onChange={e => formik.setFieldValue("marriedStatus", e)}
                    >
                      <Option value={"Evli"}>Married</Option>
                      <Option value={"Bekar"}>Single</Option>
                    </Select>
                  </Descriptions.Item>
                  {orderType != "Turk Vatandasi" ? (
                    <Descriptions.Item label={t("general.nationality")} span={2}>
                      <Input
                        value={formik.values.nationality}
                        onChange={e => formik.setFieldValue("nationality", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {/* //------------------- */}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.femaleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.femaleChildrenNumber}
                        onChange={e => formik.setFieldValue("femaleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.maleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.maleChildrenNumber}
                        onChange={e => formik.setFieldValue("maleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
         
                  <Descriptions.Item label={t("general.wifeNationality")} span={2}>
                    <Input
                      value={formik.values.wifeNationality}
                      onChange={e => formik.setFieldValue("wifeNationality", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeFirstName")} span={2}>
                    <Input
                      value={formik.values.wifeFirstName}
                      onChange={e => formik.setFieldValue("wifeFirstName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeLastName")} span={2}>
                    <Input
                      value={formik.values.wifeLastName}
                      onChange={e => formik.setFieldValue("wifeLastName", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.wifeTC")} span={2}>
                    <Input
                      value={formik.values.wifeTC}
                      onChange={e => formik.setFieldValue("wifeTC", e.target.value)}
                    />
                  </Descriptions.Item>
                  {/* //------------------- */}
                  <Descriptions.Item label={t("general.phone")} span={2}>
                    <Input value={formik.values.phone} onChange={e => formik.setFieldValue("phone", e.target.value)} />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.email")} span={2}>
                    <Input value={formik.values.email} onChange={e => formik.setFieldValue("email", e.target.value)} />
                  </Descriptions.Item>
         
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.universityNameAddress")} span={2}>
                      <Input
                        value={formik.values.universityNameAddress}
                        onChange={e => formik.setFieldValue("universityNameAddress", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}{" "}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degree")} span={2}>
                      <Input
                        value={formik.values.degree}
                        onChange={e => formik.setFieldValue("degree", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeField")} span={2}>
                      <Input
                        value={formik.values.degreeField}
                        onChange={e => formik.setFieldValue("degreeField", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType != "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeSpecialization")} span={2}>
                      <Input
                        value={formik.values.degreeSpecialization}
                        onChange={e => formik.setFieldValue("degreeSpecialization", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {/* //------------------- */}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportNO")} span={2}>
                      <Input
                        value={formik.values.passportNO}
                        onChange={e => formik.setFieldValue("passportNO", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportExpiryDate")} span={2}>
                      <DatePicker
                        value={formik.values.passportExpiryDate}
                        onChange={e => formik.setFieldValue("passportExpiryDate", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.passportAuth")} span={2}>
                      <Input
                        value={formik.values.passportAuth}
                        onChange={e => formik.setFieldValue("passportAuth", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item label={t("general.position")} span={2}>
                    <Input
                      value={formik.values.position}
                      onChange={e => formik.setFieldValue("position", e.target.value)}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("general.salary")} span={2}>
                    <InputNumber value={formik.values.salary} onChange={e => formik.setFieldValue("salary", e)} />
                  </Descriptions.Item>
                  {orderType == "AFAD Kart" || orderType == "Ikamet Kart" ? (
                    <Descriptions.Item label={t("general.cityInSyria")} span={2}>
                      <Input
                        value={formik.values.cityInSyria}
                        onChange={e => formik.setFieldValue("cityInSyria", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degree")} span={2}>
                      <Input
                        value={formik.values.degree}
                        onChange={e => formik.setFieldValue("degree", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.universityNameAddress")} span={2}>
                      <Input
                        value={formik.values.universityNameAddress}
                        onChange={e => formik.setFieldValue("universityNameAddress", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeFaculty")} span={2}>
                      <Input
                        value={formik.values.degreeFaculty}
                        onChange={e => formik.setFieldValue("degreeFaculty", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.degreeField")} span={2}>
                      <Input
                        value={formik.values.degreeField}
                        onChange={e => formik.setFieldValue("degreeField", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.durationInTurkey")} span={2}>
                      <Input
                        value={formik.values.durationInTurkey}
                        onChange={e => formik.setFieldValue("durationInTurkey", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.femaleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.femaleChildrenNumber}
                        onChange={e => formik.setFieldValue("femaleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.maleChildrenNumber")} span={2}>
                      <InputNumber
                        value={formik.values.maleChildrenNumber}
                        onChange={e => formik.setFieldValue("maleChildrenNumber", e)}
                      />
                    </Descriptions.Item>
                  ) : null}
                  {orderType == "AFAD Kart" ? (
                    <Descriptions.Item label={t("general.language")} span={2}>
                      <Input
                        value={formik.values.language}
                        onChange={e => formik.setFieldValue("language", e.target.value)}
                      />
                    </Descriptions.Item>
                  ) : null}
                </Descriptions>
                <Descriptions
                  size={"small"}
                  layout="horizontal"
                  bordered
                  column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label={t("general.address")} span={4}>
                    <Input
                      value={formik.values.residenceAddress}
                      onChange={e => formik.setFieldValue("residenceAddress", e.target.value)}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}
            <span>{t("general.attachments")}:</span>
            <Descriptions
              size={"small"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("attachments.permitCardPhoto")} span={3}>
            
                  <FileUploader
                  
                    storageRef={orderStorageRef}
                    onUploadStart={() => setUploadingPermit(true)}
                    onUploadSuccess={async (fileName: string) => {
                      const downloadURL = await orderStorageRef.child(fileName).getDownloadURL();
                      formik.setFieldValue("permitCardPhotoURL", downloadURL);

                      setPermitCardURL(downloadURL);
                      setUploadingPermit(false);
                      message.success("Card Uploaded successfully");
                    }}
                  />
           
                  {uploadingPermit ? <Spin indicator={antIcon} /> : null}

                  {permitCardURL != "" || orderDocSN?.data()?.permitCardPhotoURL != "" ? (
                    <Icon type="check" style={{ color: "green", padding: "2%" }} />
                  ) : null}
                </Descriptions.Item>
              ) : null}
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("attachments.passportPhoto")} span={3}>
               
                  <FileUploader
                 
                    storageRef={orderStorageRef}
                    onUploadStart={() => setUploadingPassport(true)}
                    onUploadSuccess={async (fileName: string) => {
                      const downloadURL = await orderStorageRef.child(fileName).getDownloadURL();
                      formik.setFieldValue("passportPhotoURL", downloadURL);

                      setPassportPhoto(downloadURL);
                      setUploadingPassport(false);

                      message.success("Passport Uploaded successfully");
                    }}
                  />
                 
                  {uploadingPassport ? <Spin indicator={antIcon} /> : null}

                  {passportPhoto != "" || orderDocSN?.data()?.passportPhotoURL != "" ? (
                    <Icon type="check" style={{ color: "green", padding: "2%" }} />
                  ) : null}
                </Descriptions.Item>
              ) : null}
              {orderType == "Ikamet Kart" ? (
                <Descriptions.Item label={t("attachments.diploma")} span={3}>
             
                  <FileUploader
                   
                    storageRef={orderStorageRef}
                    onUploadStart={() => setUploadingDiploma(true)}
                    onUploadSuccess={async (fileName: string) => {
                      const downloadURL = await orderStorageRef.child(fileName).getDownloadURL();
                      formik.setFieldValue("diplomaURL", downloadURL);
                      setDiplomaURL(downloadURL);
                      setUploadingDiploma(false);
                      message.success("Diploma Uploaded successfully");
                    }}
                  />
                  {/* </label> */}
                  {uploadingDiploma ? <Spin indicator={antIcon} /> : null}

                  {diplomaURL != "" || orderDocSN?.data()?.diplomaURL != "" ? (
                    <Icon type="check" style={{ color: "green", padding: "2%" }} />
                  ) : null}
                </Descriptions.Item>
              ) : null}
              {orderType != "Ikamet Kart" ? (
                <Descriptions.Item label={t("attachments.ID")} span={3}>
              
                  <FileUploader
                    
                    storageRef={orderStorageRef}
                    onUploadStart={() => setUploadingID(true)}
                    onUploadSuccess={async (fileName: string) => {
                      const downloadURL = await orderStorageRef.child(fileName).getDownloadURL();
                      formik.setFieldValue("IDURL", downloadURL);
                      setIDURL(downloadURL);
                      setUploadingID(false);
                      message.success("Identify Card Uploaded successfully");
                    }}
                  />
                  {/* </label> */}
                  {uploadingID ? <Spin indicator={antIcon} /> : null}

                  {orderDocSN?.data()?.IDURL != "" || IDURL != "" ? (
                    <Icon type="check" style={{ color: "green", padding: "2%" }} />
                  ) : null}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label={t("attachments.personalPhoto")} span={3}>
              
                <FileUploader
                 
                  storageRef={orderStorageRef}
                  onUploadStart={() => setUploadingPersonalPhoto(true)}
                  onUploadSuccess={async (fileName: string) => {
                    const downloadURL = await orderStorageRef.child(fileName).getDownloadURL();
                    formik.setFieldValue("personalPhoto", downloadURL);
                    setPersonalPhoto(downloadURL);
                    setUploadingPersonalPhoto(false);
                    message.success("Photo Uploaded successfully");
                  }}
                />
                {/* </label> */}
                {uploadingPersonalPhoto ? <Spin indicator={antIcon} /> : null}

                {orderDocSN?.data()?.personalPhoto != "" || personalPhoto != "" ? (
                  <Icon type="check" style={{ color: "green", padding: "2%" }} />
                ) : null}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditOrder;
