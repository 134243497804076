import { Card, Row, Col, message } from "antd";
import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { auth } from "firebase";
import { useHistory } from "react-router-dom";
import AddNewOrder from "../models/AddNewOrder";
import { currentUserData } from "../../db/userGlobal";
import { globalOfficesData } from "../../global/officeList";
const { Meta } = Card;

export default function Services() {
  const history = useHistory();
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const arrOfCon = globalOfficesData[currentUserData.office].restrictedFeatures;
  return (
    <div style={{ alignContent: "center", padding: "0.6%" }}>
      <AddNewOrder modalState={modalState} setModalState={setModalState} />

      <Row gutter={20} style={{ alignItems: "center", padding: "0.5%" }}>
        {arrOfCon.includes("workPermit") ? null : (
          <Col
            span={5}
            style={{
              width: "auto",
              paddingLeft: "0.6%",
              paddingRight: "0.6%",
              paddingTop: "0.6%",
              paddingBottom: "0.6%"
            }}
          >
            <Card
              onClick={() => setModalState(true)}
              hoverable
              style={{
                width: 225,
                boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px"
              }}
              cover={
                <img
                  height="150"
                  alt="example"
                  src="https://www.excelimmigrations.com/wp-content/uploads/2022/06/work-permit.jpg"
                />
              }
            >
              <Meta title={t("dashboard.workPermitWindow")} />
            </Card>
          </Col>
        )}
        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%"
          }}
        >
          <Card
            onClick={() => history.push("/meeting-calendar")}
            hoverable
            style={{
              width: 225,
              boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px"
            }}
            cover={
              <img
                height="150"
                alt="example"
                src="https://relishstudio.com/wp-content/uploads/2015/10/calendar.jpg"
              />
            }
          >
            <Meta title={t("dashboard.meetingCalendar")} />
          </Card>
        </Col>
        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%"
          }}
        >
          <Card
            onClick={() => history.push("/meeting-table")}
            hoverable
            style={{
              width: 225,
              boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px"
            }}
            cover={
              <img
                height="150"
                alt="example"
                src="https://miro.medium.com/max/1400/1*TdbG99OKie8QseB6eL8GcQ.png"
              />
            }
          >
            <Meta title={t("dashboard.myMeetings")} />
          </Card>
        </Col>
        {arrOfCon.includes("vacations") ? null : (
          <Col
            span={5}
            style={{
              width: "auto",
              paddingLeft: "0.6%",
              paddingRight: "0.6%",
              paddingTop: "0.6%",
              paddingBottom: "0.6%"
            }}
          >
            <Card
              onClick={() => history.push("/vacation")}
              hoverable
              style={{
                width: 225,
                boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px"
              }}
              cover={
                <img
                  height="150"
                  alt="example"
                  src="https://static4.depositphotos.com/1013501/356/i/950/depositphotos_3563968-stock-photo-vacation-request.jpg"
                />
              }
            >
              <Meta title={t("dashboard.vacationRequest")} />
            </Card>
          </Col>
        )}
        {arrOfCon.includes("overtime") ? null : (
          <Col
            span={5}
            style={{
              width: "auto",
              paddingLeft: "0.6%",
              paddingRight: "0.6%",
              paddingTop: "0.6%",
              paddingBottom: "0.6%"
            }}
          >
            <Card
              onClick={() => history.push("/overtime")}
              hoverable
              style={{
                width: 225,
                boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px"
              }}
              cover={
                <img
                  height="150"
                  alt="example"
                  src="https://firebasestorage.googleapis.com/v0/b/administrationapp-cdc55.appspot.com/o/Screen%20Shot%202020-09-08%20at%2015.37.49.png?alt=media&token=4e2a8c17-9a2d-4893-bd5b-dc50cba5a8c1"
                />
              }
            >
              <Meta title={t("dashboard.overTimeRequest")} />
            </Card>
          </Col>
        )}
        <Col
          span={5}
          style={{
            width: "auto",
            paddingLeft: "0.6%",
            paddingRight: "0.6%",
            paddingTop: "0.6%",
            paddingBottom: "0.6%"
          }}
        >
          <Card
            onClick={() => history.push("/time-sheet")}
            hoverable
            style={{
              width: 225,
              boxShadow: "rgba(0, 0, 0, 0.09) 1px -1px 17px 3px"
            }}
            cover={
              <img
                height="150"
                alt="example"
                src="https://firebasestorage.googleapis.com/v0/b/administrationapp-cdc55.appspot.com/o/Screen%20Shot%202020-09-24%20at%2016.27.59.png?alt=media&token=baeaf2f2-4211-431f-a170-4e8087c45810"
              />
            }
          >
            <Meta title={t("dashboard.timeSheet")} />
          </Card>
        </Col>
      </Row>
      <Row gutter={20} style={{ alignItems: "center", padding: "0.5%" }}></Row>
    </div>
  );
}
