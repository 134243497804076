import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase, { auth } from "firebase/app";
import { Spin, Button, DatePicker, Select } from "antd";
const { Option } = Select;
import { userRef, overtimeRef, officeRef, vacationRef } from "../../db/collectionsRef";
import DelayModel from "../models/delayModel";
import userRecord from "./userRecord";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { users } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
import { uniq } from "lodash";
import DevTable from "../../helpers/devex-table";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function UsersAbsent() {
  const [now, setNow] = useState(
    moment()
      .startOf("day")
      .toDate()
  );
  const yesterday = moment(now)
    .subtract(1, "days")
    .endOf("day")
    .toDate();
  const tomorrow = moment(now)
    .add(1, "days")
    .startOf("day")
    .toDate();
  const history = useHistory();
  const uid = auth().currentUser!.uid;
  const [UID, setUID] = useState("");
  const [modalState, setModalState] = useState(false);
  const [defaultHiddenColumnNames] = useState(["managerUID", "gender", "department"]);
  const [officeId, setOfficeId] = useState(currentUserData.office);
  const [value, loading, error] = useCollection(usersRef.where("office", "==", officeId));
  const [userSNALL] = useCollection(usersRef.where("office", "==", officeId));
  const [officeSN] = useCollection(officeRef);
  const [delaySN] = useCollection(db.collectionGroup("delay"));
  const [vacationSN] = useCollection(vacationRef.where("office", "==", officeId).where("vacationEndOn", ">=", now));

  const [accessSN] = useCollection(
    db
      .collectionGroup("access")
      .where("officeId", "==", officeId)
      .where("operationDate", ">=", yesterday)
      .where("operationDate", "<", tomorrow)
  );

  if (
    value == undefined ||
    loading ||
    !delaySN ||
    !officeSN ||
    !currentUserData.fullControlOffices ||
    !vacationSN ||
    !accessSN ||
    !userSNALL
  )
    return <Spin />;

  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map(id => {
    const officeName = officeSN?.docs?.find(d => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });

  const data = value.docs
    .filter(
      d => currentUserData.fullControlOffices.includes(d.data()?.office) || d.data()?.managerUID == uid || d.id == uid
    )
    .map((d, index) => {
      return {
        ...d.data(),
        id: d.id,
        index: index + 1
      };
    });

  const vacations = vacationSN?.docs.map(v => {
    return { ...v.data(), id: v.id };
  });

  const vacationsFiltered = vacations.filter((vacation: any) => {
    const vacationStartOn = moment(vacation.vacationStartOn.toDate()).startOf("day");
    const vacationEndOn = moment(vacation.vacationEndOn.toDate()).endOf("day");

    return moment(now).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
  });

  const users = userSNALL?.docs
    ?.map((user, index) => {
      return { ...user.data(), uid: user.id };
    })
    .filter((u: any) => u.status != "disable");
  const usersNotOnVacation = users.filter(
    user => vacationsFiltered.find((v: any) => v.employeeUID === user.uid) == undefined
  );

  const access = accessSN.docs?.map(a => {
    return { ...a.data(), id: a.id };
  });
  const usersAbsent = usersNotOnVacation
    .filter((unv: any) => access.find((a: any) => a.uid == unv.uid) == undefined)
    .map((ub, index) => {
      return { ...ub, index: index + 1 };
    });

  const columnsExport = [
    { name: "index", title: "NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "department", title: "Department" },
    { name: "gender", title: "Gender" },
    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) =>
        `${userSNALL?.docs?.find(d => d.id == row.managerUID)?.data()?.firstName} ${
          userSNALL?.docs?.find(d => d.id == row.managerUID)?.data()?.lastName
        }`
    },
    { name: "position", title: "Position" }
  ];
  const columns = [
    { name: "index", title: "NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "department", title: "Department" },
    { name: "gender", title: "Gender" },
    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) =>
        `${userSNALL?.docs?.find(d => d.id == row.managerUID)?.data()?.firstName} ${
          userSNALL?.docs?.find(d => d.id == row.managerUID)?.data()?.lastName
        }`
    },
    { name: "position", title: "Position" },

    {
      name: "fingerPrint",
      title: "Finger Print Records",
      getCellValue: (r: any) => {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => {
                history.push(`/fingerPrint/${r.uid}`);
              }}
            >
              Finger Print Records
            </Button>
          </div>
        );
      }
    }
  ];
  return (
    <div>
      {modalState ? <DelayModel modalState={modalState} setModalState={setModalState} UID={UID} /> : null}
      <Paper>
        <DatePicker
          size="large"
          style={{ margin: "1%" }}
          placeholder="Select month"
          value={moment(now)}
          format={"DD-MM-YYYY"}
          onChange={e =>
            setNow(
              moment(e)
                .startOf("day")
                .toDate()
            )
          }
        />

        <Select
          size="default"
          placeholder="Search for Office!"
          style={{ width: "25%", padding: "1%" }}
          showSearch
          value={officeId}
          onChange={e => {
            setOfficeId(e);
          }}
        >
          {UserListOffices.map(d => {
            return <Option value={d.id}>{d.officeName}</Option>;
          })}
        </Select>

        <DevTable
          data={usersAbsent}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="Absents"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
