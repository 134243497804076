import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, Popconfirm } from "antd";

import { orderRef, userRef, meetingRoomList, projectsRef, officeRef } from "../../db/collectionsRef";
import { Link } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import AddNewRoomModel from "../Meetings_Rooms/AddNewRoomModel";
import { useTranslation } from "react-i18next";
// import { admin } from "../../db/admin";
import moment from "moment";
import ManagePermissions from "./ManagePermissionsModel";
import AddManagePermissions from "./AddManagePermissionsModel";
import { globalOfficesData } from "../../global/officeList";
import { globalMissionsData } from "../../global/missionList";
import DevTable from "../../helpers/devex-table";

const columnsExport = [
  { name: "id", title: "Id" },

  { name: "officeName", title: "Office Name" },
  { name: "location", title: "Location" }
];

export default function OfficesTable() {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [office, setOffice] = useState({});
  const [modalState, setModalState] = useState(false);
  const [modalStatePermissions, setModalStatePermissions] = useState(false);


  const [officesSN ] = useCollection(officeRef);

  if (!officesSN) return <Spin />;

  const data = officesSN.docs.map(d => {
    return { ...d.data(), id: d.id };
  });

  const columns = [
    { name: "officeName", title: "Office Name" },
    { name: "location", title: "Location" },
    { name: "missionId", title: "Mission Name", getCellValue: r => globalMissionsData[r.missionId].missionName },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              officeRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      }
    },
    {
      name: "permissions",
      title: "Permissions",
      getCellValue: (row: any) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setOffice(row);
              setModalStatePermissions(true);
            }}
          >
            Manage Permissions
          </Button>
        );
      }
    }
  ];

  return (
    <div>
      {modalStatePermissions ? (
        <ManagePermissions
          modalState={modalStatePermissions}
          setModalState={setModalStatePermissions}
          office={office}
        />
      ) : null}
      <AddManagePermissions modalState={modalState} setModalState={setModalState} />
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewOffice")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Offices"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
