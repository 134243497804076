import React, { useCallback, useRef, useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select, Icon } from "antd";
const { MonthPicker } = DatePicker;
import { useCollection } from "react-firebase-hooks/firestore";
import {
  userRef,
  vacationRef,
  projectRef,
  budgetLinesRef,
  allocationRef,
  officeRef,
  positionRef,
} from "../../db/collectionsRef";

import { users, vacations } from "../../db/interfaces";
import printJS from "print-js";
import _, { uniq } from "lodash";
import firebase, { auth } from "firebase/app";
const { Option } = Select;
import { currentUserData } from "../../db/userGlobal";

import TimeSheetComponent from "./timeSheetComponent";
export default function TimeSheetByOffice() {
  const [officeId, setOfficeId] = useState("");
  const [month, setMonth] = useState(moment().subtract(1, "months"));
  const [uid, setUid] = useState("");
  const [vacationSN] = useCollection(vacationRef.where("office", "==", officeId));
  const [projectSN] = useCollection(projectRef);
  const [budgetLinesSN] = useCollection(budgetLinesRef);
  const [allocationSN] = useCollection(allocationRef.where("office", "==", officeId));
  const [usersSN] = useCollection(userRef.where("office", "==", officeId));
  const [officeSN] = useCollection(officeRef);
  const [delaySN] = useCollection(firebase.firestore().collectionGroup("delay"));
  const print1CbRef = useRef<() => void>();
  const print = useCallback(() => {
    setTimeout(() => {
      print1CbRef.current();
    }, 1500);
  }, []);

  const printCb = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .pagebreak {
          page-break-before:always;
        }
        .textField {
          border: 1px solid black;
          border-collapse: collapse;
          width: 25%;
          text-align: center;
        }
        .row {
          border: 1px solid black;
            border-collapse: collapse;

        }
        .titleText {
          border: 1px solid black;
          border-collapse: collapse;
          width: 25%;
          background: #a2a2a2;
          text-align: center;
        }
        .inputText {
          border: 1px solid black;
          border-collapse: collapse;
          width: 25%;
          text-align: center;
        }
        .logos {
          border: 1px solid black;
          border-collapse: collapse;
          width: 25%;
        }
        .projectInput {
          border: 1px solid black;
          border-collapse: collapse;
          width: 5%;
          text-align: center;
        }
        
        .cell {
          border: 1px solid black;
          border-collapse: collapse;
          width: 5%;
        }
        .cellEmpty {
          border: 1px  rgb(255, 255, 255);
          width: 5%;
        }
        

       </style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`timeSheet`);
  }, []);
  print1CbRef.current = printCb;

  if (!vacationSN || !projectSN || !budgetLinesSN || !allocationSN || !usersSN || !delaySN || !officeSN)
    return <Spin />;
  const vacationsMap = vacationSN.docs.map((vacation) => {
    return { ...vacation.data(), id: vacation.id };
  });

  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  const USERS_ID_FULL_NAME = usersSN.docs
    .filter((e) => currentUserData.fullControlOffices.includes(e.data()?.office))
    .filter((user) => user.data().status != "disable")
    .map((u) => {
      const fullName = u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName;
      const uid = u.id;
      return { uid, fullName };
    });
  const usersData = usersSN.docs.map((user) => {
    return { uid: user.id, userData: user.data() };
  });

  // const print = async () => {
  //   await printJS({
  //     header: "Time-Sheet",
  //     printable: "timeSheet",
  //     type: "html",
  //     targetStyles: ["*"],
  //     scanStyles: true,
  //     font_size: "15",
  //     documentTitle: `aiu.ida-org.com/print-timeSheet/${uid}/true/${moment(month).format("MM-YYYY")}`,
  //     showModal: true,
  //   });
  // };
  return (
    <div>
      <MonthPicker
        allowClear={false}
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      <Select
        showSearch
        placeholder="User"
        style={{ width: "20%", paddingBottom: "1%" }}
        value={[...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }].find((d) => d.uid == uid)?.fullName}
        onChange={(e: string) => {
          const id = [...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }].find((d) => d.fullName == e)
            ?.uid as string;
          setUid(id);
        }}
      >
        {[...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }]?.map((d) => {
          return <Option value={d.fullName}>{d.fullName}</Option>;
        })}
      </Select>
      <Select
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={UserListOffices.find((office) => office.id == officeId)?.officeName}
        onChange={(e) => {
          const id = UserListOffices.find((office) => office.officeName == e)?.id as string;
          setOfficeId(id);
        }}
      >
        {UserListOffices.map((d) => {
          return <Option value={d.officeName}>{d.officeName}</Option>;
        })}
      </Select>

      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        Print
      </Button>

      <div id="timeSheet">
        {usersData
          .filter((user) => user.userData.status != "disable")
          .filter((user) => (uid == "" || uid == "all" ? true : user.uid == uid))
          .map((user) => (
            <div>
              <TimeSheetComponent
                month={month}
                uid={user.uid}
                userData={user.userData as users}
                vacationsMap={vacationsMap as vacations[]}
                projectSN={projectSN}
                budgetLinesSN={budgetLinesSN}
                allocationSN={allocationSN}
                usersSN={usersSN}
                officeSN={officeSN}
                delaySN={delaySN}
              />
              <div className="pagebreak" style={{ pageBreakBefore: "always" }} />
            </div>
          ))}
        <br />
      </div>
    </div>
  );
}
