import React, { useState, useEffect, MutableRefObject, useCallback } from "react";

import moment from "moment";
import { format } from "../../db/dateFormat";
import { allocation, users, vacations } from "../../db/interfaces";
import "./paySlipToPrint.css";
import line from "./line.png";
import Picture1 from "./Picture1.png";
import Picture2 from "./Picture2.png";
import { sumBy } from "lodash";
import logo from "./Picture1.png";
import { globalOfficesData } from "../../global/officeList";
import { globalProjectsData } from "../../global/projectList";
import { globalBudgetLinesData } from "../../global/budgetLineList";
import { currentUserData } from "../../db/userGlobal";
import { globalUsersData } from "../../global/usersList";

interface Props {
  printFnRef: MutableRefObject<() => void>;
  row: users & allocation & any;
  month: any;
  days: any[];
  paymentOffice: string;
  index: number;
  exchangeRate: number;
  paymentDate: any;
  accountantId:string
}

export default function PaySlipToPrint(props: Props) {
  const { month, days, row, paymentOffice, index, exchangeRate, paymentDate, accountantId } = props;

  return (
    <div id="printingPayslip">
      <div id="view" className="container">
        <div className="bg" />

        <table className="tg" style={{ tableLayout: "fixed", width: "645px" }}>
          <colgroup>
            <col style={{ width: "21px" }} />
            <col style={{ width: "193px" }} />
            <col style={{ width: "80px" }} />
            <col style={{ width: "93px" }} />
            <col style={{ width: "66px" }} />
            <col style={{ width: "199px" }} />
            <col style={{ width: "79px" }} />
            <col style={{ width: "63px" }} />
            <col style={{ width: "43px" }} />
            <col style={{ width: "89px" }} />
            <col style={{ width: "21px" }} />
          </colgroup>
          <thead>
            <tr>
              <th className="tg-zv4m"></th>
              <th className="tg-fmc2" colSpan={3} rowSpan={2}>
                <img style={{ width: "175px", marginBottom: "3%", marginTop: "3%", marginLeft: "5%" }} src={logo} />
              </th>
              <th className="tg-lh7k"></th>
              <th className="tg-lh7k"></th>
              <th className="tg-lh7k"></th>
              <th className="tg-lh7k"></th>
              <th className="tg-lh7k"></th>
              <th className="tg-lh7k"></th>
              <th className="tg-lh7k"></th>
            </tr>
            <tr>
              <th className="tg-z4i2"></th>
              <th className="tg-z4i2"></th>
              <th className="tg-z4i2"></th>
              <th className="tg-z4i2"></th>
              <th className="tg-z4i2"></th>
              <th className="tg-z4i2"></th>
              <th className="tg-z4i2"></th>
              <th className="tg-z4i2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-z4i2"></td>
              <td className="tg-sl9l" colSpan={10} rowSpan={2}>
                IDA Pay Slip
              </td>
            </tr>
            <tr>
              <td className="tg-z4i2"></td>
            </tr>
            <tr>
              <td className="tg-2urc" colSpan={11}>
                {" "}
              </td>
            </tr>
            <tr>
              <td className="tg-ui32"> </td>
              <td className="tg-1zvd">No:</td>
              <td className="tg-1zvd" colSpan={3}>
                {`${month.format("MMMM")}/${paymentOffice}/${index}`}
              </td>
              <td className="tg-1zvd">Project Code</td>
              <td className="tg-1zvd" colSpan={4}>
                {row?.allocations.map((a) => globalProjectsData[a.projectId].projectCode).join(" | ")}
              </td>
              <td className="tg-ui32"> </td>
            </tr>
            <tr>
              <td className="tg-ui32" colSpan={11}>
                {" "}
              </td>
            </tr>
            <tr>
              <td className="tg-ui32"> </td>
              <td className="tg-1zvd">Name</td>
              <td className="tg-1zvd" colSpan={3}>
                {row?.firstName + " " + row?.lastName}
              </td>
              <td className="tg-1zvd">Budget Line</td>
              <td className="tg-1zvd" colSpan={4}>
                {row?.allocations.map((a) => globalBudgetLinesData[a.budgetLineId].budgetLine).join(" | ")}
              </td>
              <td className="tg-ui32"> </td>
            </tr>
            <tr>
              <td className="tg-ui32" colSpan={11}>
                {" "}
              </td>
            </tr>
            <tr>
              <td className="tg-f3so"> </td>
              <td className="tg-1zvd">Position</td>
              <td className="tg-1zvd" colSpan={3}>
                {row?.position}
              </td>
              <td className="tg-1zvd">Place of work</td>
              <td className="tg-1zvd" colSpan={4}>
                {globalOfficesData[row?.office].officeName}
              </td>
              <td className="tg-f3so"> </td>
            </tr>
            <tr>
              <td className="tg-f3so" colSpan={11}>
                {" "}
              </td>
            </tr>
            <tr>
              <td className="tg-f3so"> </td>
              <td className="tg-1zvd">Month:</td>
              <td className="tg-1zvd" colSpan={3}>
                {month.format("MMMM.YYYY")}
              </td>
              <td className="tg-1zvd">Cash/Transfer:</td>
              <td className="tg-1zvd" colSpan={4}>
                {row?.paymentType ?? ""}
              </td>
              <td className="tg-f3so"> </td>
            </tr>
            <tr>
              <td className="tg-f3so" colSpan={11}>
                {" "}
              </td>
            </tr>
            <tr>
              <td className="tg-f3so" rowSpan={21}>
                {" "}
                <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
                <br /> <br /> <br /> <br /> <br />{" "}
              </td>
              <td className="tg-9o1m" rowSpan={2}>
                Period
              </td>
              <td className="tg-27760">From:</td>
              <td className="tg-u9r80" colSpan={2}>
                {month.startOf("month").format("DD-MMM-YY")}
              </td>
              <td className="tg-277601" colSpan={2}>
                Total work Percentage:
              </td>
              <td className="tg-27760" colSpan={3}>
                {row?.idaAllocationPercentage + " %"}
              </td>
              <td className="tg-f3so" rowSpan={21}>
                {" "}
                <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
                <br /> <br /> <br /> <br /> <br />{" "}
              </td>
            </tr>
            <tr>
              <td className="tg-2776">To:</td>
              <td className="tg-u9r81" colSpan={2}>
                {month.endOf("month").format("DD-MMM-YY")}
              </td>
              <td className="tg-2776">Project</td>
              <td className="tg-9o1m">work %</td>
              <td className="tg-9o1m" colSpan={2}>
                Payment %
              </td>
              <td className="tg-9o1m">Amount</td>
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                Work days
              </td>
              <td className="tg-u9r81" colSpan={2}>
                {days.length}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[0] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[0] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[0] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[0] as allocation)?.percentage &&
                  (row?.allocations[0] as allocation)?.percentage + "%"}
              </td>
              <td className="tg-2776">
                {(row?.allocations[0] as allocation)?.percentage &&
                  (
                    ((row?.allocations[0] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>
            </tr>
            <tr>
              <td className="tg-2776">Basic salary</td>
              <td className="tg-2776">USD</td>
              <td className="tg-u9r81" colSpan={2}>
                {row?.contractSalary}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[1] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[1] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[1] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[1] as allocation)?.percentage &&
                  (row?.allocations[1] as allocation)?.percentage + "%"}{" "}
              </td>
              <td className="tg-2776">
                {(row?.allocations[1] as allocation)?.percentage &&
                  (
                    ((row?.allocations[1] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>{" "}
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                Salary USD according&nbsp;&nbsp;&nbsp;to Total work Percentage:
              </td>
              <td className="tg-u9r81" colSpan={2}>
                {row?.contractSalary}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[2] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[2] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[2] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[2] as allocation)?.percentage &&
                  (row?.allocations[2] as allocation)?.percentage + "%"}{" "}
              </td>
              <td className="tg-2776">
                {(row?.allocations[2] as allocation)?.percentage &&
                  (
                    ((row?.allocations[2] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>{" "}
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                Absent Days
              </td>
              <td className="tg-u9r81" colSpan={2}>
                {row?.absentDays}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[3] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[3] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[3] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[3] as allocation)?.percentage &&
                  (row?.allocations[3] as allocation)?.percentage + "%"}{" "}
              </td>
              <td className="tg-2776">
                {(row?.allocations[3] as allocation)?.percentage &&
                  (
                    ((row?.allocations[3] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>{" "}
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                Unpaid Leave
              </td>
              <td className="tg-u9r81" colSpan={2}>
                {row?.leaveWithoutPay}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[4] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[4] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[4] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[4] as allocation)?.percentage &&
                  (row?.allocations[4] as allocation)?.percentage + "%"}{" "}
              </td>
              <td className="tg-2776">
                {(row?.allocations[4] as allocation)?.percentage &&
                  (
                    ((row?.allocations[4] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>{" "}
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                Hours Late
              </td>
              <td className="tg-u9r81" colSpan={2}>
                {row?.numberOfHoursLate}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[5] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[5] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[5] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[5] as allocation)?.percentage &&
                  (row?.allocations[5] as allocation)?.percentage + "%"}{" "}
              </td>
              <td className="tg-2776">
                {(row?.allocations[5] as allocation)?.percentage &&
                  (
                    ((row?.allocations[5] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>{" "}
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                Deduction
              </td>
              <td className="tg-u9r81" colSpan={2}>
                {row?.deduction}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[6] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[6] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[6] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[6] as allocation)?.percentage &&
                  (row?.allocations[6] as allocation)?.percentage + "%"}{" "}
              </td>
              <td className="tg-2776">
                {(row?.allocations[6] as allocation)?.percentage &&
                  (
                    ((row?.allocations[6] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>{" "}
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                Total Deduction:
              </td>
              <td className="tg-u9r81" colSpan={2}>
                {row?.difference}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[7] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[7] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[7] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[7] as allocation)?.percentage &&
                  (row?.allocations[7] as allocation)?.percentage + "%"}{" "}
              </td>
              <td className="tg-2776">
                {(row?.allocations[7] as allocation)?.percentage &&
                  (
                    ((row?.allocations[7] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>
            </tr>
            <tr>
              <td className="tg-1zvd0" colSpan={2} rowSpan={2}>
                Total Net salary
              </td>
              <td className="tg-u9r83" colSpan={2} rowSpan={2}>
                {row?.totalSalaryForCurrentMonth}
              </td>
              <td className="tg-u9r8">
                {globalProjectsData[(row?.allocations[8] as allocation)?.projectId ?? ""]?.projectCode ?? ""}
              </td>
              <td className="tg-u9r8">
                {(((row?.allocations[8] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 == 0
                  ? ""
                  : (((row?.allocations[8] as allocation)?.percentage ?? 0) * row?.idaAllocationPercentage) / 100 +
                    " %"}
              </td>
              <td className="tg-u9r8" colSpan={2}>
                {(row?.allocations[8] as allocation)?.percentage &&
                  (row?.allocations[8] as allocation)?.percentage + "%"}{" "}
              </td>
              <td className="tg-2776">
                {(row?.allocations[8] as allocation)?.percentage &&
                  (
                    ((row?.allocations[8] as allocation)?.percentage *
                      (row?.totalSalaryForCurrentMonth * exchangeRate)) /
                    100
                  ).toFixed(3)}
              </td>
            </tr>
            <tr>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8" colSpan={2}>
                {" "}
              </td>
              <td className="tg-2776"> </td>
            </tr>
            <tr>
              <td className="tg-mlkz" colSpan={2}>
                Advance paid
              </td>
              <td className="tg-u9r81" colSpan={2}>
                0
              </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8" colSpan={2}>
                {" "}
              </td>
              <td className="tg-2776"> </td>
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                net salary
              </td>
              <td className="tg-u9r81" colSpan={2}>
                {row?.totalSalaryForCurrentMonth}
              </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8" colSpan={2}>
                {" "}
              </td>
              <td className="tg-2776"> </td>
            </tr>
            <tr>
              <td className="tg-2776" colSpan={2}>
                Round up
              </td>
              <td className="tg-u9r81" colSpan={2}>
                0
              </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8" colSpan={2}>
                {" "}
              </td>
              <td className="tg-2776"> </td>
            </tr>
            <tr>
              <td className="tg-u6id" colSpan={2} rowSpan={2}>
                Net salary &nbsp;&nbsp;&nbsp;to be paid
              </td>
              <td className="tg-u9r81" colSpan={2} rowSpan={2}>
                {row?.totalSalaryForCurrentMonth}
              </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8"> </td>
              <td className="tg-u9r8" colSpan={2}>
                {" "}
              </td>
              <td className="tg-2776"> </td>
            </tr>
            <tr>
              <td className="tg-mlkz">0</td>
              <td className="tg-2776"></td>
              <td className="tg-2776"></td>
              <td className="tg-2776"></td>
              <td className="tg-2776"> </td>
            </tr>
            <tr>
              <td className="tg-u6id1" colSpan={2}>
                Net salary - rounded
              </td>
              <td className="tg-u9r82" colSpan={2}>
                {row?.totalSalaryForCurrentMonth * exchangeRate}
              </td>
              <td className="tg-mlkz">TL</td>
              <td className="tg-mlkz" colSpan={2}>
                Exchange rate
              </td>
              <td className="tg-9ksr" colSpan={2}>
                {exchangeRate}
              </td>
            </tr>
            <tr>
              <td className="tg-2776x" colSpan={2}>
                {" "}
              </td>
              <td className="tg-2776x"></td>
              <td className="tg-2776x"></td>
              <td className="tg-2776x"></td>
              <td className="tg-2776x" colSpan={2}>
                {" "}
              </td>
              <td className="tg-2776x" colSpan={2}>
                {" "}
              </td>
            </tr>
            <tr>
              <td className="tg-2776x" colSpan={2}>
                {" "}
              </td>
              <td className="tg-2776x" colSpan={4}></td>
              <td className="tg-2776x"></td>
              <td className="tg-2776x"></td>
              <td className="tg-2776x"> </td>
            </tr>
            <tr>
              <td className="tg-2776xy" colSpan={2}></td>
              <td className="tg-2776xy"> </td>
              <td className="tg-2776xy"> </td>
              <td className="tg-2776xy"> </td>
              <td className="tg-2776xy"> </td>
              <td className="tg-2776xy"> </td>
              <td className="tg-2776xy"> </td>
              <td className="tg-2776xy"> </td>
            </tr>
            <tr>
              <td className="tg-f3sox" colSpan={11}>
                {" "}
              </td>
            </tr>
            <tr>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
            </tr>
            <tr>
              <td className="tg-oedm"></td>
              <td className="tg-0326" colSpan={3}>
                Received by
              </td>
              <td className="tg-oedm"></td>
              <td className="tg-w7c0"></td>
              <td className="tg-jqmv" colSpan={2}>
                prepare by
              </td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
            </tr>
            <tr>
              <td className="tg-oedm"></td>
              <td className="tg-ttyt" rowSpan={2}>
                Signature :
              </td>
              <td className="tg-jqmv"></td>
              <td className="tg-jqmv"></td>
              <td className="tg-oedm"></td>
              <td className="tg-pgku">Signature :</td>
              <td className="tg-pgku"></td>
              <td className="tg-pgku"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
            </tr>
            <tr>
              <td className="tg-oedm"></td>
              <td className="tg-w7c0"></td>
              <td className="tg-w7c0"></td>
              <td className="tg-oedm"></td>
              <td className="tg-w7c0"></td>
              <td className="tg-w7c0"></td>
              <td className="tg-w7c0"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
            </tr>
            <tr>
              <td className="tg-oedm"></td>
              <td className="tg-ttyt" rowSpan={2}>
                Name :
              </td>
              <td className="tg-w7c0" colSpan={3} rowSpan={2}>
               {row?.firstName + " " + row?.lastName}
              </td>
              <td className="tg-ttyt">Name :</td>
              <td className="tg-w7c0" colSpan={4}>
                {globalUsersData[accountantId]?.fullName}
              </td>
              <td className="tg-oedm"></td>
            </tr>
            <tr>
              <td className="tg-oedm"></td>
              <td className="tg-ttyt">Post :</td>
              <td className="tg-w7c0" colSpan={3}>
                {globalUsersData[accountantId]?.position}
              </td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
            </tr>
            <tr>
              <td className="tg-ttytx" colSpan={2}>
              </td>
              <td className="tg-ttytx" colSpan={2}>
              {moment(paymentDate).format("DD.MM.YYYY")}
              </td>
              <td className="tg-oedm"></td>
              <td className="tg-ttytx">Date :</td>
              <td className="tg-ttytx" colSpan={2}>
                {moment(paymentDate).format("DD.MM.YYYY")}
              </td>
              <td className="tg-ttytx"> </td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
            </tr>
            <tr>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
              <td className="tg-oedm"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
