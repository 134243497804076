import { userRef } from "../db/collectionsRef";
import { users } from "../db/interfaces";

interface UsersWithId extends users {
  id: string;
}

export const globalUsersData: Record<string, UsersWithId> & { loaded?: boolean } = {};

userRef.onSnapshot(data => {
  Object.defineProperty(globalUsersData, "loaded", { enumerable: false, value: true });
  data.docs.forEach(doc => {
    globalUsersData[doc.id] = { ...doc.data(), id: doc.id } as UsersWithId;
  });
});
