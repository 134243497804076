import React, { useState } from "react";
import firebase from "firebase/app";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Icon } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import Paper from "@material-ui/core/Paper";
import logo from "./aiu-logo.png";
import "./style.css";

const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function ResetEmail(s: RouteComponentProps) {
  const [loading, setLoading] = useState(false);
  const history = useHistory(); // IT MUST BE OUT OF THE FUNCTION

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: SignInSchema,

    onSubmit: (v) => handleSubmit(v),
  });

  const handleSubmit = (valuesS: { email: string }) => {
    const promise = firebase.auth().sendPasswordResetEmail(valuesS.email);

    promise.then(() => {
      message.success("Reset link has been sent to your email successfully");
      history.push(`/signin`);
    });

    promise.catch((error) => {
      console.log(error);
      var errorMessage = error.message;
      message.error(errorMessage);
      setLoading(false);
    });
  };

  const enterLoading = () => {
    setLoading(true);
  };

  return (
    <div className={"boxForm"}>
      <div
        style={{
          width: "450px",
          marginTop: "6%",
          padding: "2%",
          border: "solid",
          borderWidth: "thin",
          borderColor: "rgb(128 128 128 / 17%)",
          borderRadius: "11px",
          background: "rgb(245 245 245 / 36%)",
          boxShadow: "0px 1px 10px 2px rgba(0, 0, 0, 0.18)",
        }}
      >
        <img
          style={{ width: "50%", marginBottom: "8%", marginTop: "0%" }}
          src={logo}
        />

        <Form
          labelCol={{ span: 8 }}
          onSubmit={formik.handleSubmit}
          className="login-form"
        >
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email Address"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
              onClick={enterLoading}
              loading={loading}
              disabled={!formik.values.email}
            >
              Reset Email{" "}
            </Button>
            {/* Or <a href="/signup">register now!</a> */}
          </Form.Item>
        </Form>
        <div style={{ textAlign: "center" }}>
          <b>
            <h2 style={{ color: "grey" }}>Human Resources Management System</h2>
          </b>
          <p style={{ color: "#1890ff" }}>RC-HRMS Developed by RightCode</p>
        </div>
      </div>
    </div>
  );
}
