export const convertToHours = (num: number) => {
    const hours = Math.floor(num / 60);
    const min = num % 60;
    const minFixed = min.toFixed();
    return ("000" + hours).slice(-3) + ":" + ("00" + minFixed).slice(-2);
  };
export const convertToHoursMin = (num: number) => {
    const hours = Math.floor(num / 60);
    const min = num % 60;
    const minFixed = min.toFixed();
    return ("00" + hours).slice(-2) + ":" + ("00" + minFixed).slice(-2);
  };