import React, { useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select } from "antd";
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import {
  userRef,
  vacationRef,
  overtimeRef,
  projectRef,
  budgetLinesRef,
  allocationRef,
  delayRef,
  officeRef
} from "../../db/collectionsRef";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import logo from "./Picture1.png";
import { start } from "repl";
import { users } from "../../db/interfaces";
import printJS from "print-js";
import _, { sortBy } from "lodash";
import { HRManager } from "../../db/admin";
import firebase, { auth } from "firebase/app";
const moments = extendMoment(Moment);
const { Option } = Select;
import { useHistory, useRouteMatch } from "react-router-dom";

import "./style.css";

const convertToHours = (num: number) => {
  const hours = Math.floor(num / 60);
  const min = num % 60;
  const minFixed = min.toFixed();
  return ("000" + hours).slice(-3) + ":" + ("00" + minFixed).slice(-2);
};
const convertToHoursMin = (num: number) => {
  const hours = Math.floor(num / 60);
  const min = num % 60;
  const minFixed = min.toFixed();
  return ("00" + hours).slice(-2) + ":" + ("00" + minFixed).slice(-2);
};
export default function PrintTimeSheet() {
  const uid = useRouteMatch<{ id: string }>().params.id;
  const withDelays = useRouteMatch<{ original: any }>().params.original;
  const monthRoute = useRouteMatch<{ month: any }>().params.month;
  console.log(moment.unix(monthRoute).toDate());
  const [month, setMonth] = useState(moment(monthRoute, "MM-YYYY"));

  const [vacationSN] = useCollection(vacationRef.where("employeeUID", "==", uid));
  const [projectSN] = useCollection(projectRef);
  const [budgetLinesSN] = useCollection(budgetLinesRef);
  const [allocationSN] = useCollection(allocationRef.where("uid", "==", uid));
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);
  const [userSN] = useDocument(userRef.doc(uid));
  const [isOriginal, setIsOriginal] = useState(withDelays == "true" ? true : false);
  const [delaySN] = useCollection(firebase.firestore().collectionGroup("delay"));

  const CurrentUid = auth().currentUser!.uid;

  if (!vacationSN || !userSN || !projectSN || !budgetLinesSN || !allocationSN || !usersSN || !delaySN) return <Spin />;
  const USERS_ID_FULL_NAME = usersSN.docs.map(u => {
    const fullName = u.data().firstName + " " + u.data().lastName;
    const uid = u.id;
    return { uid, fullName };
  });

  const publicHolidays = [
    "01-01-2020",
    "01-05-2020",
    "19-05-2020",
    "24-05-2020",
    "25-05-2020",
    "26-05-2020",
    "15-07-2020",
    "31-07-2020",
    "01-08-2020",
    "02-08-2020",
    "03-08-2020",
    "30-08-2020",
    "29-10-2020",
    // "10-11-2020"
  ];
  const userData = userSN.data() as users;
  const fullName = userData.firstName + " " + userData.lastName;
  const position = userData.position;
  const location = officeSN?.docs.find(d => d.id == userData.office)?.data().officeName;
  const supervisorName = `${usersSN.docs.find(d => d.id == userData.managerUID)?.data().firstName} ${
    usersSN.docs.find(d => d.id == userData.managerUID)?.data().lastName
  }`;
  const supervisorPosition = usersSN.docs.find(d => d.id == userData.managerUID)?.data().position;
  const BEG = month.startOf("month").format("DD.MM.YYYY");
  const END = month.endOf("month").format("DD.MM.YYYY");

  const projectsArray = allocationSN.docs
    .filter(d => {
      const startDate = d.data().startDate.toDate();
      return startDate < month.endOf("month");
    })
    .filter(d => {
      const endDate = d.data().endDate.toDate();
      return month.startOf("month") < endDate;
    })
    .map(a => {
      return {
        project: a.data().projectId,
        budgetLine: a.data().budgetLineId,
        percentage: a.data().percentage,
        idaPercentage: a.data().idaAllocationPercentage,
        startDate: a.data().startDate,
        endDate: a.data().endDate
      };
    });

  const idaAllocationPercentage = projectsArray.length == 0 ? 0 : projectsArray[0].idaPercentage;
  const fullDayMin = 8 * 60;
  const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100;
  const halfDayMin = 4 * 60;
  const halfDayPercentageMin = (halfDayMin * idaAllocationPercentage) / 100;
  const timeOutMin = 9 * 60 + fullDayPercentageMin;
  const timeOutMin9 = 9 * 60 + fullDayPercentageMin / 2;
  const timeOutMin13 = 13 * 60 + fullDayPercentageMin / 2;
  const timeInMin9 = 9 * 60;
  const timeInMin13 = 13 * 60;

  const DDates = isOriginal
    ? delaySN.docs
        .filter(d => d.data().userUID == uid)
        .map(d => {
          return {
            userUID: d.data().userUID,
            day: d.data().dayDate,
            min: d.data().min,
            month: d.data().month
          };
        })
    : [
        {
          userUID: "",
          day: moment(),
          min: 0,
          month: ""
        }
      ];
  const VDates = vacationSN.docs.map(d => {
    return {
      start: d.data().vacationStartOn,
      end: d.data().vacationEndOn,
      duration: d.data().vocationPeriodDays,
      type: d.data().vacationType,
      halfDay: d.data().halfDay
    };
  });
  function daysInMonth(m: number) {
    var count = moment()
      .month(m)
      .daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(
        moment()
          .month(m)
          .date(i)
      );
    }
    return days;
  }
  var days = daysInMonth(month.month());

  const daysVacationsTimeIn = days.map(r => {
    let dateVacation =
      moment(r.toDate()).format("dd") == "Sa" || moment(r.toDate()).format("dd") == "Su" ? 0 : timeInMin9;
    const v = VDates.map(v => {
      const HalfDayTimeIn = v.halfDay == "morning" ? timeInMin9 : timeInMin13;
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
        dateVacation = v.duration == 0.5 ? HalfDayTimeIn : 0;
      }
    });
    const d = DDates.map((d: any) => {
      const dayDate = moment(d.day.toDate())
        .format("DD-MM-YYYY")
        .toString();
      if (
        moment(r.toDate())
          .format("DD-MM-YYYY")
          .toString() == dayDate
      ) {
        dateVacation = dateVacation + d.min;
      }
    });
    const h = publicHolidays.map(v => {
      if (
        moment(r.toDate())
          .format("DD-MM-YYYY")
          .toString() == v
      ) {
        dateVacation = 0;
      }
    });
    return { dateVacation, r };
  });
  const daysVacationsTimeOut = days.map(r => {
    let dateVacation =
      moment(r.toDate()).format("dd") == "Sa" || moment(r.toDate()).format("dd") == "Su" ? 0 : timeOutMin;

    const v = VDates.map(v => {
      const HalfDayTimeOut = v.halfDay == "morning" ? timeOutMin9 : v.halfDay == "evening" ? timeOutMin13 : timeOutMin;
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
        dateVacation = v.duration == 0.5 ? HalfDayTimeOut : 0;
      }
    });
    const h = publicHolidays.map(v => {
      if (
        moment(r.toDate())
          .format("DD-MM-YYYY")
          .toString() == v
      ) {
        dateVacation = 0;
      }
    });
    return { dateVacation, r };
  });
  const daysVacationsAnnual = days.map(r => {
    let dateVacation = 0;

    const v = VDates.map(v => {
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
        dateVacation = v.type == "Annual Leave" ? (v.duration == 0.5 ? halfDayPercentageMin : fullDayPercentageMin) : 0;
      }
    });
    const h = publicHolidays.map(v => {
      if (
        moment(r.toDate())
          .format("DD-MM-YYYY")
          .toString() == v
      ) {
        dateVacation = 0;
      }
    });
    dateVacation =
      moment(r.toDate()).format("dd") == "Sa" || moment(r.toDate()).format("dd") == "Su" ? 0 : dateVacation;

    return { dateVacation, r };
  });
  const daysVacationsSick = days.map(r => {
    let dateVacation = 0;

    const v = VDates.map(v => {
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
        dateVacation = v.type == "Sick Leave" ? (v.duration == 0.5 ? halfDayPercentageMin : fullDayPercentageMin) : 0;
      }
    });
    const h = publicHolidays.map(v => {
      if (
        moment(r.toDate())
          .format("DD-MM-YYYY")
          .toString() == v
      ) {
        dateVacation = 0;
      }
    });
    dateVacation =
      moment(r.toDate()).format("dd") == "Sa" || moment(r.toDate()).format("dd") == "Su" ? 0 : dateVacation;

    return { dateVacation, r };
  });
  const daysVacationsOther = days.map(r => {
    let dateVacation = 0;

    const v = VDates.map(v => {
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
        dateVacation =
          v.type !== "Sick Leave" && v.type !== "Annual Leave" && v.type !== "Unpaid Leave"
            ? v.duration == 0.5
              ? halfDayPercentageMin
              : fullDayPercentageMin
            : 0;
      }
    });
    const h = publicHolidays.map(v => {
      if (
        moment(r.toDate())
          .format("DD-MM-YYYY")
          .toString() == v
      ) {
        dateVacation = 0;
      }
    });
    dateVacation =
      moment(r.toDate()).format("dd") == "Sa" || moment(r.toDate()).format("dd") == "Su" ? 0 : dateVacation;
    return { dateVacation, r };
  });
  const daysVacationsPublicHolidays = days.map(r => {
    let dateVacation = 0;

    const h = publicHolidays.map(v => {
      if (
        moment(r.toDate())
          .format("DD-MM-YYYY")
          .toString() == v
      ) {
        dateVacation = fullDayPercentageMin;
      }
    });
    dateVacation =
      moment(r.toDate()).format("dd") == "Sa" || moment(r.toDate()).format("dd") == "Su" ? 0 : dateVacation;
    return { dateVacation, r };
  });
  const daysVacationsUnpaid = days.map(r => {
    let dateVacation = 0;

    const v = VDates.map(v => {
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
        dateVacation = v.type == "Unpaid Leave" ? (v.duration == 0.5 ? halfDayPercentageMin : fullDayPercentageMin) : 0;
      }
    });
    const h = publicHolidays.map(v => {
      if (
        moment(r.toDate())
          .format("DD-MM-YYYY")
          .toString() == v
      ) {
        dateVacation = 0;
      }
    });
    dateVacation =
      moment(r.toDate()).format("dd") == "Sa" || moment(r.toDate()).format("dd") == "Su" ? 0 : dateVacation;
    return { dateVacation, r };
  });

  const newTotalWorkedHours = days.map(d => {
    const num = 0;
    return { d, num };
  });
  const timeOut = daysVacationsTimeOut.map(d => d.dateVacation);
  const timeIn = daysVacationsTimeIn.map(d => d.dateVacation);
  for (let i = 0; i < timeIn.length; i++) {
    newTotalWorkedHours[i].num = timeOut[i] - timeIn[i];
  }

  const AnnalSick = days.map(d => {
    const num = 0;
    return { d, num };
  });
  const annal = daysVacationsAnnual.map(d => d.dateVacation);
  const sick = daysVacationsSick.map(d => d.dateVacation);
  for (let i = 0; i < timeIn.length; i++) {
    AnnalSick[i].num = annal[i] + sick[i];
  }
  const OtherPublic = days.map(d => {
    const num = 0;
    return { d, num };
  });
  const other = daysVacationsOther.map(d => d.dateVacation);
  const holidays = daysVacationsPublicHolidays.map(d => d.dateVacation);
  for (let i = 0; i < timeIn.length; i++) {
    OtherPublic[i].num = other[i] + holidays[i];
  }

  const TotalAllLeaves = days.map(d => {
    const num = 0;
    return { d, num };
  });
  const as = AnnalSick.map(d => d.num);
  const ot = OtherPublic.map(d => d.num);
  for (let i = 0; i < timeIn.length; i++) {
    TotalAllLeaves[i].num = ot[i] + as[i];
  }

  const TotalLeaveAndWorkedHours = days.map(d => {
    const num = 0;
    return { d, num };
  });
  const worked = newTotalWorkedHours.map(d => d.num);
  const leaves = TotalAllLeaves.map(d => d.num);
  for (let i = 0; i < timeIn.length; i++) {
    TotalLeaveAndWorkedHours[i].num = worked[i] + leaves[i];
  }

  const daysTotal = TotalLeaveAndWorkedHours.map(d => {
    let dateVacation =
      moment(d.d.toDate()).format("dd") == "Sa" || moment(d.d.toDate()).format("dd") == "Su" ? 0 : d.num;

    const v = VDates.map(v => {
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(d.d.toDate()).isBetween(start, end, undefined, "[]")) {
        dateVacation = v.type == "Unpaid Leave" ? 0 : d.num;
      }
    });
    const r = d.d;
    return { dateVacation, r };
  });

  const totalCellsProject = projectsArray.map(project => {
    const { startDate, endDate, percentage } = project;
    const start = moment(startDate.toDate()).startOf("day");
    const end = moment(endDate.toDate()).endOf("day");

    const arr = daysTotal.map(col => {
      if (moment(col.r.toDate()).isBetween(start, end, undefined, "[]")) {
        let dateVacation = (col.dateVacation * percentage) / 100;
        return dateVacation;
      } else {
        return 0;
      }
    });
    return arr;
  });

  const newTotalCell = days.map(d => 0);
  for (let i = 0; i < totalCellsProject.length; i++) {
    for (let j = 0; j < newTotalCell.length; j++) {
      //0 to 2
      newTotalCell[j] = newTotalCell[j] + totalCellsProject[i][j];
    }
  }

  const TOTAL_PROJECT_CELL = _.sum(newTotalCell);
  const hours = _.sumBy(daysTotal, d => d.dateVacation);
  const percentage = _.sumBy(projectsArray, d => d.percentage);
  const totalWorkedHours = _.sumBy(newTotalWorkedHours, d => d.num);
  const totalAnnualHours = _.sumBy(daysVacationsAnnual, d => d.dateVacation);
  const totalSickHours = _.sumBy(daysVacationsSick, d => d.dateVacation);
  const totalOtherHours = _.sumBy(daysVacationsOther, d => d.dateVacation);
  const totalPublicHours = _.sumBy(daysVacationsPublicHolidays, d => d.dateVacation);
  const totalUnpaidHours = _.sumBy(daysVacationsUnpaid, d => d.dateVacation);

  //   const print = async () => {
  //     await printJS({
  //       header: "TimeSheet",
  //       printable: "timeSheet",
  //       type: "html",
  //       targetStyles: ["*"],
  //       documentTitle: "TimeSheet",
  //       showModal: true
  //     });
  //   };

  return (
    <div>
      <Button
        style={{ margin: "1%" }}
        type="ghost"
        onClick={async () => {
          window.print();
        }}
        htmlType="submit"
      >
        Print{" "}
      </Button>{" "}
      <div id="timeSheet">
        <table style={{ width: "100%" }}>
          <tr className={"row"}>
            <th colspan={9} className={"logos"}>
              <img style={{ width: "150px", marginBottom: "3%", marginTop: "3%", marginLeft: "5%" }} src={logo} />
            </th>
            <th colspan={13} className={"textField"}>
              <h2>IDA</h2>
              <h4>{month.format("MMM.YYYY")}</h4>
              <h5>TIMESHEET</h5>
            </th>
            <th colspan={13} className={"logos"}>
              <img
                style={{ width: "150px", marginBottom: "3%", marginTop: "3%", marginRight: "5%", float: "right" }}
                src={logo}
              />
            </th>
          </tr>

          <tr className={"row"}>
            <th colspan={1} className={"titleText"}>
              <h4>Name</h4>
            </th>
            <th colspan={11} className={"inputText"}>
              <h4>{fullName}</h4>
            </th>
            <th colspan={8} className={"titleText"}>
              <h4>Position</h4>
            </th>
            <th colspan={15} className={"inputText"}>
              <h4>{position}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colspan={1} rowspan="2" className={"titleText"}>
              <h4>Month</h4>
            </th>
            <th colspan={1} className={"inputText"}>
              <h4>BEG</h4>
            </th>
            <th colspan={10} className={"inputText"}>
              <h4>{BEG}</h4>
            </th>
            <th colspan={8} className={"titleText"}>
              <h4>Location</h4>
            </th>
            <th colspan={15} className={"inputText"}>
              <h4>{location}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colspan={1} className={"inputText"}>
              <h4>END</h4>
            </th>
            <th colspan={10} className={"inputText"}>
              <h4>{END}</h4>
            </th>
            <th colspan={8} className={"titleText"}>
              <h4>Supervisor's Name</h4>
            </th>
            <th colspan={6} className={"inputText"}>
              <h4>{supervisorName}</h4>
            </th>
            <th colspan={3} className={"titleText"}>
              <h4>Title</h4>
            </th>
            <th colspan={7} className={"inputText"}>
              <h4>{supervisorPosition}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colspan={1} className={"titleText"}>
              <h4>Employee's Signature and Date</h4>
            </th>
            <th colspan={11} className={"inputText"}>
              <h4></h4>
            </th>
            <th colspan={8} className={"titleText"}>
              <h4>Supervisor's Signature and Date</h4>
            </th>
            <th colspan={15} className={"inputText"}>
              <h4></h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colspan={3} className={"titleText"}>
              <h4>Days</h4>
            </th>
            {days.map(col => (
              <th
                className={"cell"}
                style={{
                  background:
                    col.format("dd").toString() == "Sa" || col.format("dd").toString() == "Su" ? "yellow" : "#9e9e9e6b"
                }}
              >
                <h5>{col.format("dd")}</h5>
              </th>
            ))}
            <th colspan={3} className={"inputText"} style={{ background: "#fff" }}>
              <h5>Total</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colspan={3} className={"titleText"}>
              <h4>Date</h4>
            </th>
            {days.map(col => (
              <th
                className={"cell"}
                style={{
                  background:
                    col.format("dd").toString() == "Sa" || col.format("dd").toString() == "Su" ? "yellow" : "#9e9e9e6b"
                }}
              >
                <h5>{col.format("DD-MMM")}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colspan={3} className={"titleText"}>
              <h4>Time-in</h4>
            </th>
            {daysVacationsTimeIn.map(col => (
              <th
                className={"cell"}
                style={{
                  background: col.dateVacation == 0 ? "yellow" : "none"
                }}
              >
                <h5>{convertToHoursMin(col.dateVacation)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colspan={3} className={"titleText"}>
              <h4>Time-out</h4>
            </th>
            {daysVacationsTimeOut.map(col => (
              <th
                className={"cell"}
                style={{
                  background: col.dateVacation == 0 ? "yellow" : "none"
                }}
              >
                <h5>{convertToHoursMin(col.dateVacation)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>

          <tr className={"row"}>
            <th colspan={3} className={"titleText"}>
              <h4>Worked-Hours</h4>
            </th>
            {newTotalWorkedHours.map(d => (
              <th
                className={"cell"}
                style={{
                  background: d.num == 0 ? "yellow" : "none"
                }}
              >
                <h5>{convertToHoursMin(d.num)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(totalWorkedHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colspan={1} rowspan="5" className={"titleText"} style={{ background: "#9e9e9e6b" }}>
              Leave
            </th>

            <th colspan={2} className={"titleText"}>
              <h5>Annual-Leave</h5>
            </th>
            {daysVacationsAnnual.map(col => (
              <th className={"cell"}>
                <h5>{col.dateVacation == 0 ? "" : convertToHoursMin(col.dateVacation)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalAnnualHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colspan={2} className={"titleText"}>
              <h5>Sick-Leave</h5>
            </th>
            {daysVacationsSick.map(col => (
              <th className={"cell"}>
                <h5>{col.dateVacation == 0 ? "" : convertToHoursMin(col.dateVacation)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalSickHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colspan={2} className={"titleText"}>
              <h5>Other-Leave</h5>
            </th>
            {daysVacationsOther.map(col => (
              <th className={"cell"}>
                <h5>{col.dateVacation == 0 ? "" : convertToHoursMin(col.dateVacation)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalOtherHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colspan={2} className={"titleText"}>
              <h5>Public-Holiday</h5>
            </th>
            {daysVacationsPublicHolidays.map(col => (
              <th className={"cell"}>
                <h5>{col.dateVacation == 0 ? "" : convertToHoursMin(col.dateVacation)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalPublicHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colspan={2} className={"titleText"}>
              <h5>Unpaid-Leave</h5>
            </th>
            {daysVacationsUnpaid.map(col => (
              <th className={"cell"}>
                <h5>{col.dateVacation == 0 ? "" : convertToHoursMin(col.dateVacation)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalUnpaidHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th
              colspan={3}
              style={{
                border: "1px solid black",
                width: "25%",
                padding: "5px",
                background: "#fff",
                textAlign: "center"
              }}
            >
              <h4>Total</h4>
            </th>
            {daysTotal.map(col => (
              <th className={"cell"}>
                <h5>{convertToHoursMin(col.dateVacation)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(hours)}</h5>
            </th>
          </tr>
          <tr>
            <th colspan={34} className={"titleText"}>
              <h4>Hours Allocation</h4>
            </th>
          </tr>
          <tr>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>Projects</h6>
            </th>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>budgetLine</h6>
            </th>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>Percentage</h6>
            </th>
            {days.map(col => (
              <th className={"cell"}>
                <h5>{col.format("dd")}</h5>
              </th>
            ))}
          </tr>
          {projectsArray.map((p: any) => {
            const start = moment(p.startDate.toDate()).startOf("day");
            const end = moment(p.endDate.toDate()).endOf("day");
            const totalHoursProject = _.sumBy(daysTotal, (d: any) => {
              if (moment(d.r.toDate()).isBetween(start, end, undefined, "[]")) {
                return (d.dateVacation * p.percentage) / 100;
              } else {
                return 0;
              }
            });
            return (
              <tr>
                <th className={"projectInput"}>
                  <h5>{projectSN.docs.find(d => d.id == p.project)?.data().projectCode}</h5>
                </th>
                <th className={"projectInput"}>
                  <h5>{budgetLinesSN.docs.find(d => d.id == p.budgetLine)?.data().budgetLine}</h5>
                </th>
                <th className={"projectInput"}>
                  <h5>{p.percentage + "%"}</h5>
                </th>
                {daysTotal.map(col => {
                  if (moment(col.r.toDate()).isBetween(start, end, undefined, "[]")) {
                    let dateVacation = (col.dateVacation * p.percentage) / 100;

                    return (
                      <th className={"cell"}>
                        <h5>{dateVacation == 0 ? "" : convertToHoursMin(dateVacation)}</h5>
                      </th>
                    );
                  } else {
                    return (
                      <th className={"cell"}>
                        <h5></h5>
                      </th>
                    );
                  }
                })}
                <th className={"inputText"} style={{ background: "#fff" }}>
                  <h5> {convertToHours(totalHoursProject)}</h5>
                </th>
              </tr>
            );
          })}
          <tr className={"row"}>
            <th colspan={2} className={"inputText"} style={{ background: "#fff" }}>
              <h5>TOTAL HOURS ALLOCATED</h5>
            </th>
            <th className={"projectInput"} style={{ background: "#fff" }}>
              <h5>{percentage + "%"}</h5>
            </th>
            {newTotalCell.map(min => {
              return (
                <th className={"cell"}>
                  <h5>{convertToHoursMin(min)}</h5>
                </th>
              );
            })}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(TOTAL_PROJECT_CELL)}</h5>
            </th>
          </tr>
        </table>
        <br />
      </div>
    </div>
  );
}
