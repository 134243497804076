import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase, { auth } from "firebase/app";
import { Spin, Button, DatePicker, Select } from "antd";
const { Option } = Select;
import {  overtimeRef, officeRef } from "../../db/collectionsRef";
import DelayModel from "../models/delayModel";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { users } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
import { uniq } from "lodash";
import DevTable from "../../helpers/devex-table";
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function UsersDelay() {
  const history = useHistory();
  const uid = auth().currentUser!.uid;
  const [UID, setUID] = useState("");
  const [modalState, setModalState] = useState(false);
  const [filterTime, setFilterTime] = useState(moment());
  const [defaultHiddenColumnNames] = useState(["managerUID", "gender", "department"]);
  const [officeId, setOfficeId] = useState(currentUserData.office);
  const [value, loading, error] = useCollection(usersRef.where("office", "==", officeId));
  const [userSNALL] = useCollection(usersRef);

  const [officeSN] = useCollection(officeRef);
  const [delaySN] = useCollection(db.collectionGroup("delay"));
  const [overtimeSN] = useCollection(overtimeRef);
  if (value == undefined || loading || !delaySN || !overtimeSN || !officeSN || !currentUserData.fullControlOffices)
    return <Spin />;

  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map(id => {
    const officeName = officeSN?.docs?.find(d => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  const data = value.docs
    .filter(
      d => currentUserData.fullControlOffices.includes(d.data()?.office) || d.data()?.managerUID == uid || d.id == uid
    )
    .map((d, index) => {
      return {
        ...d.data(),
        id: d.id,
        index: index + 1
      };
    });
  const columnsExport = [
    { name: "index", title: "NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "department", title: "Department" },
    { name: "gender", title: "Gender" },
    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) =>
        `${userSNALL?.docs?.find(d => d.id == row.managerUID)?.data()?.firstName} ${
          userSNALL?.docs?.find(d => d.id == row.managerUID)?.data()?.lastName
        }`
    },
    { name: "position", title: "Position" },
    {
      name: "todayDelay",
      title: "Today Delay",
      getCellValue: (r: any) => {
        const id = r.id;
        let total = 0;
        delaySN.docs
          .filter(d => d.data()?.userUID == id)
          .filter(d => d.data()?.status != "disabled")
          .filter(d => {
            const today = moment()
              .format("DD.MM.YYYY")
              .toString();
            const delayDay = moment(d.data()?.dayDate.toDate())
              .format("DD.MM.YYYY")
              .toString();
            return today == delayDay;
          })
          ?.map(x => {
            return (total = total + x.data()?.min);
          });
        return total;
      }
    },
    {
      name: "totalDelays",
      title: "Total Delays",
      getCellValue: (r: any) => {
        const id = r.id;
        let total = 0;
        delaySN.docs
          .filter(d => d.data()?.userUID == id)
          .filter(d => d.data()?.status != "disabled")
          .filter(d => {
            const thisMonth = filterTime.format("MMM.YYYY").toString();
            const lastMonth = d.data()?.month;
            return thisMonth == lastMonth;
          })
          ?.map(x => {
            return (total = total + x.data()?.min);
          });
        return total;
      }
    },
    {
      name: "justifiedDelay",
      title: "Justified delay",
      getCellValue: (r: any) => {
        const id = r.id;
        let total = 0;
        delaySN.docs
          .filter(d => d.data()?.userUID == id)
          .filter(d => d.data()?.status == "disabled")
          .filter(d => {
            const thisMonth = filterTime.format("MMM.YYYY").toString();
            const lastMonth = d.data()?.month;
            return thisMonth == lastMonth;
          })
          ?.map(x => {
            return (total = total + x.data()?.min);
          });
        return total;
      }
    },
    {
      name: "totalOvertime",
      title: "Total Overtime",
      getCellValue: (r: any) => {
        const id = r.id;
        let total = 0;
        overtimeSN.docs
          .filter(d => d.data()?.employeeUID == id)
          .filter(d => {
            const thisMonth = filterTime.format("MMM.YYYY").toString();
            const lastMonth = moment(d.data()?.overtimeDate.toDate())
              .format("MMM.YYYY")
              .toString();
            return thisMonth == lastMonth;
          })
          ?.map(x => {
            return (total = total + x.data()?.overtimeAccepted * 60);
          });
        return total;
      }
    }
  ];
  const columns = [
    { name: "index", title: "NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "department", title: "Department" },
    { name: "gender", title: "Gender" },
    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) =>
        `${userSNALL?.docs?.find(d => d.id == row.managerUID)?.data()?.firstName} ${
          userSNALL?.docs?.find(d => d.id == row.managerUID)?.data()?.lastName
        }`
    },
    { name: "position", title: "Position" },
    {
      name: "todayDelay",
      title: "Today Delay",
      getCellValue: (r: any) => {
        const id = r.id;
        let total = 0;
        delaySN.docs
          .filter(d => d.data()?.userUID == id)
          .filter(d => d.data()?.status != "disabled")
          .filter(d => {
            const today = moment()
              .format("DD.MM.YYYY")
              .toString();
            const delayDay = moment(d.data()?.dayDate.toDate())
              .format("DD.MM.YYYY")
              .toString();
            return today == delayDay;
          })
          ?.map(x => {
            return (total = total + x.data()?.min);
          });
        return total;
      }
    },
    {
      name: "totalDelays",
      title: "Total Delays",
      getCellValue: (r: any) => {
        const id = r.id;
        let total = 0;
        delaySN.docs
          .filter(d => d.data()?.userUID == id)
          .filter(d => d.data()?.status != "disabled")
          .filter(d => {
            const thisMonth = filterTime.format("MMM.YYYY").toString();
            const lastMonth = d.data()?.month;
            return thisMonth == lastMonth;
          })
          ?.map(x => {
            return (total = total + x.data()?.min);
          });
        return total;
      }
    },
    {
      name: "justifiedDelay",
      title: "Justified delay",
      getCellValue: (r: any) => {
        const id = r.id;
        let total = 0;
        delaySN.docs
          .filter(d => d.data()?.userUID == id)
          .filter(d => d.data()?.status == "disabled")
          .filter(d => {
            const thisMonth = filterTime.format("MMM.YYYY").toString();
            const lastMonth = d.data()?.month;
            return thisMonth == lastMonth;
          })
          ?.map(x => {
            return (total = total + x.data()?.min);
          });
        return total;
      }
    },
    {
      name: "totalOvertime",
      title: "Total Overtime",
      getCellValue: (r: any) => {
        const id = r.id;
        let total = 0;
        overtimeSN.docs
          .filter(d => d.data()?.employeeUID == id)
          .filter(d => {
            const thisMonth = filterTime.format("MMM.YYYY").toString();
            const lastMonth = moment(d.data()?.overtimeDate.toDate())
              .format("MMM.YYYY")
              .toString();
            return thisMonth == lastMonth;
          })
          ?.map(x => {
            return (total = total + x.data()?.overtimeAccepted * 60);
          });
        return total;
      }
    },

    {
      name: "actionView",
      title: "Details",
      getCellValue: (r: any) => {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => {
                history.push(`/user-delay/${r.id}`);
              }}
            >
              View Records
            </Button>
          </div>
        );
      }
    },
    {
      name: "fingerPrint",
      title: "Finger Print Records",
      getCellValue: (r: any) => {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => {
                history.push(`/fingerPrint/${r.id}`);
              }}
            >
              Finger Print Records
            </Button>
          </div>
        );
      }
    },
    {
      name: "action",
      title: "Action",
      getCellValue: r => {
        return currentUserData.fullControlOffices.includes(r.office) ? (
          <Button
            type="danger"
            onClick={() => {
              setModalState(true);
              setUID(r.id);
            }}
          >
            Delay
          </Button>
        ) : null;
      }
    }
  ];
  return (
    <div>
      {modalState ? <DelayModel modalState={modalState} setModalState={setModalState} UID={UID} /> : null}
      <Paper>
        <MonthPicker
          size="large"
          style={{ margin: "1%" }}
          placeholder="Select month"
          value={filterTime}
          format={"MM-YYYY"}
          onChange={e => setFilterTime(moment(e))}
        />

        <Select
          size="default"
          placeholder="Search for Office!"
          style={{ width: "25%", padding: "1%" }}
          showSearch
          value={officeId}
          onChange={e => {
            setOfficeId(e);
          }}
        >
          {UserListOffices.map(d => {
            return <Option value={d.id}>{d.officeName}</Option>;
          })}
        </Select>

        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="Users_Delays"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
