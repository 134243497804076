import firebase from "firebase/app";

export async function firebaseUploadAndGetUrl(
  value: File | Blob,
  src: "docx" | "contract",
  employeeName: string,
  uid: string
): Promise<string> {
  const storage = firebase.storage();

  let url = "";

  const filename = `${employeeName}_contract.docx`;

  await storage
    .ref(uid)
    .child(filename)
    .put(value);

  const orderStorageRef = firebase.storage().ref(uid);

  url = await orderStorageRef.child(filename).getDownloadURL();

  return url;
}
