import { Grid } from "@devexpress/dx-react-grid-material-ui";
import DefaultDevExtremeGridComponents from "./defaultDevExtremeGridComponents";
import React from "react";

interface Props {
  data: any;
  columns: any;
  defaultHiddenColumnNames?: any;
  tableName: string;
  selectedItemsIds?: any[];
  SetSelectedItemsIds?: (ids: any) => void;
  selected?: boolean;
  defaultColumnWidths?: any[];
  typicalTable?: boolean;
  extendedTable?: boolean;
  rowDetails?: any;
  backgroundColor?: string;
  exportExcel?: boolean;
  table?: string;
  titles?: string[];
  felids?: string[];
  totalItems?: any[];
  summaryColumn?: boolean;
  columnsExport?: any[];
  rowClick?: (args: any) => void;
}
export default function DevTable(props: Props) {
  const {
    table,
    data,
    columns,
    defaultHiddenColumnNames,
    tableName,
    SetSelectedItemsIds,
    selectedItemsIds,
    selected,
    defaultColumnWidths,
    typicalTable,
    extendedTable,
    rowDetails,

    totalItems,
    summaryColumn,
    columnsExport,
    rowClick,
  } = props;

  return (
    <div style={{ backgroundColor: "white" }}>
      <Grid rows={data} columns={columns} getRowId={(r) => r.id}>
        {DefaultDevExtremeGridComponents({
          table: table,
          pagination: true,
          pageSize: 50,
          selectedItemsIds: selectedItemsIds,
          SetSelectedItemsIds: SetSelectedItemsIds,
          withSelection: selected,
          data: data,
          tableName: tableName,
          extendedTable: extendedTable,
          rowDetails: rowDetails,
          typicalTable: typicalTable,
          defaultHiddenColumnNames: defaultHiddenColumnNames,
          defaultColumnWidths: defaultColumnWidths,

          totalItems: totalItems,
          summaryColumn: summaryColumn,
          columnsExport: columnsExport,
          rowClick: rowClick,
        })}
      </Grid>
    </div>
  );
}
