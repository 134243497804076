import React, { useState, useEffect } from "react";
import firebase, { auth } from "firebase/app";
import {
  Button,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Input,
  Descriptions,
  DatePicker,
  Radio,
  Checkbox,
  Row,
  TimePicker,
} from "antd";

const { Option } = Select;
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userRef, officeRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { users } from "../../db/interfaces";
import { usersRef } from "../User/usersTable";
import { currentUserData } from "../../db/userGlobal";
import moment from "moment";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  UID: string;
}
const projectSchema = Yup.object().shape({
  managerUID: Yup.string().required("*"),
  office: Yup.string().required("*"),
});

function UsersInformation(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState, UID } = props;
  const [loading, setLoading] = useState(false);
  const uid = auth().currentUser!.uid;
  const [usersSNDoc] = useDocument(userRef.doc(UID));
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);
  const [partTimeSchedule, setPartTimeSchedule] = useState({
    saturday: { status: false, start: moment(), end: moment() },
    sunday: { status: false, start: moment(), end: moment() },
    monday: { status: false, start: moment(), end: moment() },
    tuesday: { status: false, start: moment(), end: moment() },
    wednesday: { status: false, start: moment(), end: moment() },
    thursday: { status: false, start: moment(), end: moment() },
    friday: { status: false, start: moment(), end: moment() },
  });
  const USERS_ID_FULL_NAME = usersSN?.docs
    .filter((user) => currentUserData.fullControlOffices.includes(user.data()?.office))
    .map((u) => {
      const fullName = u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName;
      const uid = u.id;
      return { uid, fullName };
    });

  const officeDataMap = officeSN?.docs
    .filter((d) => currentUserData.fullControlOffices.includes(d.id))
    .map((d) => {
      return {
        ...d.data(),
        id: d.id,
        officeName: d.data().officeName,
      };
    });

  const data = usersSNDoc?.data() as users;
  const staffFullName = `${data?.firstName} ${data?.lastName}`;
  const formik = useFormik({
    initialValues: {
      fatherName: "",
      motherName: "",
      salary: 0,
      currency: "",
      startingDate: moment(),
      endingDate: moment(),
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      gender: "",
      office: "",
      arabicFullName: "",
      dateOfBirth: moment(),
      placeOfBirth: "",
      permanentAddress: "",
      currentAddress: "",
      phoneNumber: "",
      identityNo: "",
      emergencyContact1: "",
      emergencyContact2: "",
      bankAccountName: "",
      bankName: "",
      IBAN: "",
      bankAccountNumber: "",
      managerUID: "",
      position: "",
      department: "",
      vacationAnnualScore: 0,
      vacationCompensationScore: 0,
      vacationSickScore: 0,
      vacationDeathScore: 0,
      vacationMarriageScore: 0,
      vacationMaternityScore: 0,
      vacationPaternityScore: 0,
      region: "",
      employmentType: "fullTime",
      idaAllocationPercentage: 0,
      resourceNO: "",
      paymentType: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      const promise = usersRef.doc(UID).set(
        {
          fatherName: values.fatherName,
          motherName: values.motherName,
          salary: values.salary,
          currency: values.currency,
          startingDate: values.startingDate.toDate() as any,
          endingDate: values.endingDate.toDate() as any,
          // email: values.email,
          // password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          arabicFullName: values.arabicFullName,
          dateOfBirth: values.dateOfBirth.toDate() as any,
          placeOfBirth: values.placeOfBirth,
          permanentAddress: values.permanentAddress,
          currentAddress: values.currentAddress,
          phoneNumber: values.phoneNumber,
          identityNo: values.identityNo,
          emergencyContact1: values.emergencyContact1,
          emergencyContact2: values.emergencyContact2,
          bankAccountName: values.bankAccountName,
          bankName: values.bankName,
          IBAN: values.IBAN,
          bankAccountNumber: values.bankAccountNumber,
          vacationCompensationScore: values.vacationCompensationScore,
          vacationDeathScore: values.vacationDeathScore,
          vacationMarriageScore: values.vacationMarriageScore,
          vacationMaternityScore: values.vacationMaternityScore,
          vacationPaternityScore: values.vacationPaternityScore,
          managerUID: values.managerUID,
          office: values.office,
          position: values.position,
          department: values.department,
          vacationAnnualScore: values.vacationAnnualScore,
          vacationSickScore: values.vacationSickScore,
          region: values.region,
          idaAllocationPercentage: values.idaAllocationPercentage,
          employmentType: values.employmentType,
          resourceNO: values.resourceNO,
          partTimeSchedule: {
            saturday: {
              ...partTimeSchedule.saturday,
              start: moment(partTimeSchedule.saturday.start).toDate(),
              end: moment(partTimeSchedule.saturday.end).toDate(),
            },
            sunday: {
              ...partTimeSchedule.sunday,
              start: moment(partTimeSchedule.sunday.start).toDate(),
              end: moment(partTimeSchedule.sunday.end).toDate(),
            },
            monday: {
              ...partTimeSchedule.monday,
              start: moment(partTimeSchedule.monday.start).toDate(),
              end: moment(partTimeSchedule.monday.end).toDate(),
            },
            tuesday: {
              ...partTimeSchedule.tuesday,
              start: moment(partTimeSchedule.tuesday.start).toDate(),
              end: moment(partTimeSchedule.tuesday.end).toDate(),
            },
            wednesday: {
              ...partTimeSchedule.wednesday,
              start: moment(partTimeSchedule.wednesday.start).toDate(),
              end: moment(partTimeSchedule.wednesday.end).toDate(),
            },
            thursday: {
              ...partTimeSchedule.thursday,
              start: moment(partTimeSchedule.thursday.start).toDate(),
              end: moment(partTimeSchedule.thursday.end).toDate(),
            },
            friday: {
              ...partTimeSchedule.friday,
              start: moment(partTimeSchedule.friday.start).toDate(),
              end: moment(partTimeSchedule.friday.end).toDate(),
            },
          },
          paymentType: values.paymentType,
        },
        { merge: true }
      );
      promise
        .then(() => {
          message.success("Done!");
          console.log("Document successfully written!");
          setModalState(false);
          formik.resetForm();
          setLoading(false);
        })

        .then(() => form.resetForm());
      promise.catch((error) => {
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (usersSNDoc && loaded) {
      formik.setValues({
        fatherName: data.fatherName ?? "",
        motherName: data.motherName ?? "",
        salary: data.salary ?? "",
        currency: data.currency ?? "",
        startingDate: moment(data.startingDate?.toDate()),
        endingDate: moment(data.endingDate?.toDate()),
        email: data.email ?? "",
        password: data.password ?? "",
        firstName: data.firstName ?? "",
        lastName: data.lastName ?? "",
        gender: data.gender ?? "",
        arabicFullName: data.arabicFullName ?? "",
        dateOfBirth: moment(data.dateOfBirth?.toDate()),
        placeOfBirth: data.placeOfBirth ?? "",
        permanentAddress: data.permanentAddress ?? "",
        currentAddress: data.currentAddress ?? "",
        phoneNumber: data.phoneNumber ?? "",
        identityNo: data.identityNo ?? "",
        emergencyContact1: data.emergencyContact1 ?? "",
        emergencyContact2: data.emergencyContact2 ?? "",
        bankAccountName: data.bankAccountName ?? "",
        bankName: data.bankName ?? "",
        IBAN: data.IBAN ?? "",
        bankAccountNumber: data.bankAccountNumber ?? "",
        vacationCompensationScore: data.vacationCompensationScore ?? "",
        vacationDeathScore: data.vacationDeathScore ?? "",
        vacationMarriageScore: data.vacationMarriageScore ?? "",
        vacationMaternityScore: data.vacationMaternityScore ?? "",
        vacationPaternityScore: data.vacationPaternityScore ?? "",
        managerUID: data.managerUID ?? "",
        office: data.office ?? "",
        position: data.position ?? "",
        department: data.department ?? "",
        vacationAnnualScore: data.vacationAnnualScore ?? "",
        vacationSickScore: data.vacationSickScore ?? "",
        region: data.region ?? "",
        employmentType: data.employmentType ?? "",
        idaAllocationPercentage: data.idaAllocationPercentage ?? 0,
        resourceNO: data.resourceNO ?? "",
        paymentType: data.paymentType ?? "",
      } as any);
      setPartTimeSchedule(
        data.partTimeSchedule
          ? {
              saturday: {
                ...data.partTimeSchedule.saturday,
                start: moment(data.partTimeSchedule.saturday.start.toDate()),
                end: moment(data.partTimeSchedule.saturday.end.toDate()),
              },
              sunday: {
                ...data.partTimeSchedule.sunday,
                start: moment(data.partTimeSchedule.sunday.start.toDate()),
                end: moment(data.partTimeSchedule.sunday.end.toDate()),
              },
              monday: {
                ...data.partTimeSchedule.monday,
                start: moment(data.partTimeSchedule.monday.start.toDate()),
                end: moment(data.partTimeSchedule.monday.end.toDate()),
              },
              tuesday: {
                ...data.partTimeSchedule.tuesday,
                start: moment(data.partTimeSchedule.tuesday.start.toDate()),
                end: moment(data.partTimeSchedule.tuesday.end.toDate()),
              },
              wednesday: {
                ...data.partTimeSchedule.wednesday,
                start: moment(data.partTimeSchedule.wednesday.start.toDate()),
                end: moment(data.partTimeSchedule.wednesday.end.toDate()),
              },
              thursday: {
                ...data.partTimeSchedule.thursday,
                start: moment(data.partTimeSchedule.thursday.start.toDate()),
                end: moment(data.partTimeSchedule.thursday.end.toDate()),
              },
              friday: {
                ...data.partTimeSchedule.friday,
                start: moment(data.partTimeSchedule.friday.start.toDate()),
                end: moment(data.partTimeSchedule.friday.end.toDate()),
              },
            }
          : partTimeSchedule
      );
      setLoaded(false);
    }
  }, [usersSNDoc, formik, loaded]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const format = "HH:mm";

  return (
    <Drawer
      title={`(${staffFullName}) profile`}
      placement={"left"}
      width={1000}
      visible={modalState}
      onClose={() => {
        setModalState(false);
        formik.resetForm();
      }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.firstName")} span={3}>
                <Input
                  value={formik.values.firstName}
                  onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.lastName")} span={3}>
                <Input
                  value={formik.values.lastName}
                  onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.identityNo")} span={3}>
                <Input
                  type="text"
                  id="identityNo"
                  value={formik.values.identityNo}
                  onChange={(e) => formik.setFieldValue("identityNo", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.arabicFullName")} span={3}>
                <Input
                  type="text"
                  id="arabicFullName"
                  value={formik.values.arabicFullName}
                  onChange={(e) => formik.setFieldValue("arabicFullName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.dateOfBirth")} span={3}>
                <DatePicker
                  value={formik.values.dateOfBirth}
                  format={dateFormat}
                  onChange={(e: any) => {
                    formik.setFieldValue("dateOfBirth", e);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.placeOfBirth")} span={3}>
                <Input
                  type="text"
                  id="placeOfBirth"
                  value={formik.values.placeOfBirth}
                  onChange={(e) => formik.setFieldValue("placeOfBirth", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.permanentAddress")} span={6}>
                <Input
                  type="text"
                  id="permanentAddress"
                  value={formik.values.permanentAddress}
                  onChange={(e) => formik.setFieldValue("permanentAddress", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.currentAddress")} span={6}>
                <Input
                  type="text"
                  id="currentAddress"
                  value={formik.values.currentAddress}
                  onChange={(e) => formik.setFieldValue("currentAddress", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.gender")} span={3}>
                <Select
                  id="gender"
                  showSearch
                  value={formik.values.gender}
                  onChange={(e) => {
                    formik.setFieldValue("gender", e);
                  }}
                >
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.fatherName")} span={3}>
                <Input
                  type="text"
                  id="fatherName"
                  value={formik.values.fatherName}
                  onChange={(e) => formik.setFieldValue("fatherName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.motherName")} span={3}>
                <Input
                  type="text"
                  id="motherName"
                  value={formik.values.motherName}
                  onChange={(e) => formik.setFieldValue("motherName", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.phoneNumber")} span={3}>
                <Input
                  type="text"
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.emergencyContact1")} span={3}>
                <Input
                  type="text"
                  id="emergencyContact1"
                  value={formik.values.emergencyContact1}
                  onChange={(e) => formik.setFieldValue("emergencyContact1", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.emergencyContact2")} span={3}>
                <Input
                  type="text"
                  id="emergencyContact2"
                  value={formik.values.emergencyContact2}
                  onChange={(e) => formik.setFieldValue("emergencyContact2", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.startingDate")} span={3}>
                <DatePicker
                  value={formik.values.startingDate}
                  format={dateFormat}
                  onChange={(e: any) => {
                    formik.setFieldValue("startingDate", e);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.endingDate")} span={3}>
                <DatePicker
                  value={formik.values.endingDate}
                  format={dateFormat}
                  onChange={(e: any) => {
                    formik.setFieldValue("endingDate", e);
                  }}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.salary")} span={3}>
                <InputNumber min={0} value={formik.values.salary} onChange={(e) => formik.setFieldValue("salary", e)} />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.currency")} span={3}>
                <Input
                  type="text"
                  id="currency"
                  value={formik.values.currency}
                  onChange={(e) => formik.setFieldValue("currency", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankAccountName")} span={3}>
                <Input
                  type="text"
                  id="bankAccountName"
                  value={formik.values.bankAccountName}
                  onChange={(e) => formik.setFieldValue("bankAccountName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankName")} span={3}>
                <Input
                  type="text"
                  id="bankName"
                  value={formik.values.bankName}
                  onChange={(e) => formik.setFieldValue("bankName", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.IBAN")} span={3}>
                <Input
                  type="text"
                  id="IBAN"
                  value={formik.values.IBAN}
                  onChange={(e) => formik.setFieldValue("IBAN", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.bankAccountNumber")} span={3}>
                <Input
                  type="text"
                  id="bankAccountNumber"
                  value={formik.values.bankAccountNumber}
                  onChange={(e) => formik.setFieldValue("bankAccountNumber", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.supervisor")} span={3}>
                <Select
                  showSearch
                  value={USERS_ID_FULL_NAME?.find((d) => d.uid == formik.values.managerUID)?.fullName}
                  onChange={(e) => {
                    const id = USERS_ID_FULL_NAME?.find((d) => d.fullName == e)?.uid;
                    formik.setFieldValue("managerUID", id);
                  }}
                >
                  {USERS_ID_FULL_NAME?.map((d) => {
                    return <Option value={d.fullName}>{d.fullName}</Option>;
                  })}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.location")} span={3}>
                <Select
                  value={officeSN?.docs.find((d) => d.id == formik.values.office)?.data().officeName}
                  onChange={(e) => formik.setFieldValue("office", e)}
                >
                  {officeDataMap?.map((d) => {
                    return <Option value={d.id}>{d.officeName}</Option>;
                  })}
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.position")} span={3}>
                <Input
                  value={formik.values.position}
                  onChange={(e) => formik.setFieldValue("position", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.department")} span={3}>
                <Input
                  value={formik.values.department}
                  onChange={(e) => formik.setFieldValue("department", e.target.value)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.vacationAnnualScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationAnnualScore}
                  onChange={(e) => formik.setFieldValue("vacationAnnualScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationSickScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationSickScore}
                  onChange={(e) => formik.setFieldValue("vacationSickScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationCompensationScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationCompensationScore}
                  onChange={(e) => formik.setFieldValue("vacationCompensationScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationDeathScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationDeathScore}
                  onChange={(e) => formik.setFieldValue("vacationDeathScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationMarriageScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationMarriageScore}
                  onChange={(e) => formik.setFieldValue("vacationMarriageScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationMaternityScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationMaternityScore}
                  onChange={(e) => formik.setFieldValue("vacationMaternityScore", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationPaternityScore")} span={3}>
                <InputNumber
                  min={0}
                  value={formik.values.vacationPaternityScore}
                  onChange={(e) => formik.setFieldValue("vacationPaternityScore", e)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.region")} span={3}>
                <Form.Item validateStatus={formik.errors.region ? "error" : ""}>
                  <Select
                    id="region"
                    showSearch
                    value={formik.values.region}
                    onChange={(e) => {
                      formik.setFieldValue("region", e);
                    }}
                  >
                    <Option value="syria">Syria</Option>
                    <Option value="turkey">Turkey</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.paymentType")} span={3}>
                <Form.Item validateStatus={formik.errors.paymentType ? "error" : ""}>
                  <Select
                    id="region"
                    showSearch
                    value={formik.values.paymentType}
                    onChange={(e) => {
                      formik.setFieldValue("paymentType", e);
                    }}
                  >
                    <Option value="cash">Cash</Option>
                    <Option value="transfer">Transfer</Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.resourceNO")} span={3}>
                <Input
                  value={formik.values.resourceNO}
                  onChange={(e) => formik.setFieldValue("resourceNO", e.target.value)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.idaAllocationPercentage")} span={6}>
                <Form.Item validateStatus={formik.errors.idaAllocationPercentage ? "error" : ""}>
                  <InputNumber
                    disabled={true}
                    min={0}
                    value={formik.values.idaAllocationPercentage}
                    onChange={(e) => formik.setFieldValue("idaAllocationPercentage", e)}
                  />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.employmentType")} span={6}>
                <Form.Item validateStatus={formik.errors.employmentType ? "error" : ""}>
                  <Radio.Group
                    onChange={(e) => {
                      formik.setFieldValue("employmentType", e.target.value);
                    }}
                    value={formik.values.employmentType}
                  >
                    <Radio value={"fullTime"}>Full-Time</Radio>
                    <Radio value={"partTime"}>Part-Time</Radio>
                    <Radio value={"shift"}>Shift</Radio>
                  </Radio.Group>
                </Form.Item>
              </Descriptions.Item>
              {formik.values.employmentType == "partTime" ? (
                <Descriptions.Item label={t("general.partTimeSchedule")} span={6}>
                  <Form.Item>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.saturday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            saturday: { ...partTimeSchedule.saturday, status: e.target.checked },
                          });
                        }}
                      >
                        Saturday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              saturday: { ...partTimeSchedule.saturday, start: moment(e) as any },
                            })
                          }
                          value={partTimeSchedule?.saturday?.start}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            saturday: { ...partTimeSchedule.saturday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.saturday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.sunday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            sunday: { ...partTimeSchedule.sunday, status: e.target.checked },
                          });
                        }}
                      >
                        Sunday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              sunday: { ...partTimeSchedule.sunday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.sunday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            sunday: { ...partTimeSchedule.sunday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.sunday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.monday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            monday: { ...partTimeSchedule.monday, status: e.target.checked },
                          });
                        }}
                      >
                        Monday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              monday: { ...partTimeSchedule.monday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.monday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            monday: { ...partTimeSchedule.monday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.monday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.tuesday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            tuesday: { ...partTimeSchedule.tuesday, status: e.target.checked },
                          });
                        }}
                      >
                        Tuesday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              tuesday: { ...partTimeSchedule.tuesday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.tuesday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            tuesday: { ...partTimeSchedule.tuesday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.tuesday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.wednesday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            wednesday: { ...partTimeSchedule.wednesday, status: e.target.checked },
                          });
                        }}
                      >
                        Wednesday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              wednesday: { ...partTimeSchedule.wednesday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.wednesday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            wednesday: { ...partTimeSchedule.wednesday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.wednesday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.thursday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            thursday: { ...partTimeSchedule.thursday, status: e.target.checked },
                          });
                        }}
                      >
                        Thursday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              thursday: { ...partTimeSchedule.thursday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.thursday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            thursday: { ...partTimeSchedule.thursday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.thursday.end)}
                      />
                    </Row>
                    <Row>
                      <Checkbox
                        checked={partTimeSchedule.friday.status}
                        onChange={(e) => {
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            friday: { ...partTimeSchedule.friday, status: e.target.checked },
                          });
                        }}
                      >
                        Friday
                      </Checkbox>
                      <span>
                        {"- Start: "}
                        <TimePicker
                          allowClear={false}
                          format={format}
                          onChange={(e) =>
                            setPartTimeSchedule({
                              ...partTimeSchedule,
                              friday: { ...partTimeSchedule.friday, start: moment(e).toDate() as any },
                            })
                          }
                          value={moment(partTimeSchedule.friday.start)}
                        />
                      </span>
                      <span> {"End: "}</span>
                      <TimePicker
                        allowClear={false}
                        format={format}
                        onChange={(e) =>
                          setPartTimeSchedule({
                            ...partTimeSchedule,
                            friday: { ...partTimeSchedule.friday, end: moment(e).toDate() as any },
                          })
                        }
                        value={moment(partTimeSchedule.friday.end)}
                      />
                    </Row>
                  </Form.Item>
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default UsersInformation;
