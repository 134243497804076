// import { ordersRef, SCItemsRef, SCRef } from 'common/db/collectionsRef'
// import { SCItem, ServiceContract } from 'common/db/interfaces'
import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils";
import contract from "./contract.docx";
import { firebaseUploadAndGetUrl } from "./firebaseUploadAndGetUrl";
import { orderRef } from "../../db/collectionsRef";
import moment from "moment";

export const generateAndUploadWord = async (TemplateFile: string, templateData: any, orderId: string, uid: string) => {
  function loadFile(url: any, callback: () => void) {
    PizZipUtils.getBinaryContent(contract, callback);
  }

  loadFile(TemplateFile, function(error: any, content: any) {
    console.log({ error, content });

    if (error) {
      throw error;
    }

    function replaceErrors(key: any, value: any) {
      if (value instanceof Error) {
        return Object.getOwnPropertyNames(value).reduce(function(error, key) {
          error[key] = value[key];
          return error;
        }, {});
      }
      return value;
    }

    function errorHandler(error: any) {
      console.log(JSON.stringify({ error: error }, replaceErrors));

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function(error: any) {
            return error.properties.explanation;
          })
          .join("\n");
        console.log("errorMessages", errorMessages);
      }
      throw error;
    }

    const zip = new PizZip(content);

    let doc;

    try {
      doc = new docxtemplater(zip);
    } catch (error) {
      errorHandler(error);
    }

    async function dataRun(doc: any) {
      try {
        doc.setData({
          firstName: templateData.firstName,
          lastName: templateData.lastName,
          fatherName: templateData.fatherName,
          nationalaty: templateData.nationality,
          cityInSyria: templateData.cityInSyria,
          birthPlace: templateData.birthPlace,
          birthDate: moment(templateData.birthDate.toDate()).format("DD.MM.YYYY"),
          TC: templateData.TC,
          passportNo: templateData.passportNO,
          residenceAddress: templateData.residenceAddress,
          salary: templateData.salary == 0 || templateData.salary == undefined ? "" : templateData.salary
        });

        const fileR = await doc.render();

        const file = fileR.getZip().generate({ type: "nodebuffer" });

        const url = await firebaseUploadAndGetUrl(file, "contract", templateData.firstName, uid);

        console.log("i am hereeee");
        await orderRef.doc(orderId).update({
          contractURL: url
        });
      } catch (error) {
        errorHandler(error);
      }
    }

    dataRun(doc);
  });
};
