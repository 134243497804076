import React, { useState, useEffect } from "react";
import firebase, { auth } from "firebase/app";
import {
  Button,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Input,
  Descriptions,
  DatePicker,
  Spin,
  Avatar,
  Icon,
} from "antd";

const { Option } = Select;
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userRef, officeRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { users } from "../../db/interfaces";
import { usersRef } from "./usersTable";
import { currentUserData } from "../../db/userGlobal";
import moment from "moment";
import firebaseConfig from "../../config/firebase";
import FileUploader from "react-firebase-file-uploader";
import SignatureTab from "./signatureTab";
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const userSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required(),
  
});

export default function MyProfile() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const uid = auth().currentUser!.uid;
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [usersSNDoc] = useDocument(userRef.doc(uid));

  const data = usersSNDoc?.data() as users;
  const formik = useFormik({
    initialValues: {
      fatherName: "",
      motherName: "",
      salary: 0,
      currency: "",
      startingDate: moment(),
      endingDate: moment(),
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      gender: "",
      office: "",
      arabicFullName: "",
      dateOfBirth: moment(),
      placeOfBirth: "",
      permanentAddress: "",
      currentAddress: "",
      phoneNumber: "",
      identityNo: "",
      emergencyContact1: "",
      emergencyContact2: "",
      bankAccountName: "",
      bankName: "",
      IBAN: "",
      bankAccountNumber: "",
      managerUID: "",
      position: "",
      department: "",
      vacationAnnualScore: 0,
      vacationCompensationScore: 0,
      vacationSickScore: 0,
      vacationDeathScore: 0,
      vacationMarriageScore: 0,
      vacationMaternityScore: 0,
      vacationPaternityScore: 0,
    },
    validationSchema: userSchema,
    onSubmit: async (values, form) => {
      const promise = usersRef.doc(uid).set(
        {
          fatherName: values.fatherName,
          motherName: values.motherName,
          salary: values.salary,
          currency: values.currency,
          startingDate: values.startingDate.toDate() as any,
          endingDate: values.endingDate.toDate() as any,
          // email: values.email,
          // password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          arabicFullName: values.arabicFullName,
          dateOfBirth: values.dateOfBirth.toDate() as any,
          placeOfBirth: values.placeOfBirth,
          permanentAddress: values.permanentAddress,
          currentAddress: values.currentAddress,
          phoneNumber: values.phoneNumber,
          identityNo: values.identityNo,
          emergencyContact1: values.emergencyContact1,
          emergencyContact2: values.emergencyContact2,
          bankAccountName: values.bankAccountName,
          bankName: values.bankName,
          IBAN: values.IBAN,
          bankAccountNumber: values.bankAccountNumber,
          vacationCompensationScore: values.vacationCompensationScore,
          vacationDeathScore: values.vacationDeathScore,
          vacationMarriageScore: values.vacationMarriageScore,
          vacationMaternityScore: values.vacationMaternityScore,
          vacationPaternityScore: values.vacationPaternityScore,
          managerUID: values.managerUID,
          office: values.office,
          position: values.position,
          department: values.department,
          vacationAnnualScore: values.vacationAnnualScore,
          vacationSickScore: values.vacationSickScore,
        },
        { merge: true }
      );
      promise.then(() => {
        message.success("Done!");
        console.log("Document successfully written!");
        setLoading(false);
      });

      promise.catch((error) => {
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (usersSNDoc && loaded) {
      formik.setValues({
        fatherName: data.fatherName ?? "",
        motherName: data.motherName ?? "",
        salary: data.salary ?? "",
        currency: data.currency ?? "",
        startingDate: moment(data.startingDate?.toDate()),
        endingDate: moment(data.endingDate?.toDate()),
        email: data.email ?? "",
        password: data.password ?? "",
        firstName: data.firstName ?? "",
        lastName: data.lastName ?? "",
        gender: data.gender ?? "",
        arabicFullName: data.arabicFullName ?? "",
        dateOfBirth: moment(data.dateOfBirth?.toDate()),
        placeOfBirth: data.placeOfBirth ?? "",
        permanentAddress: data.permanentAddress ?? "",
        currentAddress: data.currentAddress ?? "",
        phoneNumber: data.phoneNumber ?? "",
        identityNo: data.identityNo ?? "",
        emergencyContact1: data.emergencyContact1 ?? "",
        emergencyContact2: data.emergencyContact2 ?? "",
        bankAccountName: data.bankAccountName ?? "",
        bankName: data.bankName ?? "",
        IBAN: data.IBAN ?? "",
        bankAccountNumber: data.bankAccountNumber ?? "",
        vacationCompensationScore: data.vacationCompensationScore ?? "",
        vacationDeathScore: data.vacationDeathScore ?? "",
        vacationMarriageScore: data.vacationMarriageScore ?? "",
        vacationMaternityScore: data.vacationMaternityScore ?? "",
        vacationPaternityScore: data.vacationPaternityScore ?? "",
        managerUID: data.managerUID ?? "",
        office: data.office ?? "",
        position: data.position ?? "",
        department: data.department ?? "",
        vacationAnnualScore: data.vacationAnnualScore ?? "",
        vacationSickScore: data.vacationSickScore ?? "",
      } as any);
      setLoaded(false);
    }
  }, [usersSNDoc, formik, loaded]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const orderStorageRef = firebase.storage().ref(uid).child("images");

  return (
    <div>
      <div style={{ textAlign: "center", paddingBottom: "1%" }}>
        <label style={{ cursor: "pointer" }}>
          <Avatar shape="circle" size={225} icon="user" src={currentUserData.personalPhoto} />
          <FileUploader
            accept="image/*"
            name="avatar"
            hidden
            storageRef={orderStorageRef}
            onUploadStart={() => setUploadingPhoto(true)}
            onUploadSuccess={async (fileName: string) => {
              const downloadURL = (await orderStorageRef.child(fileName).getDownloadURL()) as string;
              setUploadingPhoto(false);
              userRef.doc(uid).update({
                personalPhoto: downloadURL,
              });
              message.success(" Uploaded successfully");
            }}
            onUploadError={(e: any) => message.success(e)}
          />
        </label>
        {uploadingPhoto ? <Spin indicator={antIcon} /> : null}
      </div>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <div style={{ textAlign: "-webkit-center" }}>
            <div>
              <Descriptions
                style={{ width: "55%" }}
                size={"middle"}
                layout="horizontal"
                bordered
                column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item label={t("general.firstName")} span={3}>
                  <Input
                    disabled={data?.firstName != ""}
                    value={formik.values.firstName}
                    onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.lastName")} span={3}>
                  <Input
                    disabled={data?.lastName != ""}
                    value={formik.values.lastName}
                    onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.identityNo")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="identityNo"
                    value={formik.values.identityNo}
                    onChange={(e) => formik.setFieldValue("identityNo", e.target.value)}
                  />
                </Descriptions.Item>

                <Descriptions.Item label={t("general.arabicFullName")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="arabicFullName"
                    value={formik.values.arabicFullName}
                    onChange={(e) => formik.setFieldValue("arabicFullName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.dateOfBirth")} span={3}>
                  <DatePicker
                    disabled={false}
                    value={formik.values.dateOfBirth}
                    format={dateFormat}
                    onChange={(e: any) => {
                      formik.setFieldValue("dateOfBirth", e);
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.placeOfBirth")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="placeOfBirth"
                    value={formik.values.placeOfBirth}
                    onChange={(e) => formik.setFieldValue("placeOfBirth", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.permanentAddress")} span={6}>
                  <Input
                    disabled={false}
                    type="text"
                    id="permanentAddress"
                    value={formik.values.permanentAddress}
                    onChange={(e) => formik.setFieldValue("permanentAddress", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.currentAddress")} span={6}>
                  <Input
                    disabled={false}
                    type="text"
                    id="currentAddress"
                    value={formik.values.currentAddress}
                    onChange={(e) => formik.setFieldValue("currentAddress", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.gender")} span={3}>
                  <Select
                    disabled={false}
                    id="gender"
                    showSearch
                    value={formik.values.gender}
                    onChange={(e) => {
                      formik.setFieldValue("gender", e);
                    }}
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label={t("general.fatherName")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="fatherName"
                    value={formik.values.fatherName}
                    onChange={(e) => formik.setFieldValue("fatherName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.motherName")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="motherName"
                    value={formik.values.motherName}
                    onChange={(e) => formik.setFieldValue("motherName", e.target.value)}
                  />
                </Descriptions.Item>

                <Descriptions.Item label={t("general.phoneNumber")} span={3}>
                  <Input
                    disabled={false}
                    type="text"
                    id="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.emergencyContact1")} span={3}>
                  <Input
                    type="text"
                    id="emergencyContact1"
                    value={formik.values.emergencyContact1}
                    onChange={(e) => formik.setFieldValue("emergencyContact1", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.emergencyContact2")} span={3}>
                  <Input
                    type="text"
                    id="emergencyContact2"
                    value={formik.values.emergencyContact2}
                    onChange={(e) => formik.setFieldValue("emergencyContact2", e.target.value)}
                  />
                </Descriptions.Item>

                <Descriptions.Item label={t("general.bankAccountName")} span={3}>
                  <Input
                    type="text"
                    id="bankAccountName"
                    value={formik.values.bankAccountName}
                    onChange={(e) => formik.setFieldValue("bankAccountName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.bankName")} span={3}>
                  <Input
                    type="text"
                    id="bankName"
                    value={formik.values.bankName}
                    onChange={(e) => formik.setFieldValue("bankName", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.IBAN")} span={3}>
                  <Input
                    type="text"
                    id="IBAN"
                    value={formik.values.IBAN}
                    onChange={(e) => formik.setFieldValue("IBAN", e.target.value)}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("general.bankAccountNumber")} span={3}>
                  <Input
                    type="text"
                    id="bankAccountNumber"
                    value={formik.values.bankAccountNumber}
                    onChange={(e) => formik.setFieldValue("bankAccountNumber", e.target.value)}
                  />
                </Descriptions.Item>
              </Descriptions>

              <div>
                <Button
                  style={{ margin: "1%", width: "25%" }}
                  className="btn blue lighten-1 z-depth-0"
                  onClick={() => {
                    enterLoading();
                  }}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  disabled={!formik.isValid}
                >
                  {t("general.save")}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
