import React, { useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select } from "antd";
const { MonthPicker } = DatePicker;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import {
  userRef,
  vacationRef,
  projectRef,
  budgetLinesRef,
  allocationRef,
  officeRef,
  positionRef,
} from "../../db/collectionsRef";

import logo from "./Picture1.png";
import { delay, users } from "../../db/interfaces";
import printJS from "print-js";
import _, { sortBy } from "lodash";
import firebase, { auth } from "firebase/app";
const { Option } = Select;
import "./style.css";
import { currentUserData, positions } from "../../db/userGlobal";
import { convertToHours, convertToHoursMin } from "./convertToTimeForm";
import { currentP } from "../../db/currentPosition";
import { globalMissionsData } from "../../global/missionList";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
export default function TimeSheet() {
  const [month, setMonth] = useState(moment().subtract(1, "months"));
  const [uid, setUid] = useState(auth().currentUser!.uid);
  const [vacationSN] = useCollection(vacationRef.where("employeeUID", "==", uid));
  const [projectSN] = useCollection(projectRef);
  const [budgetLinesSN] = useCollection(budgetLinesRef);
  const [allocationSN] = useCollection(allocationRef.where("uid", "==", uid));
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);

  const [userSN] = useDocument(userRef.doc(uid));
  const [delaySN] = useCollection(firebase.firestore().collectionGroup("delay"));

  const CurrentUid = auth().currentUser!.uid;
  const [currentUserSN] = useDocument(userRef.doc(CurrentUid));

  if (
    !vacationSN ||
    !userSN ||
    !projectSN ||
    !budgetLinesSN ||
    !allocationSN ||
    !usersSN ||
    !delaySN ||
    !officeSN ||
    !currentUserSN
  )
    return <Spin />;

  const USERS_ID_FULL_NAME = usersSN.docs
    .filter((e) => currentUserData.fullControlOffices.includes(e.data()?.office))
    .map((u) => {
      const fullName = u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName;
      const uid = u.id;
      return { uid, fullName };
    });

  const userData = userSN.data() as users;
  const fullName = userData.firstName + " " + userData.lastName;
  const position = userData.position;
  const location = officeSN?.docs.find((d) => d.id == userData.office)?.data().officeName;
  const missionData = globalMissionsData[globalOfficesData[userData.office].missionId];
  const missionWeekendDays = missionData.weekendDays;
  const publicHolidays = missionData.holidayDates;
  const supervisorName = `${usersSN.docs.find((d) => d.id == userData.managerUID)?.data().firstName} ${
    usersSN.docs.find((d) => d.id == userData.managerUID)?.data().lastName
  }`;
  const supervisorPosition = usersSN.docs.find((d) => d.id == userData.managerUID)?.data().position;

  const projectsArray = allocationSN.docs
    .filter((d) => {
      const startDate = d.data().startDate.toDate();
      return startDate < month.endOf("month");
    })
    .filter((d) => {
      const endDate = d.data().endDate.toDate();
      return month.startOf("month") < endDate;
    })
    .map((a) => {
      return {
        project: a.data().projectId,
        budgetLine: a.data().budgetLineId,
        percentage: a.data().percentage,
        idaPercentage: a.data().idaAllocationPercentage,
        startDate: a.data().startDate,
        endDate: a.data().endDate,
      };
    });
  const BEG =
    projectsArray.length == 1 && moment(projectsArray[0].startDate.toDate()).isAfter(month.startOf("month"), "day")
      ? moment(projectsArray[0].startDate.toDate()).format("DD.MM.YYYY")
      : month.startOf("month").format("DD.MM.YYYY");
  const END =
    projectsArray.length == 1 && moment(projectsArray[0].endDate.toDate()).isBefore(month.endOf("month"), "day")
      ? moment(projectsArray[0].endDate.toDate()).format("DD.MM.YYYY")
      : month.endOf("month").format("DD.MM.YYYY");

  const officeData = globalOfficesData[globalUsersData[uid].office];
  const officeStartHour = officeData.workStartTime.toDate();
  const officeEndHour = officeData.workEndTime.toDate();
  var a = moment(officeStartHour, "HH:mm:ss"); //now
  var b = moment(officeEndHour, "HH:mm:ss");

  const employmentType =
    globalUsersData[uid].employmentType == undefined ? "fullTime" : globalUsersData[uid].employmentType;
  const partTimeSchedule = globalUsersData[uid].partTimeSchedule;
  // console.log({ partTimeSchedule, employmentType });

  const idaAllocationPercentage = projectsArray.length == 0 ? 0 : projectsArray[0].idaPercentage;
  const fullDayMin = b.diff(a, "minutes"); // taken from start and end hours from OFFICE
  const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // calculating mins according to working-hours and percentage of allocation
  const halfDayMin = fullDayMin / 2; // half day time
  const halfDayPercentageMin = (halfDayMin * idaAllocationPercentage) / 100;
  const timeOutMin = a.diff(moment(officeStartHour).startOf("day"), "minutes") + fullDayPercentageMin;
  const timeOutMin9 = a.diff(moment(officeStartHour).startOf("day"), "minutes") + fullDayPercentageMin / 2;
  const timeOutMin13 =
    a.diff(moment(officeStartHour).startOf("day"), "minutes") + halfDayPercentageMin + fullDayPercentageMin / 2;

  const timeInMin9 = a.diff(moment(officeStartHour).startOf("day"), "minutes");
  const timeInMin13 = a.diff(moment(officeStartHour).startOf("day"), "minutes") + halfDayPercentageMin;

  const DelayDates = true
    ? delaySN.docs
        .filter((d) => d.data().userUID == uid)
        .filter((d) => {
          const delaysData = d?.data() as delay;
          return delaysData.status != "disabled";
        })
        .filter((d) => d.data()?.month == month.format("MMM.YYYY").toString())
        .map((d) => {
          return {
            userUID: d.data()?.userUID,
            day: d.data()?.dayDate,
            min: d.data()?.min,
            month: d.data()?.month, //TODO: add note
            notes: d.data()?.notes,
            dayDateFormat: moment(d.data()?.dayDate.toDate()).format("DD-MM-YYYY").toString(),
          };
        })
    : [
        {
          userUID: "",
          day: moment(),
          min: 0,
          month: "",
          notes: "",
          dayDateFormat: "",
        },
      ];

  const VacationDates = vacationSN.docs
    .filter((vacation) => vacation.data().status != "Rejected" && vacation.data().status != "Canceled")
    .map((d) => {
      return {
        start: d.data().vacationStartOn,
        end: d.data().vacationEndOn,
        duration: d.data().vocationPeriodDays,
        type: d.data().vacationType,
        halfDay: d.data().halfDay,
      };
    });
  function daysInMonth(m: any) {
    var count = moment().year(m.year()).month(m.month()).daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(moment().year(m.year()).month(m.month()).date(i));
    }
    return days;
  }
  var days = daysInMonth(month);

  const empPosition = currentP(uid, month);
  const supPostion = currentP(userData.managerUID, month);

  const daysTimeIn = days.map((r) => {
    let MINUTES_IN_DAY = 0;
    switch (employmentType) {
      case "fullTime":
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : timeInMin9;
        const v = VacationDates.map((v) => {
          const HalfDayTimeIn = v.halfDay == "evening" ? timeInMin9 : timeInMin13;
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeIn : 0;
          }
        });
        const d = DelayDates.map((d: any) => {
          const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
          if (
            moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
            !d.notes.toLowerCase().includes("last")
          ) {
            MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
          } else {
          }
        });
        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = 0;
          }
          if ((moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022" ||moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")) {
            const HalfDayTimeIn = timeInMin9;
            MINUTES_IN_DAY = HalfDayTimeIn;
          }
          VacationDates.map((vc) => {
            const HalfDayTimeIn = timeInMin13;
            const start = moment(vc.start.toDate()).startOf("day");
            const end = moment(vc.end.toDate()).endOf("day");


            if (
              (moment("08-07-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]")&& moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") ||
              ( moment("28-10-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]") &&moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")
            ) {
              MINUTES_IN_DAY = 0;
            }
          });
        });

        const projects = projectsArray.map((project) => {
          if (
            !moment(r.toDate()).isBetween(
              moment(project.startDate.toDate()).startOf("day"),
              moment(project.endDate.toDate()).endOf("day"),
              undefined,
              "[]"
            ) &&
            projectsArray.length == 1
          ) {
            MINUTES_IN_DAY = 0;
          }
        });

        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        // const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // TODO: to make it with percentage
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        // const halfDayPercentageMinPartTime = (halfDayMinPartTIme * idaAllocationPercentage) / 100; /// TODO: to make it with percentage
        const timeInMin13PartTime =
          partTimeStartHour.diff(moment(partTimeStartHour).startOf("day"), "minutes") + halfDayMinPartTIme;

        const timeInMinPartTime = partTimeDayDetails.status
          ? partTimeStartHour.diff(moment(partTimeDayDetails.start.toDate()).startOf("day"), "minutes")
          : 0;
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd"))
          ? 0
          : partTimeDayDetails.status
          ? timeInMinPartTime
          : 0;

        const v = VacationDates.map((v) => {
          const HalfDayTimeIn = v.halfDay == "evening" ? timeInMinPartTime : timeInMin13PartTime;
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeIn : 0;
          }
        });
        const d = DelayDates.map((d: any) => {
          const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
          if (
            moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
            !d.notes.toLowerCase().includes("last")
          ) {
            MINUTES_IN_DAY = MINUTES_IN_DAY + d.min;
          }
        });
        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = 0;
          }
          if ((moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022" ||moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")) {
            const HalfDayTimeIn = timeInMinPartTime;
            MINUTES_IN_DAY = HalfDayTimeIn;
          }
          VacationDates.map((vc) => {
            const start = moment(vc.start.toDate()).startOf("day");
            const end = moment(vc.end.toDate()).endOf("day");

            if (
              (moment("08-07-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]")&& moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") ||
              ( moment("28-10-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]") &&moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")
           ) {
              MINUTES_IN_DAY = 0;
            }
          });
        });

        const projects = projectsArray.map((project) => {
          if (
            !moment(r.toDate()).isBetween(
              moment(project.startDate.toDate()).startOf("day"),
              moment(project.endDate.toDate()).endOf("day"),
              undefined,
              "[]"
            ) &&
            projectsArray.length == 1
          ) {
            MINUTES_IN_DAY = 0;
          }
        });

        break;
      case "shift":
        MINUTES_IN_DAY = 0;
        break;

      default:
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : timeInMin9;
        break;
    }

    return { MINUTES_IN_DAY, r };
  });
  const daysTimeOut = days.map((r) => {
    let MINUTES_IN_DAY = 0;

    switch (employmentType) {
      case "fullTime":
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : timeOutMin;
        const v = VacationDates.map((v) => {
          const HalfDayTimeOut =
            v.halfDay == "evening" ? timeOutMin9 : v.halfDay == "morning" ? timeOutMin13 : timeOutMin;
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeOut : 0;
          }
        });
        const d = DelayDates.map((d: any) => {
          const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
          if (
            moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
            d.notes.toLowerCase().includes("last") &&
            MINUTES_IN_DAY != 0
          ) {
            MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
          }
        });

        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = 0;
          }

          if ((moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022" ||moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")) {
            const HalfDayTimeOut = timeOutMin9;
            MINUTES_IN_DAY = HalfDayTimeOut;
          }
          VacationDates.map((vc) => {
            const HalfDayTimeIn = timeInMin13;
            const start = moment(vc.start.toDate()).startOf("day");
            const end = moment(vc.end.toDate()).endOf("day");

            if (
              (moment("08-07-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]")&& moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") ||
              ( moment("28-10-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]") &&moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")
            ) {
              MINUTES_IN_DAY = 0;
            }
          });
        });

        const projects: any = projectsArray.map((project) => {
          if (
            !moment(r.toDate()).isBetween(
              moment(project.startDate.toDate()).startOf("day"),
              moment(project.endDate.toDate()).endOf("day"),
              undefined,
              "[]"
            ) &&
            projectsArray.length == 1
          ) {
            MINUTES_IN_DAY = 0;
          }
        });
        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        // const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // TODO: to make it with percentage
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        // const halfDayPercentageMinPartTime = (halfDayMinPartTIme * idaAllocationPercentage) / 100;// TODO: to make it with percentage

        const timeOutMin9PartTime =
          partTimeStartHour.diff(moment(partTimeStartHour).startOf("day"), "minutes") + fullDayMinPartTime / 2;
        const timeOutMin13PartTime =
          partTimeStartHour.diff(moment(partTimeStartHour).startOf("day"), "minutes") +
          halfDayMinPartTIme +
          fullDayMinPartTime / 2;

        const timeOutMinPartTime = partTimeDayDetails.status
          ? partTimeEndHour.diff(moment(partTimeDayDetails.end.toDate()).startOf("day"), "minutes")
          : 0;
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd"))
          ? 0
          : partTimeDayDetails.status
          ? timeOutMinPartTime
          : 0;

        const v = VacationDates.map((v) => {
          const HalfDayTimeOut =
            v.halfDay == "evening" ? timeOutMin9PartTime : v.halfDay == "morning" ? timeOutMin13PartTime : 0;
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.duration == 0.5 ? HalfDayTimeOut : 0;
          }
        });
        const d = DelayDates.map((d: any) => {
          const dayDate = moment(d.day.toDate()).format("DD-MM-YYYY").toString();
          if (
            moment(r.toDate()).format("DD-MM-YYYY").toString() == dayDate &&
            d.notes.toLowerCase().includes("last") &&
            MINUTES_IN_DAY != 0
          ) {
            MINUTES_IN_DAY = MINUTES_IN_DAY - d.min;
          }
        });

        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = 0;
          }

          if ((moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022" ||moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")) {
            const HalfDayTimeOut = timeOutMin9PartTime;
            MINUTES_IN_DAY = HalfDayTimeOut;
          }
          VacationDates.map((vc) => {
            const start = moment(vc.start.toDate()).startOf("day");
            const end = moment(vc.end.toDate()).endOf("day");

            if (
              (moment("08-07-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]")&& moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022") ||
              ( moment("28-10-2022", "DD-MM-YYYY").isBetween(start, end, undefined, "[]") &&moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")
            ) {
              MINUTES_IN_DAY = 0;
            }
          });
        });

        const projects = projectsArray.map((project) => {
          if (
            !moment(r.toDate()).isBetween(
              moment(project.startDate.toDate()).startOf("day"),
              moment(project.endDate.toDate()).endOf("day"),
              undefined,
              "[]"
            ) &&
            projectsArray.length == 1
          ) {
            MINUTES_IN_DAY = 0;
          }
        });

        break;
      case "shift":
        MINUTES_IN_DAY = 0;
        break;

      default:
        MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : timeOutMin;
        break;
    }

    return { MINUTES_IN_DAY, r };
  });
  const daysAnnual = days.map((r) => {
    let MINUTES_IN_DAY = 0;

    switch (employmentType) {
      case "fullTime":
        const v = VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Annual Leave" ? (v.duration == 0.5 ? halfDayPercentageMin : fullDayPercentageMin) : 0;
          }
        });

        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        // const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // TODO: to make it with percentage
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        // const halfDayPercentageMinPartTime = (halfDayMinPartTIme * idaAllocationPercentage) / 100;// TODO: to make it with percentage
        const v = VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Annual Leave" ? (v.duration == 0.5 ? halfDayMinPartTIme : fullDayMinPartTime) : 0;
          }
        });
        break;
      case "shift":
        break;
      default:
        MINUTES_IN_DAY = 0;
        break;
    }

    MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;

    return { MINUTES_IN_DAY, r };
  });
  const daysSick = days.map((r) => {
    let MINUTES_IN_DAY = 0;

    switch (employmentType) {
      case "fullTime":
        const v = VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Sick Leave" ? (v.duration == 0.5 ? halfDayPercentageMin : fullDayPercentageMin) : 0;
          }
        });

        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        // const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // TODO: to make it with percentage
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        // const halfDayPercentageMinPartTime = (halfDayMinPartTIme * idaAllocationPercentage) / 100;// TODO: to make it with percentage
        const v = VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY = v.type == "Sick Leave" ? (v.duration == 0.5 ? halfDayMinPartTIme : fullDayMinPartTime) : 0;
          }
        });
        break;
      case "shift":
        break;
      default:
        MINUTES_IN_DAY = 0;
        break;
    }

    MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;

    return { MINUTES_IN_DAY, r };
  });
  const daysOther = days.map((r) => {
    let MINUTES_IN_DAY = 0;
    switch (employmentType) {
      case "fullTime":
        const v = VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type !== "Sick Leave" &&
              v.type !== "Annual Leave" &&
              v.type !== "Unpaid Leave" &&
              v.type !== "Maternity Leave"
                ? v.duration == 0.5
                  ? halfDayPercentageMin
                  : fullDayPercentageMin
                : 0;
          }
        });

        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        // const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // TODO: to make it with percentage
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        // const halfDayPercentageMinPartTime = (halfDayMinPartTIme * idaAllocationPercentage) / 100;// TODO: to make it with percentage
        const v = VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type !== "Sick Leave" &&
              v.type !== "Annual Leave" &&
              v.type !== "Unpaid Leave" &&
              v.type !== "Maternity Leave"
                ? v.duration == 0.5
                  ? halfDayMinPartTIme
                  : fullDayMinPartTime
                : 0;
          }
        });
        break;
      case "shift":
        break;
      default:
        MINUTES_IN_DAY = 0;
        break;
    }

    MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;
    return { MINUTES_IN_DAY, r };
  });
  const daysPublicHolidays = days.map((r) => {
    //TODO:
    let MINUTES_IN_DAY = 0;
    switch (employmentType) {
      case "fullTime":
        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = fullDayPercentageMin;
          }
          if ((moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022" ||moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")) {
            MINUTES_IN_DAY = halfDayPercentageMin;
          }
        });
        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        // const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // TODO: to make it with percentage
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        // const halfDayPercentageMinPartTime = (halfDayMinPartTIme * idaAllocationPercentage) / 100;// TODO: to make it with percentage
        const h = publicHolidays.map((v) => {
          if (moment(r.toDate()).format("DD-MM-YYYY").toString() == v) {
            MINUTES_IN_DAY = fullDayMinPartTime;
          }
          if ((moment(r.toDate()).format("DD-MM-YYYY").toString() == "08-07-2022" ||moment(r.toDate()).format("DD-MM-YYYY").toString() == "28-10-2022")) {
            MINUTES_IN_DAY = halfDayMinPartTIme;
          }
        });
        break;
      case "shift":
        break;
      default:
        break;
    }

    MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;
    return { MINUTES_IN_DAY, r };
  });
  const daysUnpaid = days.map((r) => {
    let MINUTES_IN_DAY = 0;

    switch (employmentType) {
      case "fullTime":
        const v = VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Unpaid Leave" || v.type == "Maternity Leave"
                ? v.duration == 0.5
                  ? halfDayPercentageMin
                  : fullDayPercentageMin
                : 0;
          }
        });
        break;
      case "partTime":
        let partTimeDayDetails = partTimeSchedule[moment(r.toDate()).format("dddd").toLowerCase()];
        const partTimeStartHour = moment(partTimeDayDetails.start.toDate(), "HH:mm:ss");
        const partTimeEndHour = moment(partTimeDayDetails.end.toDate(), "HH:mm:ss");
        const fullDayMinPartTime = partTimeEndHour.diff(partTimeStartHour, "minutes");
        // const fullDayPercentageMin = (fullDayMin * idaAllocationPercentage) / 100; // TODO: to make it with percentage
        const halfDayMinPartTIme = fullDayMinPartTime / 2; // half day time
        // const halfDayPercentageMinPartTime = (halfDayMinPartTIme * idaAllocationPercentage) / 100;// TODO: to make it with percentage
        const v = VacationDates.map((v) => {
          const start = moment(v.start.toDate()).startOf("day");
          const end = moment(v.end.toDate()).endOf("day");

          if (moment(r.toDate()).isBetween(start, end, undefined, "[]")) {
            MINUTES_IN_DAY =
              v.type == "Unpaid Leave" || v.type == "Maternity Leave"
                ? v.duration == 0.5
                  ? halfDayMinPartTIme
                  : fullDayMinPartTime
                : 0;
          }
        });
        break;
      case "shift":
        break;

      default:
        break;
    }

    MINUTES_IN_DAY = missionWeekendDays.includes(moment(r.toDate()).format("dd")) ? 0 : MINUTES_IN_DAY;
    return { MINUTES_IN_DAY, r };
  });

  //======> Calculating Section of ALL leaves + Worked Hours <==========

  const TimeOut_Minus_TimeIn = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const timeOut = daysTimeOut.map((d) => d.MINUTES_IN_DAY);
  const timeIn = daysTimeIn.map((d) => d.MINUTES_IN_DAY);
  for (let i = 0; i < timeIn.length; i++) {
    TimeOut_Minus_TimeIn[i].num = timeOut[i] - timeIn[i];
  }

  const AnnalLeave_Plus_SickLeave = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const annal = daysAnnual.map((d) => d.MINUTES_IN_DAY);
  const sick = daysSick.map((d) => d.MINUTES_IN_DAY);
  for (let i = 0; i < timeIn.length; i++) {
    AnnalLeave_Plus_SickLeave[i].num = annal[i] + sick[i];
  }
  const OtherLeave_Plus_PublicHoliday = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const other = daysOther.map((d) => d.MINUTES_IN_DAY);
  const holidays = daysPublicHolidays.map((d) => d.MINUTES_IN_DAY);
  for (let i = 0; i < timeIn.length; i++) {
    OtherLeave_Plus_PublicHoliday[i].num = other[i] + holidays[i];
  }

  const Total_Annual_Sick_Other_Public = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const as = AnnalLeave_Plus_SickLeave.map((d) => d.num);
  const ot = OtherLeave_Plus_PublicHoliday.map((d) => d.num);
  for (let i = 0; i < timeIn.length; i++) {
    Total_Annual_Sick_Other_Public[i].num = ot[i] + as[i];
  }

  const TotalLeaves_Plus_WorkedHours = days.map((d) => {
    const num = 0;
    return { d, num };
  });
  const worked = TimeOut_Minus_TimeIn.map((d) => d.num);
  const leaves = Total_Annual_Sick_Other_Public.map((d) => d.num);
  for (let i = 0; i < timeIn.length; i++) {
    TotalLeaves_Plus_WorkedHours[i].num = worked[i] + leaves[i];
  }

  //======> End Calculating Section of ALL leaves + Worked Hours <==========

  const daysTotal = TotalLeaves_Plus_WorkedHours.map((d) => {
    let MINUTES_IN_DAY =
      moment(d.d.toDate()).format("dd") == missionWeekendDays[0] ||
      moment(d.d.toDate()).format("dd") == missionWeekendDays[1]
        ? 0
        : d.num;

    const v = VacationDates.map((v) => {
      const start = moment(v.start.toDate()).startOf("day");
      const end = moment(v.end.toDate()).endOf("day");

      if (moment(d.d.toDate()).isBetween(start, end, undefined, "[]")) {
        MINUTES_IN_DAY = v.type == "Unpaid Leave" ? 0 : d.num;
      }
    });
    const r = d.d;
    return { MINUTES_IN_DAY, r };
  });

  const totalCellsProject = projectsArray.map((project) => {
    const { startDate, endDate, percentage } = project;
    const start = moment(startDate.toDate()).startOf("day");
    const end = moment(endDate.toDate()).endOf("day");

    const arr = daysTotal.map((col) => {
      if (moment(col.r.toDate()).isBetween(start, end, undefined, "[]")) {
        let MINUTES_IN_DAY = (col.MINUTES_IN_DAY * percentage) / 100;
        return MINUTES_IN_DAY;
      } else {
        return 0;
      }
    });
    return arr;
  });

  const newTotalCell = days.map((d) => 0);
  for (let i = 0; i < totalCellsProject.length; i++) {
    for (let j = 0; j < newTotalCell.length; j++) {
      //0 to 2
      newTotalCell[j] = newTotalCell[j] + totalCellsProject[i][j];
    }
  }

  const TOTAL_PROJECT_CELL = _.sum(newTotalCell);
  const hours = _.sumBy(daysTotal, (d) => d.MINUTES_IN_DAY);
  const percentage = _.sumBy(projectsArray, (d) => d.percentage);
  const totalWorkedHours = _.sumBy(TimeOut_Minus_TimeIn, (d) => d.num);
  const totalAnnualHours = _.sumBy(daysAnnual, (d) => d.MINUTES_IN_DAY);
  const totalSickHours = _.sumBy(daysSick, (d) => d.MINUTES_IN_DAY);
  const totalOtherHours = _.sumBy(daysOther, (d) => d.MINUTES_IN_DAY);
  const totalPublicHours = _.sumBy(daysPublicHolidays, (d) => d.MINUTES_IN_DAY);
  const totalUnpaidHours = _.sumBy(daysUnpaid, (d) => d.MINUTES_IN_DAY);

  const print = async () => {
    await printJS({
      header: "Time-Sheet",
      printable: "timeSheet",
      type: "html",
      targetStyles: ["*"],
      scanStyles: true,
      font_size: "15",
      documentTitle: `aiu.ida-org.com/print-timeSheet/${uid}/true/${moment(month).format("MM-YYYY")}`,
      showModal: true,
    });
  };
  return (
    <div>
      <MonthPicker
        size="large"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      {currentUserData.adminHR || currentUserData.admin ? (
        <Select
          showSearch
          style={{ width: "20%", paddingBottom: "1%" }}
          value={USERS_ID_FULL_NAME.find((d) => d.uid == uid)?.fullName}
          onChange={(e: string) => {
            const id = USERS_ID_FULL_NAME.find((d) => d.fullName == e)?.uid as string;
            setUid(id);
          }}
        >
          {USERS_ID_FULL_NAME?.map((d) => {
            return <Option value={d.fullName}>{d.fullName}</Option>;
          })}
        </Select>
      ) : null}
      {/* {currentUserData.adminHR || currentUserData.admin ? (
        <Button style={{ marginLeft: "1%" }} type="danger" onClick={() => setIsOriginal(!isOriginal)}>
          {isOriginal ? "With No Delays" : "Delays"}
        </Button>
      ) : null} */}
      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        Print
      </Button>
      <div id="timeSheet">
        <table style={{ width: "100%" }}>
          <tr className={"row"}>
            <th colSpan={9} className={"logos"}>
              <img style={{ width: "150px", marginBottom: "3%", marginTop: "3%", marginLeft: "5%" }} src={logo} />
            </th>
            <th colSpan={13} className={"textField"}>
              <h2>IDA</h2>
              <h4>{month.format("MMM.YYYY")}</h4>
              <h5>TIMESHEET</h5>
            </th>
            <th colSpan={13} className={"logos"}>
              <img
                style={{ width: "150px", marginBottom: "3%", marginTop: "3%", marginRight: "5%", float: "right" }}
                src={logo}
              />
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={1} className={"titleText"}>
              <h4>Name</h4>
            </th>
            <th colSpan={11} className={"inputText"}>
              <h4>{fullName}</h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <h4>Position</h4>
            </th>
            <th colSpan={15} className={"inputText"}>
              <h4>{empPosition == "" ? position : empPosition}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={1} rowSpan={2} className={"titleText"}>
              <h4>Month</h4>
            </th>
            <th colSpan={1} className={"inputText"}>
              <h4>BEG</h4>
            </th>
            <th colSpan={10} className={"inputText"}>
              <h4>{BEG}</h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <h4>Location</h4>
            </th>
            <th colSpan={15} className={"inputText"}>
              <h4>{location}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={1} className={"inputText"}>
              <h4>END</h4>
            </th>
            <th colSpan={10} className={"inputText"}>
              <h4>{END}</h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <h4>Supervisor's Name</h4>
            </th>
            <th colSpan={6} className={"inputText"}>
              <h4>{supervisorName}</h4>
            </th>
            <th colSpan={3} className={"titleText"}>
              <h4>Title</h4>
            </th>
            <th colSpan={7} className={"inputText"}>
              <h4>{supPostion == "" ? supervisorPosition : supPostion}</h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={1} className={"titleText"}>
              <h4>Employee's Signature and Date</h4>
            </th>
            <th colSpan={11} className={"inputText"}>
              <h4></h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <h4>Supervisor's Signature and Date</h4>
            </th>
            <th colSpan={15} className={"inputText"}>
              <h4></h4>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Days</h4>
            </th>
            {days.map((col) => (
              <th
                className={"cell"}
                style={{
                  background:
                    col.format("dd").toString() == missionWeekendDays[0] ||
                    col.format("dd").toString() == missionWeekendDays[1]
                      ? "yellow"
                      : "#9e9e9e6b",
                }}
              >
                <h5>{col.format("dd")}</h5>
              </th>
            ))}
            <th colSpan={3} className={"inputText"} style={{ background: "#fff" }}>
              <h5>Total</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Date</h4>
            </th>
            {days.map((col) => (
              <th
                className={"cell"}
                style={{
                  background:
                    col.format("dd").toString() == missionWeekendDays[0] ||
                    col.format("dd").toString() == missionWeekendDays[1]
                      ? "yellow"
                      : "#9e9e9e6b",
                }}
              >
                <h5>{col.format("DD-MMM")}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Time-in</h4>
            </th>
            {daysTimeIn.map((col) => (
              <th
                className={"cell"}
                style={{
                  background: col.MINUTES_IN_DAY == 0 ? "yellow" : "none",
                }}
              >
                <h5>{convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Time-out</h4>
            </th>
            {daysTimeOut.map((col) => (
              <th
                className={"cell"}
                style={{
                  background: col.MINUTES_IN_DAY == 0 ? "yellow" : "none",
                }}
              >
                <h5>{convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5></h5>
            </th>
          </tr>

          <tr className={"row"}>
            <th colSpan={3} className={"titleText"}>
              <h4>Worked-Hours</h4>
            </th>
            {TimeOut_Minus_TimeIn.map((d) => (
              <th
                className={"cell"}
                style={{
                  background: d.num == 0 ? "yellow" : "none",
                }}
              >
                <h5>{convertToHoursMin(d.num)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(totalWorkedHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={1} rowSpan={5} className={"titleText"} style={{ background: "#9e9e9e6b" }}>
              Leave
            </th>

            <th colSpan={2} className={"titleText"}>
              <h5>Annual-Leave</h5>
            </th>
            {daysAnnual.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalAnnualHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={2} className={"titleText"}>
              <h5>Sick-Leave</h5>
            </th>
            {daysSick.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalSickHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={2} className={"titleText"}>
              <h5>Other-Leave</h5>
            </th>
            {daysOther.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalOtherHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={2} className={"titleText"}>
              <h5>Public-Holiday</h5>
            </th>
            {daysPublicHolidays.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalPublicHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th colSpan={2} className={"titleText"}>
              <h5>Unpaid-Leave</h5>
            </th>
            {daysUnpaid.map((col) => (
              <th className={"cell"}>
                <h5>{col.MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5>{convertToHoursMin(totalUnpaidHours)}</h5>
            </th>
          </tr>
          <tr className={"row"}>
            <th
              colSpan={3}
              style={{
                border: "1px solid black",
                width: "25%",
                padding: "5px",
                background: "#fff",
                textAlign: "center",
              }}
            >
              <h4>Total</h4>
            </th>
            {daysTotal.map((col) => (
              <th className={"cell"}>
                <h5>{convertToHoursMin(col.MINUTES_IN_DAY)}</h5>
              </th>
            ))}
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(hours)}</h5>
            </th>
          </tr>
          <tr>
            <th colSpan={5} className={"titleText"}>
              <h4>Hours Allocation</h4>
            </th>
            <th colSpan={15} className={"titleText"}>
              <h4>Notes</h4>
            </th>
            <th colSpan={15} className={"titleText"}>
              <h4>Human Resources Department/Checked and Verified By:</h4>
            </th>
          </tr>
          <tr>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>Projects</h6>
            </th>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>budgetLine</h6>
            </th>
            <th className={"titleText"} style={{ width: "5%" }}>
              <h6>Contracting Percentage</h6>
            </th>
            <th className={"titleText"} style={{ width: "4%" }}>
              <h6>Allocation Percentage</h6>
            </th>
            <th colSpan={1} className={"titleText"}>
              <h6>Worked Hours</h6>
            </th>
            <th colSpan={15} rowSpan={2 + projectsArray.length} className={"cell"} style={{ width: "5%" }}></th>
            <th colSpan={15} rowSpan={2 + projectsArray.length} className={"cell"} style={{ width: "5%" }}></th>
            {/* {days.map((col) => (
              <th className={"cellEmpty"}>
                
              </th>
            ))} */}
          </tr>
          {projectsArray.map((p: any) => {
            // console.log(moment(p.endDate.toDate()).isAfter(month))
            const start = moment(p.startDate.toDate()).startOf("day");
            const end = moment(p.endDate.toDate()).endOf("day");
            const totalHoursProject = _.sumBy(daysTotal, (d: any) => {
              if (moment(d.r.toDate()).isBetween(start, end, undefined, "[]")) {
                return (d.MINUTES_IN_DAY * p.percentage) / 100;
              } else {
                return 0;
              }
            });
            return (
              <tr>
                <th className={"projectInput"}>
                  <h5>{projectSN.docs.find((d) => d.id == p.project)?.data().projectCode}</h5>
                </th>
                <th className={"projectInput"}>
                  <h5>{budgetLinesSN.docs.find((d) => d.id == p.budgetLine)?.data().budgetLine}</h5>
                </th>
                <th className={"projectInput"}>
                  <h5>{p.idaPercentage + "%"}</h5>
                </th>
                <th className={"projectInput"}>
                  <h5>{p.percentage + "%"}</h5>
                </th>
                {/* {daysTotal.map((col) => {
                  if (moment(col.r.toDate()).isBetween(start, end, undefined, "[]")) {
                    let MINUTES_IN_DAY = (col.MINUTES_IN_DAY * p.percentage) / 100;

                    return (
                      <th className={"cell"}>
                        <h5>{MINUTES_IN_DAY == 0 ? "" : convertToHoursMin(MINUTES_IN_DAY)}</h5>
                      </th>
                    );
                  } else {
                    return (
                      <th className={"cell"}>
                        <h5></h5>
                      </th>
                    );
                  }
                })} */}
                <th colSpan={1} className={"inputText"} style={{ background: "#fff" }}>
                  <h5> {convertToHours(totalHoursProject)}</h5>
                </th>
              </tr>
            );
          })}
          <tr>
            <th colSpan={2} className={"inputText"} style={{ background: "#fff" }}>
              <h5>TOTAL HOURS ALLOCATED</h5>
            </th>
            <th className={"inputText"} style={{ background: "#fff" }}></th>

            {/* {newTotalCell.map((min) => {
              return (
                <th className={"cell"}>
                  <h5>{convertToHoursMin(min)}</h5>
                </th>
              );
            })} */}
            <th className={"projectInput"} style={{ background: "#fff" }}>
              <h5>{percentage + "%"}</h5>
            </th>
            <th className={"inputText"} style={{ background: "#fff" }}>
              <h5> {convertToHours(TOTAL_PROJECT_CELL)}</h5>
            </th>
          </tr>

          {/* <tr className={"row"}>
            <th colSpan={12} className={"inputText"}>
              <h4></h4>
            </th>
            <th colSpan={8} className={"titleText"}>
              <br />

              <h4>Human Resources Department</h4>
              <h4>Checked and Verified By:</h4>
              <br />
            </th>
            <th colSpan={15} className={"inputText"}>
              <h4></h4>
            </th>
          </tr> */}
        </table>
        <br />
      </div>
    </div>
  );
}
