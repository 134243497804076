import moment from "moment";
import { salaries } from "./userGlobal";

export const currentSalary = (uid: string, date: any) => {
  const salary =
    salaries
      .filter((d: any) =>
        moment(date.toDate()).isBetween(
          moment(d.startDate.toDate()).startOf("day"),
          moment(d.endDate.toDate()).endOf("day"),
          undefined,
          "[]"
        )
      )
      .find((d: any) => d.uid == uid)?.salary ?? "";
  return salary;
};
