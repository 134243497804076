import React, { useState, useEffect } from "react";
import { Button, DatePicker, Drawer, Form, message, Select, Input, Divider } from "antd";
import moment from "moment";
const { Option } = Select;
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import { userRef, caseRef, officeRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { Case } from "../../db/interfaces";
import { globalUsersData } from "../../global/usersList";
import { globalOfficesData } from "../../global/officeList";
import TextArea from "antd/lib/input/TextArea";
import { currentUserData } from "../../db/userGlobal";
import Discussion from "../../discussion/discussion";

interface Props {
  caseDoc: Case;
  modalState: boolean;
  uid?: string;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditCase(props: Props) {
  const { t } = useTranslation();
  const { caseDoc, modalState, setModalState, uid } = props;
  const [loading, setLoading] = useState(false);
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);
  const [caseSN] = useDocument(caseRef.doc(caseDoc.id));
  const caseData = caseSN?.data() as Case;
  const OFFICES = officeSN?.docs.map((u) => {
    const officeName = u.data().officeName;
    const id = u.id;
    return { id, officeName };
  });

  const USERS_ID_FULL_NAME = usersSN?.docs.map((u) => {
    const fullName = u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName;
    const uid = u.id;
    return { uid, fullName };
  });

  const formik = useFormik({
    initialValues: {
      caseDate: moment(),
      caseNO: "",
      region: "",
      officeId: "",
      findings: "",
      assignedTo: "",
      finalResoluation: "",
      status: "",
    },
    onSubmit: async (values) => {
      const promise = caseRef.doc(caseDoc.id).update({
        caseDate: values.caseDate.toDate() as any,
        caseNO: values.caseNO,
        region: values.region,
        officeId: values.officeId,
        cacheOffice: globalOfficesData[values.officeId].officeName,
        findings: values.findings,
        assignedTo: values.assignedTo,
        confirmedBy: values.status == "confirmed" ? uid : "",
        notConfirmedBy: values.status == "notConfirmed" ? uid : "",
        cacheAssignedTo: globalUsersData[values.assignedTo].firstName + globalUsersData[values.assignedTo].lastName,
        finalResoluation: values.finalResoluation ?? "",
        status: values.status,
        receivedDate: values.status == "received" ? (moment().toDate() as any) : caseData.receivedDate? caseData.receivedDate?.toDate() : null,
        inProgressDate: values.status == "inProgress" ? (moment().toDate() as any) : caseData.inProgressDate? caseData.inProgressDate?.toDate() : null,
        solvedDate: values.status == "solved" ? (moment().toDate() as any) :  caseData.solvedDate? caseData.solvedDate?.toDate() : null,
        confirmedDate:
          values.status == "confirmed" ? (moment().toDate() as any) :  caseData.confirmedDate? caseData.confirmedDate?.toDate() : null,
        notConfirmedDate:
          values.status == "notConfirmed" ? (moment().toDate() as any) :  caseData.notConfirmedDate? caseData.notConfirmedDate?.toDate() : null,
        suspendedDate:
          values.status == "suspended" ? (moment().toDate() as any) :  caseData.suspendedDate? caseData.suspendedDate?.toDate() : null,
      });

      promise.then(() => {
        setModalState(false), setLoading(false);
      });
      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (caseSN && loaded) {
      formik.setValues({
        caseNO: caseData.caseNO,
        region: caseData.region,
        officeId: caseData.officeId,
        findings: caseData.findings,
        assignedTo: caseData.assignedTo,
        finalResoluation: caseData.finalResoluation,
        caseDate: moment(caseData.caseDate.toDate()),
        status: caseData.status,
      } as any);
      setLoaded(false);
    }
  }, [caseSN, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Drawer
      title={
        caseDoc.id

        // t("general.caseView")
      }
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit} {...formItemLayout}>
        <Form.Item label={t("general.caseDate")}>
          <DatePicker
            disabled={!currentUserData.permissions.cases.includes("admin")}
            value={formik.values.caseDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("caseDate", e)}
          />
        </Form.Item>
        <Form.Item label={t("general.caseNO")}>
          <Input
            disabled={!currentUserData.permissions.cases.includes("admin")}
            value={formik.values.caseNO}
            onChange={(e) => formik.setFieldValue("caseNO", e.target.value)}
          />
        </Form.Item>

        <Form.Item label={t("general.assignedTo")}>
          <Select
            disabled={!currentUserData.permissions.cases.includes("admin")}
            showSearch
            value={USERS_ID_FULL_NAME?.find((d) => d.uid == formik.values.assignedTo)?.fullName}
            onChange={(e) => {
              const id = USERS_ID_FULL_NAME?.find((d) => d.fullName == e)?.uid;
              formik.setFieldValue("assignedTo", id);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.fullName}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.office")}>
          <Select
            disabled={!currentUserData.permissions.cases.includes("admin")}
            showSearch
            value={OFFICES?.find((d) => d.id == formik.values.officeId)?.officeName}
            onChange={(e) => {
              const id = OFFICES?.find((d) => d.officeName == e)?.id;
              formik.setFieldValue("officeId", id);
            }}
          >
            {OFFICES?.map((d) => {
              return <Option value={d.officeName}>{d.officeName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.status")}>
          <Select
            disabled={
              !(currentUserData.uid == caseDoc.assignedTo || currentUserData.permissions.cases.includes("admin"))
            }
            showSearch
            value={formik.values.status}
            onChange={(e) => {
              formik.setFieldValue("status", e);
            }}
          >
            <Option value={"received"}>{"Received"}</Option>
            <Option value={"inProgress"}>{"In Progress"}</Option>
            <Option value={"solved"}>{"Solved"}</Option>
            <Option value={"confirmed"}>{"Confirmed"}</Option>
            <Option value={"notConfirmed"}>{"Not Confirmed"}</Option>
            <Option value={"suspended"}>{"Suspended"}</Option>
          </Select>
        </Form.Item>

        <Form.Item label={t("general.findings")}>
          <TextArea
            disabled={!currentUserData.permissions.cases.includes("admin")}
            value={formik.values.findings}
            onChange={(e) => formik.setFieldValue("findings", e.target.value)}
          />
        </Form.Item>

        <Form.Item label={t("general.region")}>
          <Input
            disabled={!currentUserData.permissions.cases.includes("admin")}
            value={formik.values.region}
            onChange={(e) => formik.setFieldValue("region", e.target.value)}
          />
        </Form.Item>

        <Form.Item label={t("general.Resoluation")}>
          <TextArea
            disabled={
              !(currentUserData.uid == caseDoc.assignedTo || currentUserData.permissions.cases.includes("admin"))
            }
            value={formik.values.finalResoluation}
            onChange={(e) => formik.setFieldValue("finalResoluation", e.target.value)}
          />
        </Form.Item>

        <div>
          <Form.Item {...tailFormItemLayout}>
            <Button
              className="btn blue lighten-1 z-depth-0"
              onClick={() => {
                enterLoading();
              }}
              loading={loading}
              type="primary"
              htmlType="submit"
              disabled={!formik.isValid}
            >
              {t("general.save")}
            </Button>
          </Form.Item>
        </div>
      </Form>

      <Divider />
      <Discussion userId={["any"]} orderId={caseDoc?.id} type={"case"} officeId={caseDoc?.officeId} />
    </Drawer>
  );
}

export default EditCase;
