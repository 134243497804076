import moment from "moment";
import { positions } from "./userGlobal";

export const currentP = (uid: string, date: any) => {
   
  const position = positions
    .filter((d: any) =>
      moment(date.toDate()).isBetween(
        moment(d.startDate.toDate()).startOf("day"),
        moment(d.endDate.toDate()).endOf("day"),
        undefined,
        "[]"
      )
    )
    .find((d: any) => d.uid == uid)?.position ?? "";
return position
  };
