import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, Popconfirm, Select } from "antd";
const { Option } = Select;

import saveAs from "file-saver";
import { userRef, projectsRef, budgetLinesRef, officeRef, positionRef } from "../../db/collectionsRef";
import { Link } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AddNewPosition from "./AddNewPositionModel";
import EditPosition from "./EditPosition";
import { currentUserData, currentPosition } from "../../db/userGlobal";
import DevTable from "../../helpers/devex-table";

export default function PositionsTable() {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [positionId, setPositionId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [userUID, setUserUID] = useState("");

  const [positionSN] = useCollection(positionRef);
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);

  if (!positionSN || !usersSN || !officeSN) return <Spin />;

  const data = positionSN.docs.map(d => {
    return { ...d.data(), id: d.id };
  });

  const columns = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.uid)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.uid)?.data()?.lastName
        }`
    },

    {
      name: "position",
      title: "Position"
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY")
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              positionRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      }
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setPositionId(row.id);
              setUserUID(row.uid);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      }
    }
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.uid)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.uid)?.data()?.lastName
        }`
    },

    {
      name: "position",
      title: "Position"
    },
    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY")
    }
  ];

  return (
    <div>
      <AddNewPosition modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditPosition
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          positionId={positionId}
          uid={userUID}
        />
      ) : null}{" "}
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewPosition")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Positions"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
