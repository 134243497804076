import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase, { firestore, auth, User } from "firebase/app";
import { Spin, Button, Select, DatePicker, Checkbox, Icon, InputNumber } from "antd";
const { Option } = Select;
import UsersInformation from "../models/usersInformation";
import {
  officeRef,
  projectRef,
  allocationRef,
  budgetLinesRef,
  vacationRef,
  deductionRef,
  missionRef,
  accountantRef,
} from "../../db/collectionsRef";
import { currentUserData } from "../../db/userGlobal";
import { sortBy, sumBy, uniq, uniqBy } from "lodash";
import NewUser from "../models/newUser";
import moment from "moment";
const { MonthPicker } = DatePicker;
import DevTable from "../../helpers/devex-table";
import { allocation, deduction, users, access, project, mission, accountant } from "../../db/interfaces";
import { currentSalary } from "../../db/currentSalary";
import { currentP } from "../../db/currentPosition";
import { globalOfficesData } from "../../global/officeList";
import { CheckBox } from "@material-ui/icons";
import PaySlipToPrint from "./paySlipToPrint";
import { globalUsersData } from "../../global/usersList";
import { globalProjectsData } from "../../global/projectList";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function PayslipByOffice() {
  const [projectId, setProjectId] = useState("")
  const [accountantId, setAccountantId] = useState("");
  const [paymentDate, setPaymentDate] = useState(moment());
  const [row, setRow] = useState({} as any);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [filteredPosition, setFilteredPosition] = useState([] as string[]);
  const [paymentOffice, setPaymentOffice] = useState();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [month, setMonth] = useState(moment());
  const [fingerPrintActive, setFingerPrintActive] = useState(true);
  const [officeId, setOfficeId] = useState(currentUserData.office);
  const [modalState, setModalState] = useState(false);
  const [modalStateNewUser, setModalStateNewUser] = useState(false);
  const [defaultColumnWidths] = useState([
    { columnName: "id", width: 150 },
    { columnName: "index", width: 150 },
    { columnName: "resourceNO", width: 150 },
    { columnName: "firstName", width: 150 },
    { columnName: "lastName", width: 150 },
    { columnName: "position", width: 150 },
    { columnName: "workingDayInMonth", width: 150 },
    { columnName: "deduction", width: 150 },
    { columnName: "leaveWithoutPay", width: 250 },
    { columnName: "absentDays", width: 150 },
    { columnName: "daysWorked", width: 150 },
    { columnName: "monthlySalary", width: 250 },
    { columnName: "numberOfHoursLate", width: 250 },
    { columnName: "hoursLateDeduction", width: 250 },
    { columnName: "totalSalaryForCurrentMonth", width: 250 },
    { columnName: "difference", width: 150 },
    { columnName: "donor", width: 150 },
    { columnName: "project", width: 150 },
    { columnName: "budgetLineId", width: 150 },
    { columnName: "percentage", width: 150 },
    { columnName: "allocatedSalary", width: 200 },
    { columnName: "print", width: 200 },
    { columnName: "idaAllocationPercentage", width: 200 },
    { columnName: "contractSalary", width: 200 },
  ]);

  const [accountantsSN] = useCollection(accountantRef);
  const [allocationsSN] = useCollection(allocationRef);
  const [AllUsers] = useCollection(usersRef.where("office", "==", officeId));
  const [officeSN] = useCollection(officeRef);
  const [projectSN] = useCollection(projectRef);
  const [missionSn] = useCollection(missionRef);
  const [budgetLineSN] = useCollection(budgetLinesRef.orderBy("createdAt"));
  const [vacationSN] = useCollection(
    vacationRef
      .where("vacationStartOn", "<=", month.endOf("month").toDate())
      .where("vacationStartOn", ">=", month.startOf("month").toDate())
  );
  const [deductionSN] = useCollection(deductionRef.where("month", "==", month.format("MM-YYYY").toString()));

  const [accessSN] = useCollection(
    db
      .collectionGroup("access")
      .where("date", "<=", month.endOf("month").toDate())
      .where("date", ">=", month.startOf("month").toDate())
  );
  const [delaySN] = useCollection(
    db.collectionGroup("delay").where("month", "==", month.format("MMM.YYYY").toString())
  );

  const print2CbRef = useRef<() => void>();
  const printPayslip = useCallback(() => {
    setTimeout(() => {
      print2CbRef.current();
    }, 1500);
  }, []);
  const printCb = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .pagebreak {
          page-break-before:always;
        }
        .tg {
          border-collapse: collapse;
          border-spacing: 0;
        }
        .tg td {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg th {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg .tg-jqmv {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-z4i2 {
          border-color: #ffffff;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-w7c0 {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-zv4m {
          border-color: #ffffff;
          text-align: left;
          vertical-align: top;
        }
        .tg .tg-pgku {
          border-color: #ffffff;
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-9ksr {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u6id {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-u6id1 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-fmc2 {
          border-color: #ffffff;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-lh7k {
          border-color: #ffffff;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-sl9l {
          border-color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2urc {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-top-width: 3px;
          border-right-width: 3px;
        
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ui32 {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd0 {
          border-color: black;
          border-top-width: 3px;
        
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ai0l {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-f3so {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-f3sox {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-9o1m {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2776 {
          border-color: black;
          border-width: 1px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776x {
          border: none;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776xy {
          border-color: black;
          border-width: 0;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27762 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27760 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-277601 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-u9r8 {
          border-color: black;
          border-top-width: 3px;
        
          border-width: 1px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r80 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r81 {
          border-color: black;
          border-width: 1px;
          border-right-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r82 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r83 {
          border-color: black;
          border-top-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-mlkz {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-oedm {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-0326 {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: right;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-ttyt {
          border-color: #ffffff;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-ttytx {
          border-color: black;
          border-width:0 ;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-8srf {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        
        
        </style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`multiplePage`);
  }, []);
  print2CbRef.current = printCb;

  const print3CbRef = useRef<() => void>();
  const printPayslipPerRow = useCallback(() => {
    setTimeout(() => {
      print3CbRef.current();
    }, 1500);
  }, []);
  const printPerRow = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .pagebreak {
          page-break-before:always;
        }
        .tg {
          border-collapse: collapse;
          border-spacing: 0;
        }
        .tg td {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg th {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg .tg-jqmv {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-z4i2 {
          border-color: #ffffff;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-w7c0 {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-zv4m {
          border-color: #ffffff;
          text-align: left;
          vertical-align: top;
        }
        .tg .tg-pgku {
          border-color: #ffffff;
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-9ksr {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u6id {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-u6id1 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-fmc2 {
          border-color: #ffffff;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-lh7k {
          border-color: #ffffff;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-sl9l {
          border-color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2urc {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-top-width: 3px;
          border-right-width: 3px;
        
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ui32 {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd0 {
          border-color: black;
          border-top-width: 3px;
        
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ai0l {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-f3so {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-f3sox {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-9o1m {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2776 {
          border-color: black;
          border-width: 1px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776x {
          border: none;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776xy {
          border-color: black;
          border-width: 0;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27762 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27760 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-277601 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-u9r8 {
          border-color: black;
          border-top-width: 3px;
        
          border-width: 1px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r80 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r81 {
          border-color: black;
          border-width: 1px;
          border-right-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r82 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r83 {
          border-color: black;
          border-top-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-mlkz {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-oedm {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-0326 {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: right;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-ttyt {
          border-color: #ffffff;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-ttytx {
          border-color: black;
          border-width:0 ;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-8srf {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        
        
        </style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`perRow`);
  }, []);
  print3CbRef.current = printPerRow;

  if (
    !missionSn ||
    !accountantsSN ||
    !delaySN ||
    !accessSN ||
    !deductionSN ||
    !vacationSN ||
    !budgetLineSN ||
    !allocationsSN ||
    !projectSN ||
    !AllUsers ||
    !officeSN ||
    !currentUserData.fullControlOffices
  )
    return <Spin />;
  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  const accountants = accountantsSN.docs.map((m) => {
    return { ...m.data(), id: m.id } as accountant;
  });
  const delays = delaySN.docs
    .map((d) => {
      return { ...d.data(), id: d.id, uid: d.data().userUID, min: d.data().min, status: d.data().status };
    })
    .filter((d) => d.status != "disabled");

  const VacationDates = vacationSN.docs
    .filter((vacation) => vacation.data().status != "Rejected" && vacation.data().status != "Canceled")
    .map((d) => {
      return {
        ...d.data(),
        employeeUID: d.data().employeeUID,
        start: d.data().vacationStartOn,
        end: d.data().vacationEndOn,
        duration: d.data().vocationPeriodDays,
        type: d.data().vacationType,
        halfDay: d.data().halfDay,
      };
    });
  function daysInMonth(m: any) {
    var count = moment().year(m.year()).month(m.month()).daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(moment().year(m.year()).month(m.month()).date(i));
    }
    return days;
  }
  var days = daysInMonth(month);
  const deductionData = deductionSN.docs.map((d) => {
    return { ...d.data(), id: d.id } as deduction;
  });
  const projectList = projectSN?.docs.map((p) => {
    return { id: p.id, projectCode: p?.data()?.projectCode, donor: p?.data().donor } as project;
  });

  const allocationsUids = allocationsSN?.docs
    .filter((d) => {
      const startDate = d.data().startDate.toDate();
      return startDate < month.endOf("month");
    })
    .filter((d) => {
      const endDate = d.data().endDate.toDate();
      return month.startOf("month") < endDate;
    })
    .map((al) => {
      return { ...al.data(), id: al.id } as allocation;
    });

  const users = AllUsers?.docs
    .filter((d) => currentUserData.fullControlOffices.includes(d.data()?.office))
    .filter((d) => uniq(allocationsUids).filter((i) => i.uid == d.id).length > 0)
    .map((d) => {
      return {
        ...d.data(),
        id: d.id,
      };
    });

  const userAllocation = allocationsUids
    .filter((a) => {
      const user = users.find((u: any) => u.id == a.uid);
      return user ? true : false;
    })
    .map((a: any) => {
      const user = users.find((u: any) => u.id == a.uid);
      return { ...a, ...user, id: a.id };
    });
  const access = accessSN.docs?.map((a) => {
    return { ...a.data(), id: a.id } as access;
  });

  const absentDaysArr = userAllocation.map((u: any) => {
    const absentDays = days
      .map((day) => {
        let absent = false;

        const accessInDay = access
          .filter((a) => a.uid == u.id)
          .filter((a) => {
            return (
              moment(a.date.toDate()).format("DD-MM-YYYY").toString() == moment(day).format("DD-MM-YYYY").toString()
            );
          }).length;

        const vacationsFiltered = VacationDates.filter((v) => v.employeeUID == u.id).filter((vacation: any) => {
          const vacationStartOn = moment(vacation?.vacationStartOn?.toDate()).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn?.toDate()).endOf("day");

          return moment(day).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
        }).length;

        if (vacationsFiltered == 0 && accessInDay == 0) {
          absent = true;
        }

        return { absent, day };
      })
      .filter((ab) => ab.absent == true).length;

    return { uid: u.uid, absentDays };
  });

  const data = sortBy(
    userAllocation.map((row: any) => {
      const id = row.id;
      const missionId = globalOfficesData[row.office].missionId;
      const position = currentP(row.uid, month) == "" ? row.position : currentP(row.uid, month);

      const workingDayInMonth = days.length;

      const deduction = deductionData.find((d) => d.uid == row.uid)?.percentage ?? 0;

      const leaveWithoutPay = VacationDates.filter((v) => v.employeeUID == row.uid && v.type == "Unpaid Leave").length;

      const absentDays = (fingerPrintActive ? absentDaysArr.find((ab) => ab.uid == row.uid)?.absentDays : 0) as number;
      const daysWorked =
        days.length -
        absentDays * 2 -
        VacationDates.filter((v) => v.employeeUID == row.uid && v.type == "Unpaid Leave").length;

      const monthlySalary = currentSalary(row.uid, month) == "" ? row.salary : currentSalary(row.uid, month);

      const contractSalary = (monthlySalary * row.idaAllocationPercentage) / 100;

      ///
      // const salary = currentSalary(row.uid, month) == "" ? row.salary : currentSalary(row.uid, month);

      const delaysMin = sumBy(
        delays.filter((d) => d.uid == row.uid),
        (d) => d.min
      );

      const delaysHours = delaysMin >= 120 ? delaysMin / 60 : 0;
      const numberOfHoursLate = delaysHours.toFixed(2);
      const salaryInHour = contractSalary / days.length / 8;
      const deductionByHour = salaryInHour * (delaysHours * 2);
      const hoursLateDeduction = deductionByHour.toFixed(3);
      ///
      const salaryInDay = contractSalary / days.length;
      const deductionPercentage = deductionData.find((d) => d.uid == row.uid)?.percentage ?? 0;
      const salaryAfterDeduction =
        salaryInDay * daysWorked - deductionByHour - (deductionPercentage * contractSalary * row.percentage) / 10000;
      const totalSalaryForCurrentMonth = parseInt(salaryAfterDeduction.toFixed(3));
      ///
      const differenceNumber = contractSalary - salaryAfterDeduction;
      const difference = parseInt(differenceNumber.toFixed(3));
      ///
      const donor = projectList.find((p) => p.id == row.projectId)?.donor;
      const project = projectList.find((p) => p.id == row.projectId)?.projectCode;
      const budgetLineId = budgetLineSN.docs.find((d) => d.id == row.budgetLineId)?.data().budgetLine;
      const percentage = row.percentage + " " + "%";
      const allocatedSalaryNumber = (row.percentage * salaryAfterDeduction) / 100;
      const allocatedSalary = parseInt(allocatedSalaryNumber.toFixed(3));
      const allocations = allocationsUids.filter((a) => a.uid == row.uid);
      return {
        ...row,
        position,
        workingDayInMonth,
        deduction,
        leaveWithoutPay,
        absentDays,
        daysWorked,
        monthlySalary,
        numberOfHoursLate,
        hoursLateDeduction,
        totalSalaryForCurrentMonth,
        salaryAfterDeduction,
        difference,
        differenceNumber,
        donor,
        project,
        budgetLineId,
        percentage,
        missionId,
        allocatedSalary,
        allocatedSalaryNumber,
        id,
        allocations,
        contractSalary,
      };
    }),
    ["firstName", "lastName"]
  ).map((d, index) => {
    return { ...d, index: index + 1 };
  });
  const positions = uniq(data.map((d) => d.position));
  const tableData = data
    .filter((d: any) => {
      if (filteredPosition.length == 0) {
        return true;
      } else {
        if (filteredPosition.includes(d.position)) {
          return true;
        } else {
          return false;
        }
      }
    })
    .filter((d) => {
      if (paymentMethod == "") {
        return true;
      } else {
        return d.paymentType == paymentMethod;
      }
    })
    .filter((d) => {
      if (projectId == "") {
        return true;
      } else {
        return d.projectId == projectId;
      }
    });

  const columns = [
    { name: "id", title: "id" },
    { name: "index", title: "NO" },
    { name: "resourceNO", title: "Resource NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "workingDayInMonth",
      title: "Work days in a month",
    },
    {
      name: "deduction",
      title: "Deduction",
    },
    {
      name: "leaveWithoutPay",
      title: "Leave Without Pay",
    },
    {
      name: "absentDays",
      title: "Absent Days",
    },
    {
      name: "daysWorked",
      title: "Days worked",
    },
    {
      name: "monthlySalary",
      title: "Monthly salary ( TL/ USD )",
    },
    {
      name: "idaAllocationPercentage",
      title: "Contract Percentage",
    },
    {
      name: "contractSalary",
      title: "Contract Salary",
    },
    {
      name: "numberOfHoursLate",
      title: "Number of Hours Late",
    },
    {
      name: "hoursLateDeduction",
      title: "Hours Late Deduction",
    },
    {
      name: "totalSalaryForCurrentMonth",
      title: "Total Salary for current month",
    },
    {
      name: "difference",
      title: "Difference",
    },
    {
      name: "donor",
      title: "Donor",
    },
    {
      name: "project",
      title: "Project",
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
    },
    {
      name: "percentage",
      title: "Percentage",
    },
    {
      name: "allocatedSalary",
      title: "Allocated Salary",
    },
    {
      name: "print",
      title: "Print",
      getCellValue: (r) => {
        return (
          <Button
            onClick={() => {
              setRow(r);
              printPayslipPerRow();
            }}
          >
            Payslip
          </Button>
        );
      },
    },
  ];
  const exportColumns=[
    { name: "id", title: "id" },
    { name: "index", title: "NO" },
    { name: "resourceNO", title: "Resource NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "workingDayInMonth",
      title: "Work days in a month",
    },
    {
      name: "deduction",
      title: "Deduction",
    },
    {
      name: "leaveWithoutPay",
      title: "Leave Without Pay",
    },
    {
      name: "absentDays",
      title: "Absent Days",
    },
    {
      name: "daysWorked",
      title: "Days worked",
    },
    {
      name: "monthlySalary",
      title: "Monthly salary ( TL/ USD )",
    },
    {
      name: "idaAllocationPercentage",
      title: "Contract Percentage",
    },
    {
      name: "contractSalary",
      title: "Contract Salary",
    },
    {
      name: "numberOfHoursLate",
      title: "Number of Hours Late",
    },
    {
      name: "hoursLateDeduction",
      title: "Hours Late Deduction",
    },
    {
      name: "totalSalaryForCurrentMonth",
      title: "Total Salary for current month",
    },
    {
      name: "difference",
      title: "Difference",
    },
    {
      name: "donor",
      title: "Donor",
    },
    {
      name: "project",
      title: "Project",
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
    },
    {
      name: "percentage",
      title: "Percentage",
    },
    {
      name: "allocatedSalary",
      title: "Allocated Salary",
    },
  ]

  return (
    <div>
      {modalState ? <UsersInformation modalState={modalState} setModalState={setModalState} UID={UID} /> : null}
      {modalStateNewUser ? <NewUser modalState={modalStateNewUser} setModalState={setModalStateNewUser} /> : null}
      <MonthPicker
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      
      <Select
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={
          [...UserListOffices, { officeName: "All", id: "notYet" }].find((office) => office.id == officeId)?.officeName
        }
        onChange={(e) => {
          const id = [...UserListOffices, { officeName: "All", id: "notYet" }].find((office) => office.officeName == e)
            ?.id as string;
          setOfficeId(id);
        }}
      >
        {[...UserListOffices, { officeName: "All", id: "notYet" }].map((d) => {
          return <Option value={d.officeName}>{d.officeName}</Option>;
        })}
      </Select>
      <Select
        size="default"
        placeholder="Search for project!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={globalProjectsData[projectId]?.projectCode}
        onChange={(e) => {
          const id = projectList.find((p) => p.projectCode == e)?.id as string;
          setProjectId(id);
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.projectCode}>{d.projectCode}</Option>;
        })}
      </Select>
      <Select
        placeholder="Filter for Positions!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        mode="multiple"
        showSearch
        value={filteredPosition}
        onChange={(e: any) => {
          setFilteredPosition(e);
        }}
      >
        {positions?.map((d) => {
          return (
            <Option key={d} value={d}>
              {d}
            </Option>
          );
        })}
      </Select>
      <div>
        Payment Office:
        <Select
          placeholder="Payment Office"
          style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
          showSearch
          value={paymentOffice}
          onChange={(e: any) => {
            setPaymentOffice(e);
          }}
        >
          <Option key={"HQ"} value={"HQ"}>
            {"HQ"}
          </Option>
          <Option key={"AZAZ"} value={"AZAZ"}>
            {"AZAZ"}
          </Option>
          <Option key={"Sarmada"} value={"Sarmada"}>
            {"Sarmada"}
          </Option>
          <Option key={"Ghandoura"} value={"Ghandoura"}>
            {"Ghandoura"}
          </Option>
          <Option key={"Telabiad"} value={"Telabiad"}>
            {"Telabiad"}
          </Option>
        </Select>
        Payment Method:
        <Select
          placeholder="Payment Method"
          style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
          showSearch
          value={paymentMethod}
          onChange={(e: any) => {
            setPaymentMethod(e);
          }}
        >
          <Option key={"Cash"} value={"cash"}>
            {"Cash"}
          </Option>
          <Option key={"Transfer"} value={"transfer"}>
            {"Transfer"}
          </Option>
          <Option key={"all"} value={""}>
            {"All"}
          </Option>
        </Select>
        Payment Date:
        <DatePicker
          size="default"
          style={{ marginBottom: "1%", marginRight: "1%" }}
          placeholder="Select month"
          value={paymentDate}
          format={"DD-MM-YYYY"}
          onChange={(e) => setPaymentDate(moment(e))}
        />
        Accountant:
        <Select
          size="default"
          placeholder="Search for Accountant!"
          style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
          showSearch
          value={globalUsersData[accountantId] && globalUsersData[accountantId].fullName}
          onChange={(e) => {
            setAccountantId(e);
          }}
        >
          {accountants.map((d) => {
            return <Option value={d.uid}>{globalUsersData[d.uid]?.fullName}</Option>;
          })}
        </Select>
      </div>
      <div>
        Exchange Rate:
        <InputNumber
          style={{ marginBottom: "1%", marginRight: "1%", marginLeft: "1%", width: "15%" }}
          value={exchangeRate}
          onChange={(e) => setExchangeRate(e)}
          placeholder={"Exchange Rate"}
        />
        <Checkbox
          checked={fingerPrintActive}
          onChange={(e) => {
            setFingerPrintActive(e.target.checked);
          }}
        >
          Finger-print Activation
        </Checkbox>
        <Button
          size="large"
          icon="print"
          type="primary"
          onClick={() => printPayslip()}
          style={{ marginBottom: "1%", marginRight: "1%" }}
        >
          <Icon type="print" />
          Payslip
        </Button>
      </div>
      <Paper>
        <DevTable
          data={tableData}
          columns={columns}
          defaultHiddenColumnNames={["id"]}
          defaultColumnWidths={defaultColumnWidths}
          tableName={`Payslip-List`}
          selected={false}
          typicalTable={false}
          columnsExport={exportColumns}
          summaryColumn={true}
          totalItems={[
            { columnName: "allocatedSalary", type: "sum" },
            { columnName: "difference", type: "sum" },
            { columnName: "totalSalaryForCurrentMonth", type: "sum" },
          ]}
        />
      </Paper>

      <div id={"multiplePage"} style={{ display: "none" }}>
        {uniqBy(tableData, (u) => u.uid).map((row, index) => (
          <>
            <PaySlipToPrint
              index={index + 1}
              row={row}
              key={index}
              printFnRef={print2CbRef as any}
              month={month}
              days={days}
              paymentOffice={paymentOffice as any}
              exchangeRate={exchangeRate}
              paymentDate={paymentDate}
              accountantId={accountantId}
            />
            <div className="pagebreak" style={{ pageBreakBefore: "always" }} />
          </>
        ))}
      </div>
      {row.allocations ? (
        <div id={"perRow"} style={{ display: "none" }}>
          <>
            <PaySlipToPrint
              index={1}
              row={row}
              key={1}
              printFnRef={print3CbRef as any}
              month={month}
              days={days}
              paymentOffice={paymentOffice as any}
              exchangeRate={exchangeRate}
              paymentDate={paymentDate}
              accountantId={accountantId}
            />
          </>
        </div>
      ) : null}
    </div>
  );
}
