import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col,
  Switch,
  TimePicker,
  Checkbox,
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
const { Option } = Select;
import { difference } from "lodash";

import {
  notificationsRef,
  userRef,
  orderRef,
  meetingRoomList,
  projectsRef,
  officeRef,
  missionRef,
} from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { mission, office } from "../../db/interfaces";
import { globalMissionsData } from "../../global/missionList";

interface Props {
  office: any;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function ManagePermissions(props: Props) {
  const { t } = useTranslation();

  const { modalState, setModalState, office } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const newId = projectsRef.doc().id;
  const uid = auth().currentUser!.uid;
  const [usersSN] = useCollection(userRef);
  const [missionsSN] = useCollection(missionRef);

  const [officeDoc] = useDocument(officeRef.doc(office.id));
  const officeData = officeDoc?.data() as office;
  const USERS_ID_FULL_NAME = usersSN?.docs.map((u) => {
    const fullName = u.data().firstName + " " + u.data().lastName;
    const uid = u.id;
    return { uid, fullName };
  });
  const MISSIONS = missionsSN?.docs.map((u) => {
    return { ...u.data(), id: u.id } as mission;
  });
  const formik = useFormik({
    initialValues: {
      officeName: "",
      location: "",
      admins: [],
      HRs: [],
      approvalCEO: false,
      workStartTime: moment(),
      workEndTime: moment(),
      region: "",
      contactPersonUID: "",
      cacheMissionName: "",
      missionId: "",
      restrictedFeatures: [],
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = officeRef.doc(office.id).set(
        {
          officeName: values.officeName,
          location: values.location,
          admins: values.admins,
          HRs: values.HRs,
          approvalCEO: values.approvalCEO,
          workStartTime: values.workStartTime.toDate() as any,
          workEndTime: values.workEndTime.toDate() as any,
          region: values.region,
          contactPersonUID: values.contactPersonUID,
          missionId: values.missionId,
          cacheMissionName: globalMissionsData[values.missionId].missionName,
          restrictedFeatures: values.restrictedFeatures ?? [],
        },
        { merge: true }
      );
      promise
        .then(() => {
          values.admins.forEach(
            async (uid) =>
              await userRef.doc(uid).update({
                adminOffices: firebase.firestore.FieldValue.arrayUnion(office.id),
              })
          );
        })
        .then(() => {
          values.HRs.forEach(
            async (uid) =>
              await userRef.doc(uid).update({
                HROffices: firebase.firestore.FieldValue.arrayUnion(office.id),
              })
          );
        });

      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });

      promise
        .then(() => {
          const removedUsers = difference(officeData.admins, values.admins);
          removedUsers.forEach(async (uid) => {
            await userRef.doc(uid).update({
              adminOffices: firebase.firestore.FieldValue.arrayRemove(office.id),
            });
          });
        })
        .then(() => {
          const removedUsers = difference(officeData.HRs, values.HRs);
          removedUsers.forEach(async (uid) => {
            await userRef.doc(uid).update({
              HROffices: firebase.firestore.FieldValue.arrayRemove(office.id),
            });
          });
        });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (officeDoc && loaded) {
      formik.setValues({
        officeName: officeData.officeName,
        location: officeData.location,
        admins: officeData.admins,
        HRs: officeData.HRs,
        approvalCEO: officeData.approvalCEO,
        workStartTime: officeData.workStartTime && moment(officeData.workStartTime.toDate()),
        workEndTime: officeData.workEndTime && moment(officeData.workEndTime.toDate()),
        region: officeData.region,
        contactPersonUID: officeData.contactPersonUID,
        missionId: officeData.missionId,
        cacheMissionName: officeData.cacheMissionName,
        restrictedFeatures: officeData.restrictedFeatures,
      } as any);
      setLoaded(false);
    }
  }, [officeDoc, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const format = "hh:mm a";

  return (
    <Drawer
      title={t("general.officePermissions")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.officeName")}>
          <Input
            value={formik.values.officeName}
            onChange={(e) => formik.setFieldValue("officeName", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.location")}>
          <Input value={formik.values.location} onChange={(e) => formik.setFieldValue("location", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.admins")}>
          <Select
            mode="multiple"
            showSearch
            value={formik.values.admins?.map((id: string) => USERS_ID_FULL_NAME?.find((d) => d.uid == id)?.fullName)}
            onChange={(e: any) => {
              const uids = e.map((name: string) => {
                const id = USERS_ID_FULL_NAME?.find((d) => d.fullName == name)?.uid;
                return id;
              });
              formik.setFieldValue("admins", uids);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.fullName}>
                  {d.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.HRs")}>
          <Select
            mode="multiple"
            showSearch
            value={formik.values.HRs.map((id: string) => USERS_ID_FULL_NAME?.find((d) => d.uid == id)?.fullName)}
            onChange={(e: any) => {
              const uids = e.map((name: string) => {
                const id = USERS_ID_FULL_NAME?.find((d) => d.fullName == name)?.uid;
                return id;
              });
              formik.setFieldValue("HRs", uids);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.fullName}>
                  {d.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.startWork")}>
          <TimePicker
            use12Hours
            format={format}
            value={formik.values.workStartTime}
            onChange={(e) => formik.setFieldValue("workStartTime", e)}
          />
        </Form.Item>
        <Form.Item label={t("general.endWork")}>
          <TimePicker
            use12Hours
            format={format}
            value={formik.values.workEndTime}
            onChange={(e) => formik.setFieldValue("workEndTime", e)}
          />
        </Form.Item>
        <Form.Item labelAlign="right" label={t("general.approvalCEO")}>
          <Switch checked={formik.values.approvalCEO} onChange={(e) => formik.setFieldValue("approvalCEO", e)} />
        </Form.Item>

        <Form.Item label={t("general.contactPerson")}>
          <Select
            // mode="multiple"
            showSearch
            value={USERS_ID_FULL_NAME?.find((d) => d.uid == formik.values.contactPersonUID)?.fullName}
            onChange={(e: any) => {
              const id = USERS_ID_FULL_NAME?.find((d) => d.fullName == e)?.uid;

              formik.setFieldValue("contactPersonUID", id);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.fullName}>
                  {d.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.region")}>
          <Input value={formik.values.region} onChange={(e) => formik.setFieldValue("region", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.mission")}>
          <Select
            showSearch
            value={globalMissionsData[formik.values.missionId]?.missionName}
            onChange={(e: any) => {
              formik.setFieldValue("missionId", e);
            }}
          >
            {MISSIONS?.map((d) => {
              return (
                <Option key={d.id} value={d.id}>
                  {d.missionName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("Restricted Features")}>
          <Checkbox.Group
            disabled={false}
            onChange={(d) => formik.setFieldValue("restrictedFeatures", d)}
            value={formik.values.restrictedFeatures ?? []}
          >
            <Row>
              <Checkbox value="vacations">Vacations</Checkbox>
              <Checkbox value="overtime">Overtime</Checkbox>
              <Checkbox value="delays">Delays</Checkbox>
              <Checkbox value="cars">Cars</Checkbox>
              <Checkbox value="tasks">Tasks</Checkbox>
              <Checkbox value="workPermit">Work Permit</Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default ManagePermissions;
