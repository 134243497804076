import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { Spin, Select, Button, DatePicker } from "antd";
const { Option } = Select;
import { userRef, vacationRef, officeRef } from "../../db/collectionsRef";
import { useHistory } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import { useTranslation } from "react-i18next";
import { sortBy, uniq } from "lodash";
import DateRange from "@material-ui/icons/DateRange";
import moment from "moment";
import { currentUserData } from "../../db/userGlobal";
import DevTable from "../../helpers/devex-table";
const { MonthPicker } = DatePicker;

export default function VacationsTable() {
  const [month, setMonth] = useState(moment());
  const [officeId, setOfficeId] = useState(currentUserData.office);
  const history = useHistory();
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [defaultHiddenColumnNames] = useState(["vacationAnnualScore", "vacationSickScore"]);
  const storage = firebase.storage();
 
  const [vacationSN] = useCollection(
    vacationRef
      .where("office", "==", officeId)
      .where("vacationStartOn", "<=", month.endOf("month").toDate())
      .where("vacationStartOn", ">=", month.startOf("month").toDate())
  );
  const [officeSN] = useCollection(officeRef);

  const [usersSN] = useCollection(userRef);
  if (!usersSN || !vacationSN || !officeSN || !currentUserData.fullControlOffices) return <Spin />;
  const sortedData = sortBy( vacationSN?.docs?.map((d, index) => {
    return {
      ...d.data(),
      id: d.id,
      index: index + 1,
      employeeUID: d.data()?.employeeUID,
      managerUID: d.data().managerUID,
      office: d.data().office,
      requestedAt: d.data().requestedAt,
    };
  }), item => item.requestedAt).reverse();
  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];

  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map(id => {
    const officeName = officeSN?.docs?.find(d => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });


  const userPermissionData =
    currentUserData.admin == true || currentUserData.adminHR == true || currentUserData.CEO == true
      ? sortedData.filter(
          (e:any) => currentUserData.fullControlOffices.includes(e.office) || e.employeeUID == uid || e.managerUID == uid
        )
      : sortedData.filter((e:any) => e.employeeUID == uid || e.managerUID == uid);

  const columns = [
    {
      name: "index",
      title: "NO"
    },
    {
      name: "vacationType",
      title: "Type"
    },
    {
      name: "vocationPeriodDays",
      title: "Days"
    },

    {
      name: "employeeUID",
      title: "Full Name",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.employeeUID)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.employeeUID)?.data()?.lastName
        }`
    },
    {
      name: "position",
      title: "Position"
    },
    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.managerUID)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.managerUID)?.data()?.lastName
        }`
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => officeSN.docs?.find(d => d.id == row.office)?.data()?.officeName
    },
    {
      name: "status",
      title: "Status"
    },
    {
      name: "vacationStartOn",
      title: "Vacation Start",
      getCellValue: (row: any) => moment(row.vacationStartOn.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "vacationEndOn",
      title: "Vacation End",
      getCellValue: (row: any) => moment(row.vacationEndOn.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "startWorkOn",
      title: "Start Work",
      getCellValue: (row: any) => moment(row.startWorkOn.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "vacationAnnualScore",
      title: "Annual Score"
    },
    {
      name: "vacationSickScore",
      title: "Sick Score"
    },
    {
      name: "RFLURL",
      title: "RFL",
      getCellValue: row => {
        return row.RFLURL == "" || row.RFLURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              const U = storage.refFromURL(row.RFLURL);
              U.getDownloadURL().then(url => {
                var link = document.createElement("a");

                if (link.download !== undefined) {
                  link.setAttribute("href", url);
                  link.setAttribute("target", "_blank");
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              });
            }}
          >
            Download
          </Button>
        );
      }
    },
    {
      name: "archivedRFL",
      title: "Archived RFL",
      getCellValue: row => {
        return row.archivedRFL == "" || row.archivedRFL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              const U = storage.refFromURL(row.archivedRFL);
              U.getDownloadURL().then(url => {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                  link.setAttribute("href", url);
                  link.setAttribute("target", "_blank");
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  console.log(
                    encodeURIComponent(
                      "https://firebasestorage.googleapis.com/v0/b/administrationapp-cdc55.appspot.com/o/kGtYGGmUJGM63RmUhKBNHhCrHWB2%2Fc5371b07-67d5-4666-8494-f5e29f33e8de.jpg?alt=media&token=f5e2ee1b-73b5-41b7-a971-0b59e838d0a9"
                    )
                  );
                  link.click();
                  document.body.removeChild(link);
                }
              });
            }}
          >
            Download
          </Button>
        );
      }
    },
    {
      name: "more",
      title: "More Information",
      getCellValue: (row: any) => {
        return <Button onClick={() => history.push(`/vacation/${row.id}`)}> {t("general.view")}</Button>;
      }
    }
  ];
  const columnsExport = [
    {
      name: "index",
      title: "NO"
    },
    {
      name: "vacationType",
      title: "Type"
    },
    {
      name: "vocationPeriodDays",
      title: "Days"
    },

    {
      name: "employeeUID",
      title: "Full Name",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.employeeUID)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.employeeUID)?.data()?.lastName
        }`
    },
    {
      name: "position",
      title: "Position"
    },
    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.managerUID)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.managerUID)?.data()?.lastName
        }`
    },
    {
      name: "office",
      title: "Location"
    },
    {
      name: "status",
      title: "Status"
    },
    {
      name: "vacationStartOn",
      title: "Vacation Start",
      getCellValue: (row: any) => moment(row.vacationStartOn.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "vacationEndOn",
      title: "Vacation End",
      getCellValue: (row: any) => moment(row.vacationEndOn.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "startWorkOn",
      title: "Start Work",
      getCellValue: (row: any) => moment(row.startWorkOn.toDate()).format("DD.MM.YYYY")
    },
    {
      name: "vacationAnnualScore",
      title: "Annual Score"
    },
    {
      name: "vacationSickScore",
      title: "Sick Score"
    },
    {
      name: "RFLURL",
      title: "RFL"
    },
    {
      name: "archivedRFL",
      title: "Archived RFL"
    }
  ];

  return (
    <div>
      <div style={{ backgroundColor: "#fff", padding: "1%", marginBottom: "1%", border: "white", borderRadius: "4px" }}>
        <b>
          <h3>Vacations Table Details</h3>
        </b>
      </div>
      <MonthPicker
        size="default"
        style={{ margin: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MMMM-YYYY"}
        onChange={e => setMonth(moment(e))}
      />
      {currentUserData.admin || currentUserData.adminHR || currentUserData.CEO ? (
        <Select
          size="default"
          placeholder="Search for Office!"
          style={{ width: "25%" }}
          showSearch
          value={officeId}
          onChange={e => {
            setOfficeId(e);
          }}
        >
          {UserListOffices.map(d => {
            return <Option value={d.id}>{d.officeName}</Option>;
          })}
        </Select>
      ) : null}
      <div>
        <Paper>
          <DevTable
            data={userPermissionData}
            columns={columns}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            tableName="Vacations_List"
            selected={false}
            typicalTable={false}
            columnsExport={columnsExport}
          />
        </Paper>
      </div>
    </div>
  );
}
