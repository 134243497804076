import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, DatePicker, Popconfirm, Divider, Row, Col, Statistic, Select } from "antd";
const { TabPane } = Tabs;
import { caseRef, officeRef, userRef } from "../../db/collectionsRef";
import { auth } from "firebase/app";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Case } from "../../db/interfaces";
import MediaViewDialog from "../mediaDialogs/mediaViewDialog";
import DevTable from "../../helpers/devex-table";
import EditCase from "./EditCase";
import { currentUserData } from "../../db/userGlobal";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
  BarChart,
  CartesianGrid,
  XAxis,
  Bar,
  Legend,
  YAxis,
} from "recharts";
import { groupBy } from "lodash";
import { map } from "lodash";
import TableDialog from "./subTableDialog";
import TableBarDialog from "./subTableBarDialog";
const { Option } = Select;

export default function CasesTable() {
  const startOfToday = moment();
  const endOfToday = moment();
  const [startDate, setStartDate] = useState(startOfToday.startOf("month"));
  const [endDate, setEndDate] = useState(endOfToday.endOf("month"));
  const [loading, setLoading] = useState(false);
  const [assignedTos, setAssignedTos] = useState([] as string[]);
  const [officeIds, setOfficeIds] = useState([] as string[]);
  const [status, setStatus] = useState([] as string[]);
  const [key, setKey] = useState("1");
  const [modalState, setModalState] = useState(false);
  const [modalStateCase, setModalStateCase] = useState(false);
  const [modalStateSubTable, setModalStateSubTable] = useState(false);
  const [modalStateSubTableBar, setModalStateSubTableBar] = useState(false);

  const [caseDoc, setCaseDoc] = useState({} as Case);

  //states for subTable dialog
  const [statusSub, setStatusSub] = useState("");
  const [officeSub, setOfficeSub] = useState("");
  const [assignedToSub, setAssignedToSub] = useState("");
  const [barFilters, setBarFilters] = useState({ status: "", office: "", assignedTo: "" });
  //============
  const [url, setUrl] = useState("");
  const [attachType, setAttachType] = useState("");
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [defaultColumnWidths] = useState([
    { columnName: "caseDate", width: 150 },
    { columnName: "caseNO", width: 150 },
    { columnName: "cacheConductedBy", width: 150 },
    { columnName: "cacheOffice", width: 150 },
    { columnName: "region", width: 150 },
    { columnName: "findings", width: 350 },
    { columnName: "cacheAssignedTo", width: 150 },
    { columnName: "finalResoluation", width: 350 },
    { columnName: "status", width: 150 },
    { columnName: "submittedDate", width: 150 },
    { columnName: "receivedDate", width: 150 },
    { columnName: "inProgressDate", width: 150 },
    { columnName: "solvedDate", width: 150 },
    { columnName: "confirmedDate", width: 150 },
    { columnName: "notConfirmedDate", width: 150 },
    { columnName: "suspendedDate", width: 150 },
    { columnName: "cacheConfirmedBy", width: 150 },
    { columnName: "cacheNotConfirmedBy", width: 150 },
    { columnName: "cacheSuspendedBy", width: 150 },
    { columnName: "attach", width: 150 },
    { columnName: "attachV", width: 150 },
    { columnName: "edit", width: 150 },
  ]);
  const [defaultHiddenColumnNames] = useState([
    "submittedDate",
    "receivedDate",
    "inProgressDate",
    "solvedDate",
    "confirmedDate",
    "notConfirmedDate",
    "suspendedDate",
    "cacheConfirmedBy",
    "cacheNotConfirmedBy",
    "cacheSuspendedBy",
  ]);

  const [caseSN] = useCollection(
    caseRef.where("createdAt", ">=", startDate.toDate()).where("createdAt", "<=", endDate.toDate())
  );
  const [officeSN] = useCollection(officeRef);
  const [usersSN] = useCollection(userRef);

  if (!caseSN || !officeSN || !usersSN) return <Spin />;

  const data = caseSN.docs.map((d) => {
    return { ...d.data(), id: d.id };
  });

  const USERS_ID_FULL_NAME = usersSN?.docs.map((u) => {
    const fullName = u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName;
    const uid = u.id;
    return { uid, fullName };
  });
  const officeDataMap = officeSN?.docs.map((u) => {
    const officeName = u.data().officeName;
    const id = u.id;
    return { id, officeName };
  });
  const columnsExport = [
    {
      name: "caseDate",
      title: "Case Date",

      getCellValue: (row: any) => {
        return row.caseDate ? moment(row.caseDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    { name: "caseNO", title: "Case No." },
    { name: "cacheConductedBy", title: "Conducted By" },
    { name: "cacheOffice", title: "Office Name" },
    { name: "region", title: "Region" },
    { name: "findings", title: "Findings" },
    { name: "cacheAssignedTo", title: "Assigned To" },
    { name: "finalResoluation", title: "Final Resoluation" },
    { name: "status", title: "Status" },

    {
      name: "submittedDate",
      title: "Submitted Date",
      getCellValue: (row: Case) => {
        return row.submittedDate ? moment(row.submittedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "receivedDate",
      title: "Received Date",
      getCellValue: (row: Case) => {
        return row.receivedDate ? moment(row.receivedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "inProgressDate",
      title: "In-Progress Date",
      getCellValue: (row: Case) => {
        return row.inProgressDate ? moment(row.inProgressDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "solvedDate",
      title: "Solved Date",
      getCellValue: (row: Case) => {
        return row.solvedDate ? moment(row.solvedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "confirmedDate",
      title: "Confirmed Date",
      getCellValue: (row: Case) => {
        return row.confirmedDate ? moment(row.confirmedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "notConfirmedDate",
      title: "Not-Confirmed Date",
      getCellValue: (row: Case) => {
        return row.notConfirmedDate ? moment(row.notConfirmedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "suspendedDate",
      title: "Suspended Date",
      getCellValue: (row: Case) => {
        return row.suspendedDate ? moment(row.suspendedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    { name: "cacheConfirmedBy", title: "Confirmed By" },
    { name: "cacheNotConfirmedBy", title: "Not-Confirmed B" },
    { name: "cacheSuspendedBy", title: "Suspended Name" },
    {
      name: "attachments",
      title: "Attachments",
      getCellValue: (row: Case) => {
        return row.attachments?.map((a) => a.url);
      },
    },
  ];

  const columns = [
    {
      name: "caseDate",
      title: "Case Date",

      getCellValue: (row: Case) => {
        return row.caseDate ? moment(row.caseDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    { name: "caseNO", title: "Case No." },
    { name: "cacheConductedBy", title: "Conducted By" },
    { name: "cacheOffice", title: "Office Name" },
    { name: "region", title: "Region" },
    { name: "findings", title: "Findings" },
    { name: "cacheAssignedTo", title: "Assigned To" },
    { name: "finalResoluation", title: "Final Resoluation" },
    { name: "status", title: "Status" },

    {
      name: "submittedDate",
      title: "Submitted Date",
      getCellValue: (row: Case) => {
        return row.submittedDate ? moment(row.submittedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "receivedDate",
      title: "Received Date",
      getCellValue: (row: Case) => {
        return row.receivedDate ? moment(row.receivedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "inProgressDate",
      title: "In-Progress Date",
      getCellValue: (row: Case) => {
        return row.inProgressDate ? moment(row.inProgressDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "solvedDate",
      title: "Solved Date",
      getCellValue: (row: Case) => {
        return row.solvedDate ? moment(row.solvedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "confirmedDate",
      title: "Confirmed Date",
      getCellValue: (row: Case) => {
        return row.confirmedDate ? moment(row.confirmedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "notConfirmedDate",
      title: "Not-Confirmed Date",
      getCellValue: (row: Case) => {
        return row.notConfirmedDate ? moment(row.notConfirmedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "suspendedDate",
      title: "Suspended Date",
      getCellValue: (row: Case) => {
        return row.suspendedDate ? moment(row.suspendedDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    { name: "cacheConfirmedBy", title: "Confirmed By" },
    { name: "cacheNotConfirmedBy", title: "Not-Confirmed B" },
    { name: "cacheSuspendedBy", title: "Suspended Name" },
    {
      name: "attach",
      title: "Images",
      getCellValue: (row: Case) => {
        return row.attachments
          ?.filter((obj) => obj.type == "image")
          .map((imgObj, index) => (
            <>
              <Button
                type="link"
                onClick={() => {
                  setAttachType("image");
                  setUrl(imgObj.url);
                  setModalState(true);
                }}
              >{`Image-${index + 1}`}</Button>
              <br />
            </>
          ));
      },
    },
    {
      name: "attachV",
      title: "Videos",
      getCellValue: (row: Case) => {
        return row.attachments
          ?.filter((obj) => obj.type == "video")
          .map((vidObj, index) => (
            <>
              <Button
                type="link"
                onClick={() => {
                  setAttachType("video");
                  setUrl(vidObj.url);
                  setModalState(true);
                }}
              >{`Video-${index + 1}`}</Button>
              <br />
            </>
          ));
      },
    },
    {
      name: "edit",
      title: "edit",
      getCellValue: (row: any) => {
        return (
          <>
            <Button
              type="primary"
              onClick={() => {
                setCaseDoc(row);
                setModalStateCase(true);
              }}
            >
              Edit
            </Button>
            {currentUserData.permissions.cases.includes("admin") ? (
              <Popconfirm
                title={t("general.areUSure")}
                onConfirm={() => {
                  caseRef.doc(row.id).delete();
                }}
                onCancel={() => console.log("cancel")}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            ) : null}
          </>
        );
      },
    },
  ];
  const filteredDataStatus = data.filter((d: any) => {
    if (status.length == 0) return d;
    else return status.includes(d.status);
  });
  const filteredDataOffice = filteredDataStatus.filter((d: any) => {
    if (officeIds.length == 0) return d;
    else return officeIds.includes(d.officeId);
  });
  const filteredDataAssignedTo = filteredDataOffice.filter((d: any) => {
    if (assignedTos.length == 0) return d;
    else return assignedTos.includes(d.assignedTo);
  });

  const filteredData = filteredDataAssignedTo;

  const COLORS = ["#00C49F", "#FFBB28", "#0088FE", "#FF8042", "#8B008B", "#FF1493", "#B22222", "#ADD8E6"];

  const dataGroupByStatus = groupBy(filteredData, (i: Case) => i.status);
  const mapDataGroupByStatus = map(dataGroupByStatus, (statusArr, status) => {
    return { name: status, value: statusArr.length };
  });
  const dataGroupByOfficeBi = groupBy(filteredData, (i: Case) => i.cacheOffice);
  const mapDataGroupByOfficeBi = map(dataGroupByOfficeBi, (officeArr, office) => {
    return { name: office, value: officeArr.length };
  });
  const dataGroupByAssignedToBi = groupBy(filteredData, (i: Case) => i.cacheAssignedTo);
  const mapDataGroupByAssignedToBi = map(dataGroupByAssignedToBi, (assignedToArr, assignedTo) => {
    return { name: assignedTo, value: assignedToArr.length };
  });

  const dataGroupByCenter = groupBy(filteredData, (i: Case) => i.cacheOffice);
  const mapDataGroupByCenter = map(dataGroupByCenter, (officeArr, office) => {
    const groupByStatus = groupBy(officeArr, (i: Case) => i.status);

    const x = map(groupByStatus, (statusArr, status) => {
      return { status, length: statusArr.length };
    });
    let result: any = { name: office };
    x.forEach((v) => {
      result[v.status] = v.length;
    });

    return result;
  });

  const dataGroupByAssignedTo = groupBy(filteredData, (i: Case) => i.cacheAssignedTo);
  const mapDataGroupByAssignedTo = map(dataGroupByAssignedTo, (assignedToArr, assignedTo) => {
    const groupByStatus = groupBy(assignedToArr, (i: Case) => i.status);

    const x = map(groupByStatus, (statusArr, status) => {
      return { status, length: statusArr.length };
    });
    let result: any = { name: assignedTo };
    x.forEach((v) => {
      result[v.status] = v.length;
    });

    return result;
  });

  const dataGroupByConductedBy = groupBy(filteredData, (i: Case) => i.cacheConductedBy);
  const mapDataGroupByConductedBy = map(dataGroupByConductedBy, (conductedByArr, conductedBy) => {
    const groupByStatus = groupBy(conductedByArr, (i: Case) => i.status);

    const x = map(groupByStatus, (statusArr, status) => {
      return { status, length: statusArr.length };
    });
    let result: any = { name: conductedBy };
    x.forEach((v) => {
      result[v.status] = v.length;
    });

    return result;
  });

  return (
    <div>
      <div style={{ padding: "1%" }}>
        <span>
          {"From: "}
          <DatePicker onChange={(e) => setStartDate(e.startOf("day"))} value={startDate}></DatePicker>
        </span>
        <span> {"To: "}</span>
        <DatePicker onChange={(e) => setEndDate(e.endOf("day"))} value={endDate}></DatePicker>
      </div>

      <Tabs activeKey={key} onChange={(k) => setKey(k)}>
        <TabPane tab="Table Report" key="1">
          <div>
            {modalState ? (
              <MediaViewDialog modalState={modalState} setModalState={setModalState} url={url} type={attachType} />
            ) : null}
            {modalStateCase ? (
              <EditCase uid={uid} modalState={modalStateCase} setModalState={setModalStateCase} caseDoc={caseDoc} />
            ) : null}

            {modalStateSubTable ? (
              <TableDialog
                loading={loading}
                setLoading={setLoading}
                modalState={modalStateSubTable}
                setModalState={setModalStateSubTable}
                setStatus={setStatusSub}
                status={statusSub}
                office={officeSub}
                setOffice={setOfficeSub}
                assignedTo={assignedToSub}
                setAssignedTo={setAssignedToSub}
                columns={columns}
                columnsExport={columnsExport}
                startDate={startDate}
                endDate={endDate}
              />
            ) : null}

            {modalStateSubTableBar ? (
              <TableBarDialog
                modalState={modalStateSubTableBar}
                setModalState={setModalStateSubTableBar}
                barFilters={barFilters}
                setBarFilters={setBarFilters}
                columns={columns}
                columnsExport={columnsExport}
                startDate={startDate}
                endDate={endDate}
              />
            ) : null}

            <DevTable
              data={data}
              columns={columns}
              defaultHiddenColumnNames={defaultHiddenColumnNames}
              tableName="cases"
              selected={false}
              typicalTable={false}
              defaultColumnWidths={defaultColumnWidths}
              columnsExport={columnsExport}
              rowClick={({ args, row }) => {
                setCaseDoc(row);
                setModalStateCase(true);
              }}
            />
          </div>
        </TabPane>

        <TabPane tab="Statistical Report" key="2">
        
            <div>
              <Row gutter={16}>
                <Col span={16}>
                  <Select
                    style={{ width: "20%" }}
                    allowClear
                    mode="multiple"
                    showSearch
                    value={status}
                    onChange={(e) => {
                      setStatus(e);
                    }}
                    placeholder="Status"
                  >
                    <Option value={"submitted"}>{"Submitted"}</Option>
                    <Option value={"received"}>{"Received"}</Option>
                    <Option value={"inProgress"}>{"In Progress"}</Option>
                    <Option value={"solved"}>{"Solved"}</Option>
                    <Option value={"confirmed"}>{"Confirmed"}</Option>
                    <Option value={"notConfirmed"}>{"Not Confirmed"}</Option>
                    <Option value={"suspended"}>{"Suspended"}</Option>
                  </Select>
                  <Select
                    allowClear
                    mode="multiple"
                    style={{ width: "20%" }}
                    showSearch
                    value={officeSN?.docs.filter((d) => officeIds.includes(d.id))?.map((o) => o.data()?.officeName)}
                    onChange={(e) => {
                      const ids = officeDataMap?.filter((d) => e.includes(d.officeName))?.map((o) => o.id) as string[];
                      setOfficeIds(ids);
                    }}
                    placeholder="Offices"
                  >
                    {officeDataMap?.map((d) => {
                      return <Option value={d.officeName}>{d.officeName}</Option>;
                    })}
                  </Select>
                  <Select
                    allowClear
                    mode="multiple"
                    style={{ width: "20%" }}
                    showSearch
                    value={USERS_ID_FULL_NAME?.filter((d) => assignedTos.includes(d.uid))?.map((u) => u.fullName)}
                    onChange={(e) => {
                      const ids = USERS_ID_FULL_NAME?.filter((d) => e.includes(d.fullName))?.map(
                        (u) => u.uid
                      ) as string[];
                      setAssignedTos(ids);
                    }}
                    placeholder="Users"
                  >
                    {USERS_ID_FULL_NAME?.map((d) => {
                      return <Option value={d.fullName}>{d.fullName}</Option>;
                    })}
                  </Select>
                </Col>
                <Col span={6}>
                  <Statistic title="Record Count" value={filteredData.length} />
                </Col>
              </Row>
              <div>
                <PieChart width={2000} height={400}>
                  <Pie
                    data={mapDataGroupByStatus}
                    cx={300}
                    cy={200}
                    innerRadius={60}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={(args) => `${args.name}: ${(args.percent * 100).toFixed(0)}%`}
                    labelLine={false}
                    onDoubleClick={(g: any) => {
                      setLoading(true);
                      setStatusSub(g.name);
                      setModalStateSubTable(true);
                    }}
                  >
                    {mapDataGroupByStatus.map((entry, index) => {
                      if (entry.name == "solved") return <Cell key={`cell-${index}`} fill={"#00C49F"} />;
                      else if (entry.name == "inProgress") return <Cell key={`cell-${index}`} fill={"#FF8042"} />;
                      else if (entry.name == "confirmed") return <Cell key={`cell-${index}`} fill={"#00008B"} />;
                      else if (entry.name == "submitted") return <Cell key={`cell-${index}`} fill={"#0088FE"} />;
                      else if (entry.name == "received") return <Cell key={`cell-${index}`} fill={"#FFBB28"} />;
                      else if (entry.name == "notConfirmed") return <Cell key={`cell-${index}`} fill={"#B22222"} />;
                      else if (entry.name == "suspended") return <Cell key={`cell-${index}`} fill={"#8B008B"} />;
                      else return <Cell key={`cell-${index}`} fill={"white"} />;
                    })}
                  </Pie>
                  <Pie
                    data={mapDataGroupByOfficeBi}
                    cx={800}
                    cy={200}
                    innerRadius={60}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={(args) => `${args.name}: ${(args.percent * 100).toFixed(0)}%`}
                    labelLine={false}
                    onDoubleClick={(g: any) => {
                      setOfficeSub(g.name);
                      setModalStateSubTable(true);
                    }}
                  ></Pie>
                  <Pie
                    data={mapDataGroupByAssignedToBi}
                    cx={1400}
                    cy={200}
                    innerRadius={60}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={(args) => `${args.name}: ${(args.percent * 100).toFixed(0)}%`}
                    labelLine={false}
                    onDoubleClick={(g: any) => {
                      setAssignedToSub(g.name);
                      setModalStateSubTable(true);
                    }}
                  ></Pie>
                  <Tooltip />
                </PieChart>
                <Divider />

                <BarChart
                  width={1500}
                  height={
                    mapDataGroupByCenter.length < 10
                      ? mapDataGroupByCenter.length * 100
                      : mapDataGroupByCenter.length <= 20
                      ? mapDataGroupByCenter.length * 50
                      : mapDataGroupByCenter.length * 30
                  }
                  data={mapDataGroupByCenter}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 20,
                  }}
                  barSize={10}
                  layout="vertical"
                >
                  <CartesianGrid />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" width={200} />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="solved"
                    stackId="a"
                    fill="#00C49F"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "solved", office: f.name, assignedTo: "" });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="inProgress"
                    stackId="a"
                    fill="#FF8042"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "inProgress", office: f.name, assignedTo: "" });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="confirmed"
                    stackId="a"
                    fill="##00008B"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "confirmed", office: f.name, assignedTo: "" });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="submitted"
                    stackId="a"
                    fill="#0088FE"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "submitted", office: f.name, assignedTo: "" });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="received"
                    stackId="a"
                    fill="#FFBB28"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "received", office: f.name, assignedTo: "" });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="notConfirmed"
                    stackId="a"
                    fill="#B22222"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "notConfirmed", office: f.name, assignedTo: "" });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="suspended"
                    stackId="a"
                    fill="#8B008B"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "suspended", office: f.name, assignedTo: "" });
                      setModalStateSubTableBar(true);
                    }}
                  />
                </BarChart>

                <Divider />

                <BarChart
                  width={1500}
                  height={
                    mapDataGroupByAssignedTo.length < 10
                      ? mapDataGroupByAssignedTo.length * 100
                      : mapDataGroupByAssignedTo.length <= 20
                      ? mapDataGroupByAssignedTo.length * 50
                      : mapDataGroupByAssignedTo.length * 30
                  }
                  data={mapDataGroupByAssignedTo}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 20,
                  }}
                  barSize={10}
                  layout="vertical"
                >
                  <CartesianGrid />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" width={200} />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="solved"
                    stackId="a"
                    fill="#00C49F"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "solved", office: "", assignedTo: f.name });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="inProgress"
                    stackId="a"
                    fill="#FF8042"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "inProgress", office: "", assignedTo: f.name });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="confirmed"
                    stackId="a"
                    fill="##00008B"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "confirmed", office: "", assignedTo: f.name });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="submitted"
                    stackId="a"
                    fill="#0088FE"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "submitted", office: "", assignedTo: f.name });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="received"
                    stackId="a"
                    fill="#FFBB28"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "received", office: "", assignedTo: f.name });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="notConfirmed"
                    stackId="a"
                    fill="#B22222"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "notConfirmed", office: "", assignedTo: f.name });
                      setModalStateSubTableBar(true);
                    }}
                  />
                  <Bar
                    dataKey="suspended"
                    stackId="a"
                    fill="#8B008B"
                    onDoubleClick={(f) => {
                      setBarFilters({ status: "suspended", office: "", assignedTo: f.name });
                      setModalStateSubTableBar(true);
                    }}
                  />
                </BarChart>
                <Divider />

                <BarChart
                  width={1500}
                  height={
                    mapDataGroupByConductedBy.length < 10
                      ? mapDataGroupByConductedBy.length * 100
                      : mapDataGroupByConductedBy.length <= 20
                      ? mapDataGroupByConductedBy.length * 50
                      : mapDataGroupByConductedBy.length * 30
                  }
                  data={mapDataGroupByConductedBy}
                  margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 20,
                  }}
                  barSize={10}
                  layout="vertical"
                >
                  <CartesianGrid />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" width={200} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="solved" stackId="a" fill="#00C49F" />
                  <Bar dataKey="inProgress" stackId="a" fill="#FF8042" />
                  <Bar dataKey="confirmed" stackId="a" fill="##00008B" />
                  <Bar dataKey="submitted" stackId="a" fill="#0088FE" />
                  <Bar dataKey="received" stackId="a" fill="#FFBB28" />
                  <Bar dataKey="notConfirmed" stackId="a" fill="#B22222" />
                  <Bar dataKey="suspended" stackId="a" fill="#8B008B" />
                </BarChart>
              </div>
            </div>
         
        </TabPane>
      </Tabs>
    </div>
  );
}
