import React, { useState } from "react";
import firebase, { auth } from "firebase/app";
import { Spin, Button, Card, Select, DatePicker } from "antd";
const { Option } = Select;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { vacationRef, officeRef } from "../../db/collectionsRef";
import { usersRef } from "../User/usersTable";
import { sortBy } from "lodash";
import moment from "moment";
import { formatMMM } from "../../db/dateFormat";
import AddNewVacation from "./VacationsModules/AddNewVacation";
import VacationSummary from "./VacationSummary";
import { vacations } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
const { MonthPicker } = DatePicker;

export default function VacationList() {
  const [modalState, setModalState] = useState(false);
  const [state, setState] = useState("showAll");
  const [manager, setManager] = useState(false);
  const [month, setMonth] = useState(moment());
  const [employeeUid, setEmployeeUid] = useState("");
  const [vacationStatus, setVacationStatus] = useState("");
  const uid = auth().currentUser!.uid;

  const [userSN] = useDocument(usersRef.doc(uid));
  const [userSNC] = useCollection(usersRef);

  const isAdmin = userSN?.data()?.admin;
  const isHR = userSN?.data()?.adminHR;
  const isCEO = userSN?.data()?.CEO;

  const USERS_ID_FULL_NAME = userSNC?.docs
    .filter(e => currentUserData.fullControlOffices.includes(e.data().office) || e.data()?.managerUID == uid || isCEO)
    .map(u => {
      const manager = u.data().managerUID;
      const fullName = u.data().firstName + " " + u.data().lastName;
      const uid = u.id;
      return { uid, fullName, manager };
    });

  const [vacationList] = useCollection(
    vacationRef
      .where("vacationStartOn", "<=", month.endOf("month").toDate())
      .where("vacationStartOn", ">=", month.startOf("month").toDate())
  );
  if (isAdmin || isHR || isCEO) {
    const vacationListSN =
      vacationList == undefined
        ? null
        : vacationList.docs.filter(
            e =>
              currentUserData.fullControlOffices.includes(e.data()?.office) ||
              e.data()?.employeeUID == uid ||
              e.data()?.managerUID == uid ||
              isCEO
          );

    const HRSig = vacationListSN?.filter(d => {
      return d.data().HRSignatureDate == "" && d.data().managerSignatureDate != "";
    });
    const AdminSig = vacationListSN?.filter(d => {
      return d.data().adminSignatureDate == "" && d.data().HRSignatureDate != "";
    });
    const ManagerSig = vacationListSN?.filter(d => {
      return d.data().managerUID == uid;
    });
    const mySig = vacationListSN?.filter(d => {
      return d.data().employeeUID == uid;
    });
    const sortedList = sortBy(vacationListSN, item => item.data().requestedAt.toDate()).reverse();

    const sortedListAdmin = sortBy(AdminSig, item => item.data().requestedAt.toDate()).reverse();
    const sortedListHR = sortBy(HRSig, item => item.data().requestedAt.toDate()).reverse();
    const sortedListManager = sortBy(ManagerSig, item => item.data().requestedAt.toDate()).reverse();
    const sortedListMaine = sortBy(mySig, item => item.data().requestedAt.toDate()).reverse();

    let listView: any;
    switch (state) {
      case "showAll":
        listView = sortedList;
        break;
      case "admin":
        listView = sortedListAdmin;
        break;
      case "HR":
        listView = sortedListHR;
        break;
      case "manager":
        listView = sortedListManager;
        break;
      case "maine":
        listView = sortedListMaine;
        break;
      default:
        listView = sortedList;
        break;
    }

    if (!vacationList || !userSN)
      return (
        <div>
          <Spin size="small" />
        </div>
      );
    return (
      <div>
        <AddNewVacation modalState={modalState} setModalState={setModalState} />
        <Button
          type="default"
          style={{ margin: "1%", width: "98%", alignItems: "center", background: "#ffa500", color: "white" }}
          onClick={() => {
            setState("leavesToday");
            setEmployeeUid("");
            setVacationStatus("");
          }}
        >
          Today's Leaves{" "}
        </Button>
        <br />
        <MonthPicker
          size="default"
          style={{ margin: "1%", width: "98%" }}
          placeholder="Select month"
          value={month}
          format={"MMMM-YYYY"}
          onChange={(e:any) => {
            setMonth(moment(e));
          }}
        />
        <br />
        <Button
          type="primary"
          style={{ margin: "1%", width: "48%" }}
          onClick={() => {
            setState("maine");
          }}
        >
          My Vacations{" "}
        </Button>
        {isAdmin ? (
          <Button
            type="primary"
            style={{ margin: "1%", width: "48%" }}
            onClick={() => {
              setState("admin");
              setEmployeeUid("");
              setVacationStatus("");
            }}
          >
            Admin
          </Button>
        ) : null}
        {isHR ? (
          <Button
            type="primary"
            style={{ margin: "1%", width: "48%" }}
            onClick={() => {
              setState("HR");
              setEmployeeUid("");
              setVacationStatus("");
            }}
          >
            HR
          </Button>
        ) : null}
        <Button
          type="primary"
          style={{ margin: "1%", width: "48%" }}
          onClick={() => {
            setState("manager");
            setEmployeeUid("");
            setVacationStatus("");
          }}
        >
          My Signatures
        </Button>
        <br />
        <Button
          type="default"
          style={{ margin: "1%", width: "98%", alignItems: "center" }}
          onClick={() => {
            setState("showAll");
            setEmployeeUid("");
            setVacationStatus("");
          }}
        >
          Show All{" "}
        </Button>
        <br />
        {state == "showAll" || state == "manager" || state == "leavesToday" ? (
          <div>
            <Button
              type="danger"
              style={{ margin: "1%" }}
              onClick={() => {
                setVacationStatus("Requested");
              }}
            >
              Requested
            </Button>
            <Button
              type="primary"
              style={{ margin: "1%" }}
              onClick={() => {
                setVacationStatus("Pending");
              }}
            >
              Pending{" "}
            </Button>
            <Button
              style={{ margin: "1%", background: "#77c556", color: "white" }}
              onClick={() => {
                setVacationStatus("Accepted");
              }}
            >
              Accepted{" "}
            </Button>
          </div>
        ) : null}
        {state != "maine" ? (
          <Select
            size="default"
            placeholder="Search for Name!"
            style={{ width: "100%", paddingBottom: "1%" }}
            showSearch
            value={USERS_ID_FULL_NAME?.find(d => d.uid == employeeUid)?.fullName}
            onChange={(e:string) => {
              const id = USERS_ID_FULL_NAME?.find(d => d.fullName == e)?.uid as string;
              setEmployeeUid(id);
            }}
          >
            {USERS_ID_FULL_NAME?.map(d => {
              return <Option value={d.fullName}>{d.fullName}</Option>;
            })}
          </Select>
        ) : null}
        <br />

        {listView
          .filter((v:any) => {
            if (employeeUid == "") {
              return v;
            } else {
              return v.data()?.employeeUID == employeeUid;
            }
          })
          .filter((v: any) => {
            if (vacationStatus == "") {
              return v;
            } else {
              return v.data()?.status == vacationStatus;
            }
          })
          .filter((v: any) => {
            if (state == "leavesToday") {
              const vacationStartOn = moment(v.data()?.vacationStartOn.toDate()).startOf("day");
              const vacationEndOn = moment(v.data()?.vacationEndOn.toDate()).endOf("day");
              return moment().isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
            } else {
              return v;
            }
          })
          .map((doc: any) => {
            const data = doc.data() as vacations;
            const fullName = `${userSNC?.docs.find(d => d.id == data.employeeUID)?.data().firstName} ${
              userSNC?.docs.find(d => d.id == data.employeeUID)?.data().lastName
            }`;
            return (
              <VacationSummary
              vacationType={data?.vacationType}
                key={doc.id}
                id={doc.id}
                employeeUID={fullName}
                vacationStartOn={moment(data?.vacationStartOn.toDate()).format(formatMMM)}
                vacationEndOn={moment(data?.vacationEndOn.toDate() as any).format(formatMMM)}
                status={doc.data().status}
              />
            );
          })}
        <Button
          type="primary"
          shape="circle"
          size="large"
          onClick={() => {
            setModalState(true);
          }}
        >
          +
        </Button>
      </div>
    );
  } else {
    const vacationListSN =
      vacationList == undefined
        ? null
        : vacationList.docs.filter(d => {
            if (manager) {
              return d.data().managerUID == uid;
            } else {
              return d.data().employeeUID == uid;
            }
          });

    const sortedList = sortBy(vacationListSN, item => item.data().requestedAt.toDate()).reverse();
    if (!vacationList || !userSN)
      return (
        <div>
          <Spin size="small" />
        </div>
      );
    return (
      <div>
        <AddNewVacation modalState={modalState} setModalState={setModalState} />
        <MonthPicker
          size="large"
          style={{ margin: "1%", width: "98%" }}
          placeholder="Select month"
          value={month}
          format={"MMMM-YYYY"}
          onChange={(e:any) => {
            setMonth(moment(e));
          }}
        />
        <Button
          type="primary"
          style={{ margin: "1%", width: "98%" }}
          onClick={() => {
            setManager(!manager);
            setEmployeeUid("");
          }}
        >
          {manager ? "Go to My Vacations" : "Go to Manager Signatures"}
        </Button>
        <br />
        {manager ? (
          <Select
            size="default"
            placeholder="Search for Name!"
            style={{ width: "100%", paddingBottom: "1%" }}
            showSearch
            value={USERS_ID_FULL_NAME?.find(d => d.uid == employeeUid)?.fullName}
            onChange={(e:string) => {
              const id = USERS_ID_FULL_NAME?.find(d => d.fullName == e)?.uid as string;
              setEmployeeUid(id);
            }}
          >
            {USERS_ID_FULL_NAME?.filter(v => v.manager == uid).map(d => {
              return <Option value={d.fullName}>{d.fullName}</Option>;
            })}
          </Select>
        ) : null}
        {sortedList
          .filter((v: any) => {
            if (employeeUid == "") {
              return v;
            } else {
              return v.data()?.employeeUID == employeeUid;
            }
          })
          .map((doc:any) => {
            const data = doc.data() as vacations;
            const fullName = `${userSNC?.docs.find(d => d.id == data.employeeUID)?.data().firstName} ${
              userSNC?.docs.find(d => d.id == data.employeeUID)?.data().lastName
            }`;
            return (
              <VacationSummary
                vacationType={data?.vacationType}
                key={doc.id}
                id={doc.id}
                employeeUID={fullName}
                vacationStartOn={moment(data?.vacationStartOn.toDate()).format(formatMMM)}
                vacationEndOn={moment(data?.vacationEndOn.toDate() as any).format(formatMMM)}
                status={doc.data().status}
              />
            );
          })}
        <Button
          type="primary"
          shape="circle"
          size="large"
          onClick={() => {
            setModalState(true);
          }}
        >
          +
        </Button>
      </div>
    );
  }
}
