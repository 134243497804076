import { Button, message, Spin } from "antd";
// import { globalUsersData } from 'common/global/usersList'
import { EditorState } from "draft-js";
import {
  BlockquoteButton,
  BoldButton,
  CodeBlockButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton
} from "draft-js-buttons";
import createEmojiPlugin from "draft-js-emoji-plugin";
import "draft-js-emoji-plugin/lib/plugin.css";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import createLinkifyPlugin from "draft-js-linkify-plugin";
import "draft-js-linkify-plugin/lib/plugin.css";
import createMentionPlugin, { defaultSuggestionsFilter } from "draft-js-mention-plugin";
import Editor, { createEditorStateWithText } from "draft-js-plugins-editor";
import createToolbarPlugin, { Separator } from "draft-js-static-toolbar-plugin";
import "draft-js-static-toolbar-plugin/lib/plugin.css";
import firebase, { auth } from "firebase/app";
import { map, uniq } from "lodash";
import React, { useMemo, useRef, useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
// import "./editorStyles.module.css";
// import * as styles from "./MentionsStyles.module.css";
import { globalUsersData } from "../../../global/usersList";
import "./newStyles.css";
import { calendar, notificationsRef } from "../../../db/collectionsRef";
import { useRouteMatch } from "react-router-dom";
import { notification } from "../../../db/interfaces";

interface Props {
  onValueChange: (nextValue: string) => void; // MOM: string;
  onMentionChange: (nextValue: any[]) => void; // MOM: string;
  html: string;
}

export default function DraftText(props: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [user] = useAuthState(firebase.auth());
  const emojiPlugin = useMemo(() => createEmojiPlugin(), []);
  const { EmojiSuggestions } = emojiPlugin;
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML(props.html)));
  // const id = useRouteMatch<{ id: string }>().params.id;

  const editorRef = useRef<Editor | null>();
  const createdAt = firebase.firestore.Timestamp.fromDate(new Date());

  const usersMentions = useMemo(
    () =>
      map(globalUsersData, e => {
        return {
          key: e.id,
          name: `${e.firstName} ${e.lastName}`,
          email: e.email
        };
      }),

    []
  );
  const [suggestions, setSuggestions] = useState(usersMentions);

  const mentionPlugin = useMemo(
    () =>
      createMentionPlugin({
        usersMentions,
        entityMutability: "IMMUTABLE",
        positionSuggestions,
        mentionPrefix: "@",
        supportWhitespace: true
      }),

    [usersMentions]
  );
  const toolbarPlugin = createToolbarPlugin();
  const { Toolbar } = toolbarPlugin;
  const { MentionSuggestions } = mentionPlugin;
  const linkifyPlugin = createLinkifyPlugin();
  const plugins = useMemo(() => [mentionPlugin, emojiPlugin, toolbarPlugin, linkifyPlugin], [
    linkifyPlugin,
    mentionPlugin,
    emojiPlugin,
    toolbarPlugin
  ]);

  function onSearchChange({ value }: any) {
    setSuggestions(defaultSuggestionsFilter(value, usersMentions));
  }

  const buttonsProps = {
    getEditorState: () => editorState,
    setEditorState: setEditorState
  };

  const html = stateToHTML(editorState.getCurrentContent());
  const usersMentioned = uniq(getEntities(editorState, "mention").map(x => x.entity.data.mention.key));

  return (
    <div style={{ paddingBottom: "2%" }}>
      <div>
        <Toolbar style={{ background: "red" }}>
          {(externalProps: any) => (
            <>
              <BoldButton {...externalProps} {...buttonsProps} />
              <ItalicButton {...externalProps} {...buttonsProps} />
              <UnderlineButton {...externalProps} {...buttonsProps} />
              {/* <CodeButton {...externalProps} {...buttonsProps} /> */}
              <Separator {...externalProps} {...buttonsProps} />
              <HeadlineOneButton {...externalProps} {...buttonsProps} />
              <HeadlineTwoButton {...externalProps} {...buttonsProps} />
              <HeadlineThreeButton {...externalProps} {...buttonsProps} />
              <UnorderedListButton {...externalProps} {...buttonsProps} />
              <OrderedListButton {...externalProps} {...buttonsProps} />
              {/* <BlockquoteButton {...externalProps} {...buttonsProps} /> */}
              <CodeBlockButton {...externalProps} {...buttonsProps} />
            </>
          )}
        </Toolbar>
      </div>
      {true ? (
        <div
          style={{
            boxSizing: "border-box",
            border: "1px",
            cursor: "text",
            padding: "20px",
            marginBottom: "2px",
            background: "#fff",
            margin: "1px 2px",
            minHeight: "300px",
            borderStyle: "solid",
            borderColor: "rgb(0 0 0 / 13%)"
          }}
          onClick={() => {
            editorRef.current!.focus();
          }}
        >
          <EmojiSuggestions />

          <p>
            <b>Type Here..</b>
          </p>
          <div>
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              plugins={plugins}
              ref={element => {
                editorRef.current = element;
              }}
            />
          </div>
          <MentionSuggestions onSearchChange={onSearchChange} suggestions={suggestions} entryComponent={Entry} />
        </div>
      ) : null}
      <Button
        style={{ float: "right", height: "34px" }}
        type="danger"
        onClick={async () => {
          await props.onValueChange(html);
          await props.onMentionChange(usersMentioned);
          // await setEditorState(createEditorStateWithText(""));
          await message.success("Draft Saved!");
        }}
      >
        Save
      </Button>{" "}
    </div>
  );
}

const getEntities = (editorState: EditorState, entityType: string | null = null) => {
  const content = editorState.getCurrentContent();
  const entities: any[] = [];
  content.getBlocksAsArray().forEach(block => {
    let selectedEntity: any = null;
    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity())
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });
  return entities;
};

function positionSuggestions({ state, props }: any) {
  let transform;
  let transition;

  if (state.isActive && props.suggestions.length > 0) {
    transform = "scaleY(1)";
    transition = "all 0.25s cubic-bezier(.3,1.2,.2,1)";
  } else if (state.isActive) {
    transform = "scaleY(0)";
    transition = "all 0.25s cubic-bezier(.3,1,.2,1)";
  }

  return {
    transform,
    transition
  };
}

function Entry(props: any) {
  const { mention, theme, ...parentProps } = props;

  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className={theme.mentionSuggestionsEntryContainerLeft}>
          <img src={mention.avatar} className={theme.mentionSuggestionsEntryAvatar} role="presentation" />
        </div>

        <div className={theme.mentionSuggestionsEntryContainerRight}>
          <div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>

          <div className={theme.mentionSuggestionsEntryTitle}>{mention.title}</div>
        </div>
      </div>
    </div>
  );
}
