import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col,
  Modal,
  Popconfirm,
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUploader from "react-firebase-file-uploader";
import { notificationsRef, userRef, orderRef, meetingRoomList, calendar } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import DraftText from "./TextEditor/Draft";
import { uniq } from "lodash";
import SendMOM from "./SendMOMDialog";
import { Calendar } from "../../db/interfaces";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  MOM: string;
}

function AddMOM(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [file, setFile] = useState("");
  const [modalStateSendMOM, setModalStateSendMOM] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [HTMLText, setHTMLText] = useState(props.MOM);
  const [mentions, setMentions] = useState(Array);
  const [uploadingAttach, setUploadingAttach] = useState(false);
  const uid = auth().currentUser!.uid;

  const [calendarSN] = useDocument(calendar.doc(props.docId));
  if (!calendarSN) {
    return <Spin />;
  }
  const MOM = calendarSN.data()?.MOM == undefined ? "<p></p>" : calendarSN.data()?.MOM;

  const htmlValue = (value: string) => {
    setHTMLText(value);
  };
  const onMentionChange = (value: any[]) => {
    setMentions(value);
  };
  const momAttachStorage = firebase.storage().ref(uid);
  const data = calendarSN.data() as Calendar;
  const attachments = data.momAttach ?? [];
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  return (
    <div>
      {modalStateSendMOM ? <SendMOM
        modalStateSendMOM={modalStateSendMOM}
        setModalStateSendMOM={setModalStateSendMOM}
        setModalState={setModalState}
        files={files}
        downloadURLs={downloadURLs}
        MOM={HTMLText}
        MOMWriter={uid}
        mentions={mentions}
        docId={props.docId}
      /> : null}
      <Modal
        title={t("general.addMOM")}
        width={1000}
        visible={modalState}
        // onCancel={() => {}}
        // onOk={async () => {}}
        closable={false}
        footer={[
          <Popconfirm
            title={"Are you sure?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              setModalState(false);
              setHTMLText("");
            }}
          >
            <Button>Cancel</Button>
          </Popconfirm>,
          <Button
            type="primary"
            onClick={() => {
              setModalStateSendMOM(true);
              setModalState(false);
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <div>
          <DraftText onValueChange={htmlValue} onMentionChange={onMentionChange} html={MOM} />
          <label
            style={{
              background: "rgb(100 181 246)",
              padding: "1%",
              marginRight: "1%",
              borderColor: "rgb(100 181 246)",
              borderRadius: "6px",
              color: "white",
            }}
          >
            <Icon type="upload" />
            <span style={{ padding: "2%" }}>{t("general.uploadAttach")}</span>
            {uploadingAttach ? <Spin indicator={antIcon} style={{ color: "#fff" }} /> : null}
            {data.momAttach == undefined || data.momAttach.length == 0 ? null : (
              <Icon type="check" style={{ color: "#fff", padding: "1%" }} />
            )}

            <FileUploader
              hidden
              // randomizeFilename
              storageRef={momAttachStorage}
              onUploadStart={(e: any) => {
                setFile(e.name);
                setUploadingAttach(true);
              }}
              onUploadSuccess={async (fileName: string, args: any) => {
                const downloadURL = await momAttachStorage.child(fileName).getDownloadURL();
                setUploadingAttach(false);
                console.log("file", fileName);
                setFiles([...files, fileName] as any);
                setDownloadURLs([...downloadURLs, downloadURL] as any);
              }}
            />
          </label>
          <label
            style={{
              background: "#fff",
              padding: "1%",
              marginRight: "1%",
              border: "solid thin",
              borderColor: "rgb(100 181 246)",
              borderRadius: "6px",
              color: "rgb(100 181 246)",
            }}
          >
            {`Uploaded File Number: ${[...attachments, ...downloadURLs].length}`}
          </label>
          <Button
            onClick={() => {
              calendar.doc(props.docId).update({
                momAttach: [],
                filesNames: [],
                sendMOM: false,
              });
              setFiles([]);
            }}
            type="danger"
          >
            Delete Attach
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AddMOM;
