import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button } from "antd";

import saveAs from "file-saver";
import { orderRef, userRef, meetingRoomList, calendar } from "../../db/collectionsRef";
import { Link, useHistory } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import AddNewRoomModel from "./AddNewRoomModel";
import { useTranslation } from "react-i18next";
import AddMOM from "./AddMOM";
import { sortBy } from "lodash";
import DateRange from "@material-ui/icons/DateRange";
import { admins } from "../../db/admin";
import DevTable from "../../helpers/devex-table";

const MOMStatusChange = (id: string) => {
  console.log("MOM Status Change");
  calendar.doc(id).update({
    MOMStatus: true
  });
};
export default function MeetingsTable() {
  const [rowDocId, setRowDocId] = useState("");
  const history = useHistory();
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [MOMStatus, setMOMStatus] = useState(true);
  const [MOM, setMOM] = useState("");
  const [showAll, setShowAll] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [defaultHiddenColumnNames] = useState(["agenda", "allDay", "attendees", "endDate"]);

  const [roomsSN, loading2, error2] = useCollection(meetingRoomList);
  const [calendarSNAdmins] = useCollection(calendar);
  const [calendarSN] = useCollection(
    calendar.where("members", "array-contains", uid).where("MOMStatus", "==", MOMStatus)
  );
  const [calendarSNAll] = useCollection(calendar.where("members", "array-contains", uid));
  const [usersSN] = useCollection(userRef);

  if (!calendarSN || !usersSN || !roomsSN || !calendarSNAll) return <Spin />;

  const sortedData = sortBy(
    admins.includes(uid) ? calendarSNAdmins?.docs : showAll ? calendarSNAll.docs : calendarSN.docs,
    item => item.data().endDate
  ).reverse();
  const data = sortedData.map((d, index) => {
    return { ...d.data(), id: d.id, index: index + 1 };
  });
  const columns = [
    {
      name: "index",
      title: "NO"
    },

    { name: "title", title: "Title" },
    {
      name: "uid",
      title: "Organizer",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.uid)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.uid)?.data()?.lastName
        }`
    },
    {
      name: "roomId",
      title: "Location",
      getCellValue: (row: any) => roomsSN.docs?.find(d => d.id == row.roomId)?.data()?.roomName
    },
    {
      name: "allDay",
      title: "All Day",
      getCellValue: (row: any) => (row.allDay == false ? "No" : "Yes")
    },
    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => row.startDate.toDate().toLocaleString()
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => row.endDate.toDate().toLocaleString()
    },
    {
      name: "attendees",
      title: "Attendees",
      getCellValue: (row: any) => {
        return row.members.map((m: string) => {
          return `${usersSN.docs.find(d => d.id == m)?.data().firstName} ${
            usersSN.docs.find(d => d.id == m)?.data().lastName
          }, `;
        });
      }
    },

    {
      name: "agenda",
      title: "Agenda"
    },

    {
      name: "more",
      title: "More Information",
      getCellValue: (row: any) => {
        return <Button onClick={() => history.push(`/calenderDetails/${row.id}`)}> {t("general.view")}</Button>;
      }
    },
    {
      name: "MOM",
      title: "MOM",
      getCellValue: (row: any) => {
        const now = new Date();
        const oldDate = row.startDate.toDate();
        if (now >= oldDate && (row.MOM == undefined || row.MOM == "")) {
          MOMStatusChange(row.id);
          console.log(row.MOM);
          console.log("yes it's");
          return (
            <Button
              onClick={() => {
                setRowDocId(row.id);
                setMOM(row.MOM);
                setModalState(true);
              }}
            >
              {t("general.addMOMButton")}
            </Button>
          );
        } else if (now >= oldDate && row.MOM !== undefined) {
          return (
            <div>
              <Button
                onClick={() => {
                  setRowDocId(row.id);
                  setModalState(true);
                }}
              >
                {t("general.addMOMButton")}
              </Button>
              <div style={{ color: "green", float: "right", paddingTop: "3%", paddingRight: "6%" }}>
                {" "}
                {t("general.submitted")}
              </div>
            </div>
          );
        } else {
          return "Not Yet";
        }
      }
    }
  ];
  const columnsExport = [
    { name: "title", title: "Title" },
    {
      name: "uid",
      title: "Organizer",
      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.uid)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.uid)?.data()?.lastName
        }`
    },
    {
      name: "roomId",
      title: "Location",
      getCellValue: (row: any) => roomsSN.docs?.find(d => d.id == row.roomId)?.data()?.roomName
    },
    {
      name: "allDay",
      title: "All Day",
      getCellValue: (row: any) => (row.allDay == false ? "No" : "Yes")
    },
    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => row.startDate.toDate().toLocaleString()
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => row.endDate.toDate().toLocaleString()
    },
    {
      name: "attendees",
      title: "Attendees",
      getCellValue: (row: any) => {
        return row.members.map((m: string) => {
          return `${usersSN.docs.find(d => d.id == m)?.data().firstName} ${
            usersSN.docs.find(d => d.id == m)?.data().lastName
          }, `;
        });
      }
    }
  ];
  return (
    <div>
      <div style={{ backgroundColor: "#fff", padding: "1%", marginBottom: "1%", border: "white", borderRadius: "4px" }}>
        <b>
          <h3>Meetings Table Details</h3>
        </b>
      </div>
      <div>
        <Button
          type="primary"
          style={{ marginBottom: "1%", marginRight: "0.2%" }}
          onClick={() => {
            // setMOMStatus(!MOMStatus);
            setShowAll(!showAll);
          }}
        >
          {!showAll ? "Show All" : "Show Finished"}
        </Button>
      </div>

      {rowDocId !== "" ? (
        <AddMOM modalState={modalState} setModalState={setModalState} docId={rowDocId} MOM={MOM} />
      ) : null}
      <div>
        <Paper>
          <DevTable
            data={data}
            columns={columns}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            tableName="My_Meetings"
            selected={false}
            typicalTable={false}
            columnsExport={columnsExport}
          />
        </Paper>
      </div>
    </div>
  );
}
